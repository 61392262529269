import { GlobalElementsStyle } from "../../theme/global-elements-style";
import { type ETPtheme } from "../../theme/etp-theme";
import styled from "styled-components";
import { OrderTypeTab } from "../../types/order-type-tab";

export const OrderTabsStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
  $selectedTab: OrderTypeTab;
}>`
  padding: 8px 0 4px;

  div[role="tablist"] {
    display: flex;

    button {
      flex: 1;
      border: none;
      border-radius: 4px 4px 0 0;
      background: ${({ $ETPtheme }) =>
        $ETPtheme.orderTab.background.unselected};
      padding: 6px 0;
      font-weight: 500;
      line-height: 13px;
      font-size: 13px;
      color: ${({ $ETPtheme }) => $ETPtheme.orderTab.text.tabTitle};
      transition:
        background 100ms ease-out,
        transform 100ms ease-out;

      &[aria-selected="true"] {
        transform: translateY(1px);
        font-weight: 500;
        z-index: 1;
        background: ${({ $ETPtheme }) =>
          $ETPtheme.orderTab.background.selected};
        border: ${({ $ETPtheme }) => $ETPtheme.orderTab.border};
        border-bottom-width: 0;
      }

      &[aria-selected="false"] {
        // To perform realistic perspective with the border tab
        margin-top: 2px;
        font-weight: 400;
        transform: translateX(
          ${({ $selectedTab }) =>
            $selectedTab === OrderTypeTab.MARKET_ORDERS ? "-3px" : "3px"}
        );
        background: ${({ $ETPtheme }) =>
          $ETPtheme.orderTab.background.unselected};
      }
    }
  }

  div.tabpanel {
    border-radius: ${({ $selectedTab }) =>
      $selectedTab === OrderTypeTab.MARKET_ORDERS
        ? "0 4px 4px 4px"
        : "4px 0 4px 4px"};
    border: ${({ $ETPtheme }) => $ETPtheme.orderTab.border};
    padding: 8px 8px 6px 8px;
    background: ${({ $ETPtheme }) => $ETPtheme.orderTab.background.selected};
    transition: border-radius 100ms ease-out;
  }
`;
