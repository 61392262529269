import styled from "styled-components";
import { GlobalElementsStyle } from "../../../theme/global-elements-style";
import { type ETPtheme } from "../../../theme/etp-theme";

export const CostsAndChargesContainer = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  padding: 4px;
  margin-top: 8px;
  line-height: 16px;
  background-color: ${({ $ETPtheme }) =>
    $ETPtheme.costsAndCharges.background.summary};
  color: ${({ $ETPtheme }) => $ETPtheme.costsAndCharges.text.summary};

  dl {
    grid-template-columns: 2fr 1fr;
  }
`;

export const getModalStyle = ($ETPTheme: ETPtheme) => ({
  overlay: {
    backgroundColor: $ETPTheme.costsAndCharges.background.details.overlay,
    zIndex: 10000,
  },
  content: {
    border: 0,
    borderRadius: 0,
    padding: 0,
    maxHeight: "calc(100vh - 64px - 64px)", // 100 % of the viewport - 64px to align with the top and  -64px for the bottom
    width: "464px",
    inset: "64px 0 auto 50%",
    transform: "translateX(-50%)",
  },
});
