import {
  type PricingData,
  type RawPricingData,
  type MarketStatus,
} from "../types/pricing-data";

export const toNumberOrNull = (value: string | undefined): number | null => {
  if (value === "") {
    return null;
  }
  const convertedValue: number = Number(value);
  return isNaN(convertedValue) ? null : convertedValue;
};

export const determineEffectivePricingData = (
  snapshotBid: string,
  snapshotOffer: string,
  lightstreamerPricingData: RawPricingData | undefined,
  snapshotMarketStatus: MarketStatus,
): PricingData => {
  const castedSnapshotBid = toNumberOrNull(snapshotBid);
  const castedSnapshotOffer = toNumberOrNull(snapshotOffer);
  let effectivePricingData: PricingData;
  if (lightstreamerPricingData === undefined) {
    effectivePricingData = {
      bid: castedSnapshotBid,
      offer: castedSnapshotOffer,
      marketStatus: snapshotMarketStatus,
      underlyingPrice: null,
    };
  } else if (
    lightstreamerPricingData.bid === undefined &&
    lightstreamerPricingData.offer === undefined
  ) {
    effectivePricingData = {
      ...lightstreamerPricingData,
      bid: castedSnapshotBid,
      offer: castedSnapshotOffer,
      underlyingPrice: toNumberOrNull(lightstreamerPricingData.underlyingPrice),
    };
  } else {
    effectivePricingData = {
      ...lightstreamerPricingData,
      bid: toNumberOrNull(lightstreamerPricingData.bid),
      offer: toNumberOrNull(lightstreamerPricingData.offer),
      underlyingPrice: toNumberOrNull(lightstreamerPricingData.underlyingPrice),
    };
  }
  return effectivePricingData;
};
