export const formatVolume = (volumeString: string | null | undefined) => {
  const volume = Number(volumeString);
  if (volume >= 1000) {
    return Math.floor(volume / 1000).toString() + "K";
  } else if (volume > 0) {
    return volume.toString();
  }
  return undefined;
};

export const formatStringPrice = (
  lightstreamerPrice: string | undefined,
  decimalPlacesFactor: number,
) => {
  if (lightstreamerPrice === "" || lightstreamerPrice === undefined) {
    return "-";
  }
  return parseFloat(lightstreamerPrice).toFixed(decimalPlacesFactor);
};

export const formatNumberPrice = (
  price: number | null,
  decimalPlacesFactor: number,
) => {
  return price === null ? "-" : price.toFixed(decimalPlacesFactor);
};
