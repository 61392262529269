import { useETPTheme } from "../../hooks/use-etp-theme";
import { OptionType } from "../../types/instrument";
import { useTranslation } from "react-i18next";
import { type InstrumentType } from "../../types/instrument-type";
import ArrowIcon, { IconDirection } from "../icons/ArrowIcon";
import { InstrumentOptionTypeStyles } from "./InstrumentOptionTypeStyles";

interface OptionTypeArrowProps {
  instrumentType: InstrumentType;
  optionType: OptionType;
}

const InstrumentOptionType = ({
  instrumentType,
  optionType,
}: OptionTypeArrowProps) => {
  const ETPtheme = useETPTheme();
  const { t } = useTranslation();
  const isOptionTypeCall = optionType === OptionType.CALL;

  const instrumentDirectionTheme = ETPtheme.instrumentTitle.text.direction;

  return (
    <InstrumentOptionTypeStyles
      data-testid="instrument-option-type"
      $isOptionTypeCall={isOptionTypeCall}
      $ETPtheme={ETPtheme}
    >
      {t(`option-type.${instrumentType}.${optionType}`)}
      <span data-testid={`option-type-${optionType.toLowerCase()}`}>
        <ArrowIcon
          fill={
            isOptionTypeCall
              ? instrumentDirectionTheme.call.arrow
              : instrumentDirectionTheme.put.arrow
          }
          direction={
            isOptionTypeCall ? IconDirection.UP_RIGHT : IconDirection.DOWN_RIGHT
          }
        />
      </span>
    </InstrumentOptionTypeStyles>
  );
};

export default InstrumentOptionType;
