import React from "react";
import { useETPTheme } from "../../hooks/use-etp-theme";

export enum PlusMinusIconType {
  PLUS = "+",
  MINUS = "-",
}
interface PlusMinusIconProps {
  type: PlusMinusIconType;
}

const PlusMinusIcon = ({ type }: PlusMinusIconProps) => {
  const ETPtheme = useETPTheme();

  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {type === PlusMinusIconType.PLUS && ( // this is the vertical line for the + sign
        <rect
          x="4"
          width="1"
          height="9"
          rx="0.5"
          // TODO Should we change this to make it linked to the icons rather than the costsAndCharges?
          fill={ETPtheme.costsAndCharges.text.details.title}
        />
      )}
      <rect
        y="5"
        width="1"
        height="9"
        rx="0.5"
        transform="rotate(-90 0 5)"
        fill={ETPtheme.costsAndCharges.text.details.title}
      />
    </svg>
  );
};

export default PlusMinusIcon;
