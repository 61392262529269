import styled from "styled-components";
import { type ETPtheme } from "../../theme/etp-theme";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { GlobalElementsStyle } from "../../theme/global-elements-style";

const Spinner = () => {
  const ETPtheme = useETPTheme();
  return (
    <SpinnerStyles $ETPtheme={ETPtheme} data-testid="order-button-spinner">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0"
        y="0"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 100 100"
        xmlSpace="preserve"
      >
        <g>
          <path
            opacity="0.4"
            d="M50.6,28.5h-1.3c-1.9,0-3.4-1.5-3.4-3.4V7.9c0-1.9,1.5-3.4,3.4-3.4h1.3
		c1.9,0,3.4,1.5,3.4,3.4v17.3C54,27,52.5,28.5,50.6,28.5z"
          />
          <path
            opacity="0.48"
            d="M39.8,31.1l-1.1,0.6c-1.6,0.9-3.7,0.4-4.6-1.2l-8.6-14.9c-0.9-1.6-0.4-3.7,1.2-4.6l1.1-0.6
		c1.6-0.9,3.7-0.4,4.6,1.2L41,26.5C42,28.1,41.4,30.2,39.8,31.1z"
          />
          <path
            opacity="0.56"
            d="M31.7,38.7l-0.6,1.1c-0.9,1.6-3,2.2-4.6,1.2l-14.9-8.6c-1.6-0.9-2.2-3-1.2-4.6l0.6-1.1
		c0.9-1.6,3-2.2,4.6-1.2l14.9,8.6C32.1,35,32.7,37.1,31.7,38.7z"
          />
          <path
            opacity="0.64"
            d="M28.5,49.4v1.3c0,1.9-1.5,3.4-3.4,3.4H7.9c-1.9,0-3.4-1.5-3.4-3.4v-1.3
		c0-1.9,1.5-3.4,3.4-3.4h17.3C27,46,28.5,47.5,28.5,49.4z"
          />
          <path
            opacity="0.72"
            d="M31.1,60.2l0.6,1.1c0.9,1.6,0.4,3.7-1.2,4.6l-14.9,8.6c-1.6,0.9-3.7,0.4-4.6-1.2l-0.6-1.1
		c-0.9-1.6-0.4-3.7,1.2-4.6L26.5,59C28.1,58,30.2,58.6,31.1,60.2z"
          />
          <path
            opacity="0.8"
            d="M38.7,68.3l1.1,0.6c1.6,0.9,2.2,3,1.2,4.6l-8.6,14.9c-0.9,1.6-3,2.2-4.6,1.2l-1.1-0.6
		c-1.6-0.9-2.2-3-1.2-4.6l8.6-14.9C35,67.9,37.1,67.3,38.7,68.3z"
          />
          <path
            opacity="0.88"
            d="M49.4,71.5h1.3c1.9,0,3.4,1.5,3.4,3.4v17.3c0,1.9-1.5,3.4-3.4,3.4h-1.3
		c-1.9,0-3.4-1.5-3.4-3.4V74.8C46,73,47.5,71.5,49.4,71.5z"
          />
          <path
            opacity="0.92"
            d="M60.2,68.9l1.1-0.6c1.6-0.9,3.7-0.4,4.6,1.2l8.6,14.9c0.9,1.6,0.4,3.7-1.2,4.6l-1.1,0.6
		c-1.6,0.9-3.7,0.4-4.6-1.2L59,73.5C58,71.9,58.6,69.8,60.2,68.9z"
          />
          <path
            opacity="8.000000e-02"
            d="M68.3,61.3l0.6-1.1c0.9-1.6,3-2.2,4.6-1.2l14.9,8.6c1.6,0.9,2.2,3,1.2,4.6l-0.6,1.1
		c-0.9,1.6-3,2.2-4.6,1.2l-14.9-8.6C67.9,65,67.3,62.9,68.3,61.3z"
          />
          <path
            opacity="0.16"
            d="M71.5,50.6v-1.3c0-1.9,1.5-3.4,3.4-3.4h17.3c1.9,0,3.4,1.5,3.4,3.4v1.3
		c0,1.9-1.5,3.4-3.4,3.4H74.8C73,54,71.5,52.5,71.5,50.6z"
          />
          <path
            opacity="0.24"
            d="M68.9,39.8l-0.6-1.1c-0.9-1.6-0.4-3.7,1.2-4.6l14.9-8.6c1.6-0.9,3.7-0.4,4.6,1.2l0.6,1.1
		c0.9,1.6,0.4,3.7-1.2,4.6L73.5,41C71.9,42,69.8,41.4,68.9,39.8z"
          />
          <path
            opacity="0.32"
            d="M61.3,31.7l-1.1-0.6c-1.6-0.9-2.2-3-1.2-4.6l8.6-14.9c0.9-1.6,3-2.2,4.6-1.2l1.1,0.6
		c1.6,0.9,2.2,3,1.2,4.6l-8.6,14.9C65,32.1,62.9,32.7,61.3,31.7z"
          />
        </g>
      </svg>
    </SpinnerStyles>
  );
};

export const SpinnerStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  margin: 0;
  width: 16px;
  height: 16px;
  animation: spin 600ms steps(12, end) infinite;
  svg {
    path {
      fill: ${({ $ETPtheme }) => $ETPtheme.icon.spinner.path.fill.default};
    }
  }
`;

export default Spinner;
