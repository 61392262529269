import styled from "styled-components";

export const RatingButtonStyles = styled.button`
  padding: 4px 20px;
  border: 1px solid #2b70ae;
  border-radius: 4px;
  flex: 1;
  background-color: transparent;
  height: 24px;
`;
