import { type OptimizelyFeatureFlags } from "./optimizely-feature-flags";

export class FeatureFlag {
  public readonly name: string;
  public readonly uat: boolean;
  public readonly demo: boolean;
  public readonly prod: boolean;

  protected constructor(
    name: string,
    uat: boolean,
    demo: boolean,
    prod: boolean,
  ) {
    this.name = name;
    this.uat = uat;
    this.demo = demo;
    this.prod = prod;
  }

  // public static FEATURE_FLAG_EXAMPLE = new FeatureFlag(
  //   "FEATURE_FLAG_EXAMPLE",
  //   true,
  //   false,
  //   false,
  // );

  // public static OPTIMIZELY_FEATURE_FLAG_KEY_EXAMPLE: keyof OptimizelyFeatureFlag = "optimizely_feature_flag_key_example";

  public static ETP_QUICK_DEAL_FEEDBACK_SURVEY: keyof OptimizelyFeatureFlags =
    "etp_quick_deal_feedback_survey";
}
