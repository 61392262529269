import { createContext, type ReactNode } from "react";
import { useM3PricingLightstreamer } from "./use-m3-pricing-lightstreamer";
import { type InstrumentType } from "../types/instrument-type";
import { type MarketStatus, type PricingData } from "../types/pricing-data";
import { determineEffectivePricingData } from "../utils/effective-pricing-data";

interface SubscriptionProviderProps {
  children: ReactNode;
  instrumentEpic: string;
  instrumentType: InstrumentType;
  snapshotBid: string;
  snapshotOffer: string;
  snapshotMarketStatus: MarketStatus;
}
export const SubscriptionM3Context = createContext<PricingData | null>(null);

export const SubscriptionM3Provider = ({
  children,
  instrumentEpic,
  instrumentType,
  snapshotBid,
  snapshotOffer,
  snapshotMarketStatus,
}: SubscriptionProviderProps) => {
  const lightstreamerPricingData = useM3PricingLightstreamer(
    instrumentEpic,
    instrumentType,
  );
  const effectivePricingData = determineEffectivePricingData(
    snapshotBid,
    snapshotOffer,
    lightstreamerPricingData,
    snapshotMarketStatus,
  );

  return (
    <SubscriptionM3Context.Provider value={effectivePricingData}>
      {children}
    </SubscriptionM3Context.Provider>
  );
};
