import { type DefaultIconProps } from "../../types/default-icon-props";
import styled from "styled-components";

interface ArrowIconProps extends DefaultIconProps {
  direction?: IconDirection;
}
const ArrowIcon = ({
  width = 9,
  height = 9,
  direction = IconDirection.LEFT,
  fill,
}: ArrowIconProps) => {
  function getRotation() {
    switch (direction) {
      case IconDirection.UP:
        return "90.000000";
      case IconDirection.DOWN:
        return "-90.000000";
      case IconDirection.UP_RIGHT:
        return "135.000000";
      case IconDirection.UP_LEFT:
        return "45.000000";
      case IconDirection.DOWN_RIGHT:
        return "-135.000000";
      case IconDirection.DOWN_LEFT:
        return "-45.000000";
      case IconDirection.RIGHT:
        return "180.000000";
      case IconDirection.LEFT:
      default:
        return "0";
    }
  }

  return (
    <ArrowIconContainer>
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox={`0 0 11 11`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        data-testid={`arrow-${direction}-icon`}
        preserveAspectRatio="xMidYMid slice"
      >
        <g
          id="Stage-2"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            data-testid={`arrow-${direction}-color`}
            id="Positions_table-Copy"
            transform={`translate(-1346.000000, -190.000000)`}
            fill={fill}
            fillRule="nonzero"
          >
            <g
              id={`Arrow${direction[0].toUpperCase() + direction.slice(1)}`}
              data-testid={`arrow-${direction}-direction`}
              transform={`translate(1351.514719, 195.704074) rotate(${getRotation()}) translate(-1351.514719, -195.704074) translate(1345.014719, 190.204074)`}
            >
              <path
                d="M12.346246,6.02732129 L2.01371972,6.02732129 L5.41772624,9.42715619 C5.56748917,9.57553189 5.62636121,9.79272751 5.57202941,9.99642419 C5.5176976,10.2001209 5.35847689,10.3591448 5.15471318,10.4132247 C4.95094948,10.4673046 4.73382682,10.4081641 4.58563641,10.2582178 L0.172393414,5.85029215 C0.0620248951,5.74016709 -2.27373675e-12,5.59065925 -2.27373675e-12,5.43474665 C-2.27373675e-12,5.27883405 0.0620248951,5.12932622 0.172393414,5.01920116 L4.58563641,0.611246119 C4.73387317,0.461449827 4.9509362,0.402431697 5.15461458,0.456544388 C5.35829296,0.510657078 5.51744179,0.669626106 5.57178469,0.873243182 C5.62612759,1.07686026 5.56735487,1.29398987 5.41772624,1.44239587 L2.00376073,4.85221914 L12.346246,4.85221914 C12.5561578,4.85221913 12.7501243,4.96420573 12.8550802,5.14599467 C12.9600361,5.32778361 12.9600361,5.55175682 12.8550802,5.73354576 C12.7501243,5.9153347 12.5561578,6.0273213 12.346246,6.02732129 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </ArrowIconContainer>
  );
};

export enum IconDirection {
  LEFT = "left",
  RIGHT = "right",
  UP = "up",
  DOWN = "down",
  UP_LEFT = "up-left",
  UP_RIGHT = "up-right",
  DOWN_LEFT = "down-left",
  DOWN_RIGHT = "down-right",
}

export default ArrowIcon;

const ArrowIconContainer = styled.span`
  padding-left: 3px;
`;
