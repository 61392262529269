import styled from "styled-components";
import { type ETPtheme } from "./theme/etp-theme";
import { GlobalElementsStyle } from "./theme/global-elements-style";

export const QuickDealStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  padding: 0 6px;

  .separator-line::before {
    display: inline-block;
    border-top: ${({ $ETPtheme }) => $ETPtheme.quickDeal.border.beforeLine};
    content: "";
    width: 100%;
    margin: 0 0 4px 0;
  }

  .confirmation-message__container {
    top: -15px;
  }
`;

export const PreviewOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.65);
`;

export const InfoSummaryContainer = styled.div<{
  $ETPtheme: ETPtheme;
}>`
  display: block;
  background: ${({ $ETPtheme }) => $ETPtheme.consideration.background};
  margin: 6px -10px 0 -10px;
  border-top: ${({ $ETPtheme }) => $ETPtheme.consideration.border};
  border-bottom: ${({ $ETPtheme }) => $ETPtheme.quickDeal.border.separatorLine};
  border-width: 1px 0;
  padding: 8px 10px 8px 10px;
`;
