import { resolveInstrumentTypeByString } from "../../utils/resolve-instrument-type";
import { useSubscription } from "../../hooks/use-subscription";
import hasWideSpread from "../../utils/has-wide-spread";
import WarningMessage from "./WarningMessage";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface WideSpreadWarningProps {
  instrumentType: string;
}

export const TIMEOUT = 2000;

const WideSpreadWarning = ({ instrumentType }: WideSpreadWarningProps) => {
  const { t } = useTranslation();
  const resolvedInstrumentType = resolveInstrumentTypeByString(instrumentType);
  const pricingData = useSubscription();
  const isWideSpread = hasWideSpread(pricingData.offer, pricingData.bid);
  const [showComponent, setShowComponent] = useState<boolean>(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (isWideSpread) {
      if (!showComponent) {
        setShowComponent(true);
      }
    } else {
      if (showComponent) {
        timer = setTimeout(() => {
          setShowComponent(false);
        }, TIMEOUT);
      }
    }
    return () => {
      if (timer !== undefined) {
        clearTimeout(timer);
      }
    };
  }, [isWideSpread, showComponent]);

  return (
    <>
      {showComponent && (
        <WarningMessage
          isDialogueBubble={false}
          testId={"wide-spread"}
          message={t(`has-wide-spread.${resolvedInstrumentType}`)}
        />
      )}
    </>
  );
};

export default WideSpreadWarning;
