import DirectionButton from "./DirectionButton";
import { Direction } from "../../types/directions";
import { useSubscription } from "../../hooks/use-subscription";
import { formatVolume } from "../../utils/format-pricing-data";
import { DirectionButtonsStyle } from "./DirectionButtonsStyles";
import { type ETPtheme } from "../../theme/etp-theme";
import { useETPTheme } from "../../hooks/use-etp-theme";

interface DirectionButtonsProps {
  selectedDirection: Direction;
  onDirectionButtonClick: (direction: Direction) => void;
  isSellDisabled: boolean;
  isBuyDisabled: boolean;
  decimalPlacesFactor: number;
  isMarketDepthExpanded: boolean;
}

const DirectionButtons = ({
  selectedDirection,
  onDirectionButtonClick,
  isSellDisabled,
  isBuyDisabled,
  decimalPlacesFactor,
  isMarketDepthExpanded,
}: DirectionButtonsProps) => {
  const ETPtheme: ETPtheme = useETPTheme();
  const pricingData = useSubscription();

  if (isMarketDepthExpanded) {
    return null;
  }

  return (
    <DirectionButtonsStyle $ETPtheme={ETPtheme} data-testid="direction-buttons">
      <DirectionButton
        direction={Direction.SELL}
        isSelected={selectedDirection === Direction.SELL}
        clickHandler={() => {
          onDirectionButtonClick(Direction.SELL);
        }}
        price={pricingData.bid}
        decimalPlacesFactor={decimalPlacesFactor}
        isDisabled={isSellDisabled}
        volume={formatVolume(pricingData?.bidSize)}
      />
      <DirectionButton
        direction={Direction.BUY}
        isSelected={selectedDirection === Direction.BUY}
        clickHandler={() => {
          onDirectionButtonClick(Direction.BUY);
        }}
        price={pricingData.offer}
        decimalPlacesFactor={decimalPlacesFactor}
        isDisabled={isBuyDisabled}
        volume={formatVolume(pricingData?.offerSize)}
      />
    </DirectionButtonsStyle>
  );
};

export default DirectionButtons;
