import { useTranslation } from "react-i18next";
import { convertLocalToCurrencySymbol } from "../../../utils/currency-local-format";
import { useSession } from "../../../hooks/use-session";

interface CommissionProps {
  commission: number | undefined;
  commissionCurrency: string | undefined;
}

const Commission = ({ commission, commissionCurrency }: CommissionProps) => {
  const { t } = useTranslation();
  const { sessionData } = useSession();

  return (
    <>
      <dt id="etp-quick-deal-preview-commission">
        {t("costs-and-charges.details.commission.label")}
      </dt>
      <dd aria-labelledby="etp-quick-deal-preview-commission">
        {convertLocalToCurrencySymbol(
          commission,
          sessionData.clientLocale,
          commissionCurrency,
        )}
      </dd>
    </>
  );
};

export default Commission;
