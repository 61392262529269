import { type Ticket } from "./ticket";
import { type Prices } from "./prices";

export interface TicketValidator {
  validate: (ticket: Ticket, prices: Prices) => ValidationResult;
}

export class ValidationResult {
  private readonly _validationError: ValidationError | null;

  constructor(validationError: ValidationError | null) {
    this._validationError = validationError;
  }

  isSuccess() {
    return this._validationError === null;
  }

  isError() {
    return !this.isSuccess();
  }

  getError() {
    if (this !== undefined && this._validationError !== null) {
      return this._validationError;
    }
    throw new Error("ValidationError is null");
  }
}

export class ValidationError {
  key: ValidationErrors;
  googleAnalyticsCode: string;

  constructor(key: ValidationErrors, googleAnalyticsCode: string) {
    this.key = key;
    this.googleAnalyticsCode = googleAnalyticsCode;
  }
}

export interface SizeDetails {
  existingPositionDealSize: number;
  sellOrderSize: number;
}

export class SizeNotAboveMaxValidationError extends ValidationError {
  sizeDetails: SizeDetails;
  constructor(
    key: ValidationErrors,
    googleAnalyticsCode: string,
    sizeDetails: SizeDetails,
  ) {
    super(key, googleAnalyticsCode);
    this.sizeDetails = sizeDetails;
  }
}

export const SUCCESSFUL_RESULT = new ValidationResult(null);

export enum ValidationErrors {
  SIZE_INPUT_WARNING_ABOVE_MAX = "size-input-warning-above-max",
  SIZE_INPUT_WARNING_EXISTING_WORKING_ORDERS = "size-input-warning-existing-working-orders",
  SIZE_INPUT_WARNING_ZERO = "size-input-warning-zero",
  LIMIT_INPUT_WARNING_ZERO = "limit-input-warning-zero",
  STOP_INPUT_WARNING_ZERO = "stop-input-warning-zero",
  STOP_INPUT_LEVEL_ABOVE_BID = "stop-input-level-above-bid",
}
