import { type PositionAdapter } from "../../types/position-adapter";
import { type Position } from "../../types/position";
import { type LightstreamerService } from "../../services/lightstreamer";
import { PositionGroupSubscription, PositionService } from "ig-trading";
import { type IgTradingPositionService } from "./types";

export default class IgTradingPositionAdapter implements PositionAdapter {
  private readonly positionService: IgTradingPositionService;
  private readonly accountId: string;
  private readonly positionStreamingClient: any;
  private readonly positionSubscription;

  constructor(
    lightstreamerService: LightstreamerService,
    accountId: string,
    positionService: IgTradingPositionService,
  ) {
    this.positionService = positionService;
    this.accountId = accountId;
    this.positionStreamingClient =
      PositionService.getStreamingClient(lightstreamerService);
    this.positionSubscription = new PositionGroupSubscription(
      lightstreamerService,
      1,
    );
  }

  async getExistingPosition(instrumentEpic: string): Promise<Position | null> {
    return await this.positionService
      .getAll()
      .then(({ positions }: { positions: Position[] }) => {
        return (
          positions.find(
            (position: Position) => position.epic === instrumentEpic,
          ) ?? null
        );
      });
  }

  startListeningForUpdates = (
    existingPosition: Position | null,
    onAdded: (position: Position) => void,
    onRemoved: (dealId: string) => void,
    onChanged: () => void,
  ): void => {
    this.subscribeToLiveSizeUpdates(existingPosition, onChanged);
    this.positionStreamingClient.on("ADDED", onAdded);
    this.positionStreamingClient.on("REMOVED", onRemoved);
    this.positionStreamingClient.startListening(this.accountId);
  };

  stopListeningForUpdates = () => {
    this.positionSubscription.destroy();
    this.positionStreamingClient.stopListening();
  };

  subscribeToLiveSizeUpdates = (
    existingPosition: Position | null,
    onChanged: () => void,
  ) => {
    if (existingPosition !== null) {
      const igTradingPositionUpdateHandler =
        existingPosition.boundOnStreamedUpdate;
      existingPosition.boundOnStreamedUpdate = (itemUpdate: any) => {
        if (igTradingPositionUpdateHandler != null) {
          igTradingPositionUpdateHandler(itemUpdate);
        }
        onChanged();
      };
      this.positionSubscription.add(existingPosition);
    }
  };

  /*
   * This subscription checks for live updates on size changes of an existing position.
   * It is not the same listener that checks if a position has been opened or closed.
   */
  removeSizeUpdateSubscription = (existingPosition: Position | null) => {
    if (existingPosition !== null) {
      this.positionSubscription.remove(existingPosition);
    }
  };
}
