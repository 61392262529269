import styled from "styled-components";

export const PriceTickStyles = styled.span`
  &.direction-button__price-tick-direction {
    animation: price-tick 300ms forwards steps(2, end);

    &-up {
      transform: rotate(0);
    }

    &-down {
      transform: rotate(180deg) translateY(1px);
    }
  }

  @keyframes price-tick {
    99% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
