import { t } from "i18next";
import { useState } from "react";
import { RatingButtonStyles } from "./RatingButtonStyles";

const thumb = {
  UP: {
    maskId: "thumb_up",
    mask: "url(#thumb_up)",
    path: "M11.9997 14H4.66634V5.33333L9.33301 0.666668L10.1663 1.5C10.2441 1.57778 10.308 1.68333 10.358 1.81667C10.408 1.95 10.433 2.07778 10.433 2.2V2.43333L9.69967 5.33333H13.9997C14.3552 5.33333 14.6663 5.46667 14.933 5.73333C15.1997 6 15.333 6.31111 15.333 6.66667V8C15.333 8.07778 15.3219 8.16111 15.2997 8.25C15.2775 8.33889 15.2552 8.42222 15.233 8.5L13.233 13.2C13.133 13.4222 12.9663 13.6111 12.733 13.7667C12.4997 13.9222 12.2552 14 11.9997 14ZM5.99967 12.6667H11.9997L13.9997 8V6.66667H7.99967L8.89967 3L5.99967 5.9V12.6667ZM4.66634 5.33333V6.66667H2.66634V12.6667H4.66634V14H1.33301V5.33333H4.66634Z",
    hovered: {
      maskId: "thumb_up_hovered",
      mask: "url(#thumb_up_hovered)",
      path: "M11.9997 14H5.33301V5.33333L9.99967 0.666666L10.833 1.5C10.9108 1.57778 10.9747 1.68333 11.0247 1.81667C11.0747 1.95 11.0997 2.07778 11.0997 2.2V2.43333L10.3663 5.33333H13.9997C14.3552 5.33333 14.6663 5.46667 14.933 5.73333C15.1997 6 15.333 6.31111 15.333 6.66667V8C15.333 8.07778 15.3247 8.16111 15.308 8.25C15.2913 8.33889 15.2663 8.42222 15.233 8.5L13.233 13.2C13.133 13.4222 12.9663 13.6111 12.733 13.7667C12.4997 13.9222 12.2552 14 11.9997 14ZM3.99967 5.33333V14H1.33301V5.33333H3.99967Z",
    },
  },
  DOWN: {
    maskId: "thumb_down",
    mask: "url(#thumb_down)",
    path: "M4.00033 2H11.3337V10.6667L6.66699 15.3333L5.83366 14.5C5.75588 14.4222 5.69199 14.3167 5.64199 14.1833C5.59199 14.05 5.56699 13.9222 5.56699 13.8V13.5667L6.30033 10.6667H2.00033C1.64477 10.6667 1.33366 10.5333 1.06699 10.2667C0.800326 10 0.666992 9.68889 0.666992 9.33333V8C0.666992 7.92222 0.678103 7.83889 0.700326 7.75C0.722548 7.66111 0.74477 7.57778 0.766992 7.5L2.76699 2.8C2.86699 2.57778 3.03366 2.38889 3.26699 2.23333C3.50033 2.07778 3.74477 2 4.00033 2ZM10.0003 3.33333H4.00033L2.00033 8V9.33333H8.00033L7.10033 13L10.0003 10.1V3.33333ZM11.3337 10.6667V9.33333H13.3337V3.33333H11.3337V2H14.667V10.6667H11.3337Z",
    hovered: {
      maskId: "thumb_down_hovered",
      mask: "url(#thumb_down_hovered)",
      path: "M4.00033 2H10.667V10.6667L6.00033 15.3333L5.16699 14.5C5.08921 14.4222 5.02533 14.3167 4.97533 14.1833C4.92533 14.05 4.90033 13.9222 4.90033 13.8V13.5667L5.63366 10.6667H2.00033C1.64477 10.6667 1.33366 10.5333 1.06699 10.2667C0.800326 10 0.666992 9.68889 0.666992 9.33333V8C0.666992 7.92222 0.675326 7.83889 0.691992 7.75C0.708659 7.66111 0.733659 7.57778 0.766992 7.5L2.76699 2.8C2.86699 2.57778 3.03366 2.38889 3.26699 2.23333C3.50033 2.07778 3.74477 2 4.00033 2ZM12.0003 10.6667V2H14.667V10.6667H12.0003Z",
    },
  },
};

export enum ThumbType {
  UP = "UP",
  DOWN = "DOWN",
}

interface ThumbIconProps {
  thumbType: ThumbType;
  clickHandler: () => void;
}

const RatingButton = ({ thumbType, clickHandler }: ThumbIconProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <RatingButtonStyles
      onClick={(event) => {
        clickHandler();
      }}
      onPointerEnter={(event) => {
        setIsHovered(true);
      }}
      onPointerLeave={(event) => {
        setIsHovered(false);
      }}
      type="button"
      aria-label={t(`rating-button.${thumbType}`)}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id={
            isHovered
              ? thumb[thumbType].hovered.maskId
              : thumb[thumbType].maskId
          }
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <rect width="16" height="16" fill="#2B70AE" />
        </mask>
        <g
          mask={
            isHovered ? thumb[thumbType].hovered.mask : thumb[thumbType].mask
          }
        >
          <path
            d={
              isHovered ? thumb[thumbType].hovered.path : thumb[thumbType].path
            }
            fill="#2B70AE"
          />
        </g>
      </svg>
    </RatingButtonStyles>
  );
};

export default RatingButton;
