import styled, { css } from "styled-components";
import { GlobalElementsStyle } from "../../../theme/global-elements-style";
import { type ETPtheme } from "../../../theme/etp-theme";
import { PointerLocation } from "./Tooltip";

export const TooltipStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
  $isVisible: boolean;
  $pointerLocation: PointerLocation;
  $pointerPosition: number;
}>`
  padding: 5px 16px 11px;
  border-radius: 2px;
  box-shadow: ${({ $ETPtheme }) => $ETPtheme.tooltip.boxShadow};
  border: ${({ $ETPtheme }) => $ETPtheme.tooltip.border};
  background-color: ${({ $ETPtheme }) => $ETPtheme.tooltip.background};

  &:after {
    ${({ $isVisible, $pointerLocation }) =>
      $pointerLocation !== PointerLocation.NONE &&
      $isVisible &&
      css<{
        $ETPtheme: ETPtheme;
        $pointerLocation: PointerLocation;
        $pointerPosition: number;
      }>`
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: ${({ $ETPtheme }) => $ETPtheme.tooltip.background};
        border-bottom-right-radius: 1.5px;
        border-right: ${({ $ETPtheme }) => $ETPtheme.tooltip.border};
        border-bottom: ${({ $ETPtheme }) => $ETPtheme.tooltip.border};

        ${({ $pointerPosition }) => {
          switch ($pointerLocation) {
            case PointerLocation.TOP:
              return css<{ $ETPtheme: ETPtheme }>`
                top: -5px;
                left: ${$pointerPosition}%;
                transform: translateX(-50%) rotate(-135deg);
              `;
            case PointerLocation.LEFT:
              return css<{ $ETPtheme: ETPtheme }>`
                left: -5px;
                top: ${$pointerPosition}%;
                transform: translateY(-63%) rotate(135deg);
              `;
            case PointerLocation.RIGHT:
              return css<{ $ETPtheme: ETPtheme }>`
                right: -5px;
                top: ${$pointerPosition}%;
                transform: translateY(-63%) rotate(-45deg);
              `;
            default:
              return css<{ $ETPtheme: ETPtheme }>`
                bottom: -5px;
                left: ${$pointerPosition}%;
                box-shadow: rgba(0, 41, 84, 0.15) 5px 5px 4px -4px;
                transform: translateX(-50%) rotate(45deg);
              `;
          }
        }}
      `}
  }
`;

export const TooltipContentStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  p {
    color: ${({ $ETPtheme }) => $ETPtheme.tooltip.text.default};
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const TargetContainerStyles = styled.button`
  &#etp-quick-deal_react-tether-target-element {
    background: transparent;
    border: none;
    cursor: help;
    padding: 0;
    width: auto;
  }
`;
