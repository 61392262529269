import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import DarkTheme from "../theme/etp-dark-theme";
import LightTheme from "../theme/etp-light-theme";
import { StyleProvider } from "ig-phoenix";
import { type ETPtheme } from "../theme/etp-theme";
import SharedStyles from "../theme/shared-styles";

const ETPThemeContext = createContext<ETPtheme>(LightTheme);

export const ETPStyleProvider = ({
  children,
  WTPtheme,
}: {
  children: ReactNode;
  WTPtheme: "Light" | "Dark";
}) => {
  const [theme, setTheme] = useState<ETPtheme>(
    WTPtheme === "Dark" ? DarkTheme : LightTheme,
  );

  useEffect(() => {
    setTheme(WTPtheme === "Dark" ? DarkTheme : LightTheme);
  }, [WTPtheme]);

  return (
    <StyleProvider colorScheme={WTPtheme}>
      <ETPThemeContext.Provider value={theme}>
        <SharedStyles $ETPtheme={theme} />
        {children}
      </ETPThemeContext.Provider>
    </StyleProvider>
  );
};

export const useETPTheme = () => {
  return useContext(ETPThemeContext);
};
