import styled from "styled-components";
import { GlobalElementsStyle } from "../../theme/global-elements-style";
import { type ETPtheme } from "../../theme/etp-theme";

export const ConsiderationStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
  $isPreviewing: boolean;
}>`
  display: flex;
  flex-direction: ${({ $isPreviewing }) => ($isPreviewing ? "column" : "row")};
  text-size-adjust: 100%;
  justify-content: space-between;

  .consideration__title p {
    font-weight: 400;
    margin: 0;
    color: ${({ $ETPtheme, $isPreviewing }) =>
      $isPreviewing
        ? $ETPtheme.consideration.text.title.previewTicket
        : $ETPtheme.consideration.text.title.mainTicket};
    text-decoration-color: ${({ $ETPtheme, $isPreviewing }) =>
      $isPreviewing
        ? $ETPtheme.consideration.text.decorationColor.previewTicket
        : $ETPtheme.consideration.text.decorationColor.mainTicket};
    text-decoration: underline dotted auto;
  }

  .consideration__prices {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &-section {
      display: flex;
      text-align: right;
      justify-content: flex-end;
    }

    &-header {
      color: ${({ $ETPtheme }) =>
        $ETPtheme.consideration.text.currentAndCalculatedHeaders};
      padding-right: 2px;
    }

    &-price {
      color: ${({ $ETPtheme, $isPreviewing }) =>
        $isPreviewing
          ? $ETPtheme.consideration.text.price.previewTicket
          : $ETPtheme.consideration.text.price.mainTicket};
      font-weight: ${({ $isPreviewing }) => ($isPreviewing ? "600" : "400")};
      margin: 0;
    }
  }
`;
