import { formatStringPrice } from "../../utils/format-pricing-data";
import { type Direction } from "../../types/directions";
import { MarketDepthRowStyles } from "./MarketDepthRowStyles";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { type Dispatch } from "react";
import {
  type TicketAction,
  TicketActions,
} from "../../hooks/reducers/ticket-reducer";

interface MarketDepthRowProps {
  price: string | undefined;
  volume: string | null | undefined;
  decimalPlacesFactor: number;
  direction: Direction;
  disabled: boolean;
  dispatch: Dispatch<TicketAction>;
}

const MarketDepthRow = ({
  price,
  volume,
  decimalPlacesFactor,
  direction,
  disabled,
  dispatch,
}: MarketDepthRowProps) => {
  const ETPtheme = useETPTheme();

  let parsedVolume = "-";
  if (volume !== null && volume !== undefined && volume !== "") {
    parsedVolume = volume;
  }
  return (
    <MarketDepthRowStyles
      $direction={direction}
      $ETPtheme={ETPtheme}
      data-testid="market-depth-row"
    >
      <button
        type="button"
        disabled={disabled}
        onClick={() => {
          dispatch({
            type: TicketActions.UPDATE_TRIGGER_PRICE,
            payload: {
              triggerPrice: Number(
                formatStringPrice(price, decimalPlacesFactor),
              ),
            },
          });
        }}
      >
        <strong data-testid="market-depth-row-price">
          {formatStringPrice(price, decimalPlacesFactor)}
        </strong>
      </button>
      <button
        type="button"
        disabled={disabled}
        onClick={() => {
          dispatch({
            type: TicketActions.UPDATE_TICKET_SIZE,
            payload: {
              size: Number(parsedVolume),
            },
          });
        }}
      >
        <p data-testid="market-depth-row-volume">{parsedVolume}</p>
      </button>
    </MarketDepthRowStyles>
  );
};

export default MarketDepthRow;
