import { IgEnvironment } from "../types/ig-environment";

export const resolveDomain = (env: string) => {
  if (env === IgEnvironment.UAT) {
    return "web-";
  } else if (env === IgEnvironment.DEMO) {
    return "demo-";
  } else if (env === IgEnvironment.PROD) {
    return "";
  }
  return "net-";
};
