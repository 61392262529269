import { type Instrument } from "../types/instrument";
import { InstrumentType } from "../types/instrument-type";

export const resolveInstrumentTypeByInstrument = (
  instrument: Instrument,
): InstrumentType => {
  return resolveInstrumentTypeByString(instrument.instrumentType);
};

export const resolveInstrumentTypeByString = (
  instrumentType: string,
): InstrumentType => {
  if (instrumentType.includes("CONSTANT_LEVERAGE")) {
    return InstrumentType.CONSTANT_LEVERAGE;
  } else if (instrumentType.includes("COVERED_WARRANT")) {
    return InstrumentType.WARRANT;
  } else {
    return InstrumentType.TURBO;
  }
};
