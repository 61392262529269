export enum ChevronPointingDirection {
  UP = "M 1 5 L 5 1 L 9 5",
  DOWN = "M 1 1 L 5 5 L 9 1",
}

interface ChevronIconProps {
  pointingDirection: ChevronPointingDirection;
}

const ChevronIcon = ({ pointingDirection }: ChevronIconProps) => {
  return (
    <svg
      className="etp-quick-deal__chevron-icon"
      width="10px"
      height="5px"
      viewBox="0 0 10 6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={pointingDirection}
        fill="none"
        stroke="black"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronIcon;
