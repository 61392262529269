import {
  SUCCESSFUL_RESULT,
  type TicketValidator,
  ValidationError,
  ValidationErrors,
  ValidationResult,
} from "../../types/ticket-validation";
import { type Ticket } from "../../types/ticket";
import { OrderType } from "../../types/ig-trading-order-ticket";

export default class StopNotZeroValidator implements TicketValidator {
  validate(ticket: Ticket): ValidationResult {
    if (
      ticket.stopPrice === undefined &&
      ticket.orderType !== OrderType.STOP_MARKET
    ) {
      return SUCCESSFUL_RESULT;
    }

    if (ticket.stopPrice !== undefined && ticket.stopPrice > 0) {
      return SUCCESSFUL_RESULT;
    }

    return new ValidationResult(
      new ValidationError(
        ValidationErrors.STOP_INPUT_WARNING_ZERO,
        "STOP_REQUIRES_LEVEL",
      ),
    );
  }
}
