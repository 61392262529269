import styled from "styled-components";
import { GlobalElementsStyle } from "../../theme/global-elements-style";
import { type ETPtheme } from "../../theme/etp-theme";

export const ViewKidStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  padding-top: 8px;
  line-height: 10px;
  text-size-adjust: 100%;
`;
