import { type Dispatch, useEffect, useState } from "react";
import { OrderTabsStyles } from "./OrderTabsStyles";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { type ETPtheme } from "../../theme/etp-theme";
import type { InstrumentType } from "../../types/instrument-type";
import type { Ticket } from "../../types/ticket";
import OrderTabContent from "./OrderTabContent";
import { OrderType, TimeInForce } from "../../types/ig-trading-order-ticket";
import {
  type TicketAction,
  TicketActions,
} from "../../hooks/reducers/ticket-reducer";
import { OrderTypeTab } from "../../types/order-type-tab";
import { type GaTracer } from "../../google-analytics/ga-tracer";
import { useTranslation } from "react-i18next";

interface OrderTabsProps {
  resolvedInstrumentType: InstrumentType;
  ticket: Ticket;
  setTicketSize: (ticketSize: number | null) => void;
  dispatch: Dispatch<TicketAction>;
  availablePosition: number;
  isQuickDealDisplayed: boolean;
  gaTracer: GaTracer;
}
const OrderTabs = ({
  resolvedInstrumentType,
  ticket,
  setTicketSize,
  dispatch,
  availablePosition,
  isQuickDealDisplayed,
  gaTracer,
}: OrderTabsProps) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<OrderTypeTab>(
    OrderTypeTab.MARKET_ORDERS,
  );
  const ETPtheme: ETPtheme = useETPTheme();

  useEffect(() => {
    if (isQuickDealDisplayed) {
      gaTracer.trackOrderTabTypeInteraction(
        ticket.instrument.instrumentName,
        ticket.instrument.epic,
        selectedTab,
      );
    }
  }, [
    gaTracer,
    selectedTab,
    ticket.instrument.epic,
    ticket.instrument.instrumentName,
    isQuickDealDisplayed,
  ]);

  return (
    <OrderTabsStyles $ETPtheme={ETPtheme} $selectedTab={selectedTab}>
      <div role="tablist" aria-label="Order type tabs">
        <button
          id={`tab-${OrderTypeTab.MARKET_ORDERS}`}
          type="button"
          role="tab"
          data-cy={`tab-${OrderTypeTab.MARKET_ORDERS}`}
          aria-selected={selectedTab === OrderTypeTab.MARKET_ORDERS}
          tabIndex={0}
          onClick={() => {
            dispatch({
              type: TicketActions.UPDATE_ORDER_TYPE,
              payload: { orderType: OrderType.MARKET },
            });
            dispatch({
              type: TicketActions.UPDATE_TIME_IN_FORCE,
              payload: { timeInForce: TimeInForce.IMMEDIATE_OR_CANCEL },
            });
            dispatch({
              type: TicketActions.UPDATE_TICKET_VALIDATION_ERROR,
              payload: { validationErrors: [] },
            });
            setSelectedTab(OrderTypeTab.MARKET_ORDERS);
          }}
        >
          {t("order-tab.market-order")}
        </button>
        <button
          id={`tab-${OrderTypeTab.STOPS_AND_LIMITS}`}
          type="button"
          role="tab"
          data-cy={`tab-${OrderTypeTab.STOPS_AND_LIMITS}`}
          aria-selected={selectedTab === OrderTypeTab.STOPS_AND_LIMITS}
          tabIndex={-1}
          onClick={() => {
            dispatch({
              type: TicketActions.UPDATE_ORDER_TYPE,
              payload: { orderType: OrderType.LIMIT },
            });
            dispatch({
              type: TicketActions.UPDATE_TIME_IN_FORCE,
              payload: { timeInForce: TimeInForce.GOOD_TILL_CANCELLED },
            });
            dispatch({
              type: TicketActions.UPDATE_TICKET_VALIDATION_ERROR,
              payload: { validationErrors: [] },
            });
            setSelectedTab(OrderTypeTab.STOPS_AND_LIMITS);
          }}
        >
          {t("order-tab.stop-limit")}
        </button>
      </div>
      <OrderTabContent
        orderTypeTab={selectedTab}
        resolvedInstrumentType={resolvedInstrumentType}
        ticket={ticket}
        setTicketSize={setTicketSize}
        dispatch={dispatch}
        availablePosition={availablePosition}
        isQuickDealDisplayed={isQuickDealDisplayed}
      />
    </OrderTabsStyles>
  );
};

export default OrderTabs;
