import styled from "styled-components";
import { type ETPtheme } from "../../theme/etp-theme";

export const OrderButtonStyles = styled.div<{
  $ETPtheme: ETPtheme;
}>`
  &.etp-quick-deal_order-button {
    padding: 8px 0 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;

    button {
      font-size: 13px;
      width: 100%;
      height: 24px;
    }
  }
`;

export const OrderInProgress = styled.span`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;
