import { Notification } from "ig-phoenix";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { type ETPtheme } from "../../theme/etp-theme";
import { WarningMessageStyles } from "./WarningMessageStyles";

interface ConfirmationMessageProps {
  testId: string;
  message: string;
  topOffset?: number;
  isDialogueBubble?: boolean;
}

const WarningMessage = ({
  testId,
  message,
  topOffset = 0,
  isDialogueBubble = true,
}: ConfirmationMessageProps) => {
  const ETPtheme: ETPtheme = useETPTheme();

  return (
    <WarningMessageStyles
      $ETPtheme={ETPtheme}
      $topOffset={topOffset}
      isDialogueBubble={isDialogueBubble}
    >
      <Notification
        variant="warning"
        onCloseClick={() => {}} // the mouse click on document.EventListener("click") will close the notification
        data-testid={`${testId}-warning`}
      >
        <p data-testid={`${testId}-warning-text`}>{message}</p>
      </Notification>
    </WarningMessageStyles>
  );
};

export default WarningMessage;
