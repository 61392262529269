import styled from "styled-components";
import { GlobalElementsStyle } from "../../theme/global-elements-style";

export const DirectionButtonsStyle = styled(GlobalElementsStyle)`
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: flex-end;
  margin: 12px 0 4px 0;
`;
