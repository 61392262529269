import { MarketStatus } from "../types/pricing-data";
import { useSubscription } from "./use-subscription";
import { useTranslation } from "react-i18next";

const useTradeableInstrumentChecker = () => {
  const pricingData = useSubscription();
  const { t } = useTranslation();

  let reasonMessageKey: string | null;

  switch (pricingData.marketStatus) {
    case MarketStatus.TRADEABLE:
      reasonMessageKey = null;
      break;
    case MarketStatus.AUCTION:
    case MarketStatus.AUCTION_NO_EDIT:
    case MarketStatus.CLOSED:
    case MarketStatus.SUSPENDED:
      reasonMessageKey = "instrument-non-tradeable-reason.market-closed";
      break;
    case MarketStatus.OFFLINE:
      reasonMessageKey = "instrument-non-tradeable-reason.market-offline";
      break;
    case MarketStatus.UNAVAILABLE:
      reasonMessageKey = "instrument-non-tradeable-reason.market-unavailable";
      break;
    case MarketStatus.CLOSE_ONLY:
    case MarketStatus.EDIT:
    case MarketStatus.NONTRADEABLE:
      reasonMessageKey =
        "instrument-non-tradeable-reason.market-not-open-to-trade";
      break;
    default:
      reasonMessageKey = "instrument-non-tradeable-reason.market-closed";
      break;
  }
  return {
    isTradeable: reasonMessageKey === null,
    reasonMessage: reasonMessageKey === null ? null : t(reasonMessageKey),
  };
};

export default useTradeableInstrumentChecker;
