import styled from "styled-components";
import { TooltipContentStyles } from "../generics/tooltip/TooltipStyles";
import { type ETPtheme } from "../../theme/etp-theme";

export const PriceCalculatorToolTipContentStyles = styled(
  TooltipContentStyles,
)<{
  $ETPtheme: ETPtheme;
}>`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .etp-quick-deal_price-calculator-tooltip__disclaimer {
    font-size: 11px;
    line-height: 16px;
    color: ${({ $ETPtheme }) =>
      $ETPtheme.tooltip.text.priceCalculatorDisclaimer};
  }
`;
