import { IgEnvironment } from "../types/ig-environment";
import { type FeatureFlag } from "../types/feature-flag";
import { type OptimizelyFeatureFlags } from "../types/optimizely-feature-flags";

export class FeatureFlagLookUpService {
  private readonly _environment: IgEnvironment;
  private readonly _optimizelyFeatureFlag: OptimizelyFeatureFlags;

  constructor(
    environment: IgEnvironment,
    _optimizelyFeatureFlag: OptimizelyFeatureFlags = {},
  ) {
    this._environment = environment;
    this._optimizelyFeatureFlag = _optimizelyFeatureFlag;
  }

  isEnabled = (featureFlag: FeatureFlag | keyof OptimizelyFeatureFlags) => {
    const optimizelyFeatureFlag =
      this._optimizelyFeatureFlag[featureFlag as keyof OptimizelyFeatureFlags];

    if (optimizelyFeatureFlag !== undefined) {
      return optimizelyFeatureFlag.enabled ?? false;
    }

    const internalFeatureFlag = featureFlag as FeatureFlag;
    switch (this._environment) {
      case IgEnvironment.MIRAGE:
      case IgEnvironment.TEST:
      case IgEnvironment.UAT:
        return internalFeatureFlag.uat;
      case IgEnvironment.DEMO:
        return internalFeatureFlag.demo;
      case IgEnvironment.PROD:
        return internalFeatureFlag.prod;
    }
  };
}
