import { Label } from "ig-phoenix";
import { useETPTheme } from "../../../hooks/use-etp-theme";
import { type ETPtheme } from "../../../theme/etp-theme";
import { GenericInputStyle } from "./InputStyles";
import { NumericInput } from "../numericInput/NumericInput";

interface GenericInputProps {
  container: {
    id: string;
  };
  numericInput: {
    id: string;
    "data-testid": string;
    label: string;
    hasError: boolean;
    handleValidationErrors: () => void;
    initialValue?: number;
    value?: string;
    setValue: (value: number | null) => void;
    placeholder?: "0";
    step?: number;
    min?: number;
    max?: number;
    showArrows?: boolean;
    state?: "active" | "inactive" | "disabled";
  };
}

const Input = ({ container, numericInput }: GenericInputProps) => {
  const ETPtheme: ETPtheme = useETPTheme();
  const { handleValidationErrors, setValue, ...inputProperties } = numericInput;

  return (
    <GenericInputStyle
      id={container.id}
      onClick={handleValidationErrors}
      onMouseEnter={handleValidationErrors}
      $ETPtheme={ETPtheme}
      $hasError={numericInput.hasError}
    >
      <Label htmlFor={numericInput.id}>{numericInput.label}</Label>
      <NumericInput {...inputProperties} onUpdate={setValue} />
    </GenericInputStyle>
  );
};

export default Input;
