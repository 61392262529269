import styled from "styled-components";
import { GlobalElementsStyle } from "../../../theme/global-elements-style";
import { type ETPtheme } from "../../../theme/etp-theme";

export const GenericInputStyle = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
  $hasError: boolean;
}>`
  display: flex;
  align-items: baseline;
  position: relative;

  label {
    margin: 0;
  }

  input {
    border-radius: 2px;
    font-size: 13px;
    letter-spacing: -0.7px;
    background: ${({ $ETPtheme }) =>
      $ETPtheme.genericInput.inputField.background};
    border: ${({ $ETPtheme, $hasError }) =>
      $hasError
        ? $ETPtheme.genericInput.inputField.border.warning
        : $ETPtheme.genericInput.inputField.border.focusDisabled};
    color: ${({ $ETPtheme }) => $ETPtheme.genericInput.inputField.text};

    &:focus:not([disabled]),
    &:focus-within input:not([disabled]),
    &:hover input:focus:not([disabled]) {
      border: ${({ $ETPtheme }) =>
        $ETPtheme.genericInput.inputField.border.focusEnabled};
    }
  }

  button {
    position: absolute;
    z-index: 1;
    &:hover {
      background-color: ${({ $ETPtheme }) =>
        $ETPtheme.genericInput.incrementerButtons.background.hover};
    }

    &:disabled {
      background-color: unset;

      &:first-of-type {
        &::before {
          border-top-color: ${({ $ETPtheme }) =>
            $ETPtheme.genericInput.incrementerButtons.border.disabled};
          border-right-color: ${({ $ETPtheme }) =>
            $ETPtheme.genericInput.incrementerButtons.border.disabled};
        }
      }

      &:last-of-type {
        &::before {
          border-bottom-color: ${({ $ETPtheme }) =>
            $ETPtheme.genericInput.incrementerButtons.border.disabled};
          border-left-color: ${({ $ETPtheme }) =>
            $ETPtheme.genericInput.incrementerButtons.border.disabled};
        }
      }
    }

    &:first-of-type {
      &:hover:before:not(:disabled) {
        border-top-color: ${({ $ETPtheme }) =>
          $ETPtheme.genericInput.incrementerButtons.border.hover};
        border-right-color: ${({ $ETPtheme }) =>
          $ETPtheme.genericInput.incrementerButtons.border.hover};
      }

      &::before {
        border-top-color: ${({ $ETPtheme }) =>
          $ETPtheme.genericInput.incrementerButtons.border.default};
        border-right-color: ${({ $ETPtheme }) =>
          $ETPtheme.genericInput.incrementerButtons.border.default};
      }
    }

    &:last-of-type {
      &:hover:before:not(:disabled) {
        border-bottom-color: ${({ $ETPtheme }) =>
          $ETPtheme.genericInput.incrementerButtons.border.hover};
        border-left-color: ${({ $ETPtheme }) =>
          $ETPtheme.genericInput.incrementerButtons.border.hover};
      }

      &::before {
        border-bottom-color: ${({ $ETPtheme }) =>
          $ETPtheme.genericInput.incrementerButtons.border.default};
        border-left-color: ${({ $ETPtheme }) =>
          $ETPtheme.genericInput.incrementerButtons.border.default};
      }
    }
  }
`;
