import { useETPTheme } from "../../hooks/use-etp-theme";
import { useTranslation } from "react-i18next";
import { RatingFormStyles } from "./RatingFormStyles";
import RatingButton, { ThumbType } from "./RatingButton";
import React from "react";

interface RatingFormProps {
  handleAddRating: (hasLiked: boolean) => void;
}
const RatingForm = ({ handleAddRating }: RatingFormProps) => {
  const ETPtheme = useETPTheme();
  const { t } = useTranslation();

  return (
    <RatingFormStyles
      data-testid="etp-quick-deal__rating-form"
      $ETPtheme={ETPtheme}
    >
      <p>{t("feedback-form.rating-stage.title")}</p>
      <div className="etp-quick-deal__rating-form__buttons-container">
        <RatingButton
          thumbType={ThumbType.UP}
          clickHandler={() => {
            handleAddRating(true);
          }}
        />
        <RatingButton
          thumbType={ThumbType.DOWN}
          clickHandler={() => {
            handleAddRating(false);
          }}
        />
      </div>
    </RatingFormStyles>
  );
};

export default RatingForm;
