import { type Session } from "../../types/session";
import { type Instrument } from "../../types/instrument";
import { type OpeningCostsAndChargesResponse } from "../../types/opening-costs-and-charges-response";
import { type ClosingCostsAndChargesResponse } from "../../types/closing-costs-and-charges-response";
import type CostsAndChargesAdapter from "../../types/costs-and-charges-adapter";
import { wtpPost } from "../wtp-fetch-adapter";

export default class IgApiCostsAndChargesAdapter
  implements CostsAndChargesAdapter
{
  private readonly session: Session;
  constructor(session: Session) {
    this.session = session;
  }

  async getOpeningCostsAndCharges(
    size: number,
    instrument: Instrument,
    dealReference: string,
    bid: number,
    offer: number,
    priceLevel: number | undefined,
  ): Promise<OpeningCostsAndChargesResponse> {
    const currentAccountId = this.session.sessionData.currentAccountId;

    const openingPayload = this.getCommonPayload(
      currentAccountId,
      instrument,
      size,
      priceLevel,
      dealReference,
      bid,
      offer,
    );

    const urlSuffix = `/dealing-gateway/costsandcharges/${currentAccountId}/open`;
    const body = {
      ...openingPayload,
      stop: null,
      limit: null,
      epic: instrument.epic,
    };
    const response = await wtpPost(urlSuffix, this.session, body);
    return await response.json();
  }

  async getClosingCostsAndCharges(
    size: number,
    openingLevel: number,
    instrument: Instrument,
    dealReference: string,
    bid: number,
    offer: number,
    priceLevel: number | undefined,
  ): Promise<ClosingCostsAndChargesResponse> {
    const currentAccountId = this.session.sessionData.currentAccountId;

    const closingPayload = this.getCommonPayload(
      currentAccountId,
      instrument,
      size,
      priceLevel,
      dealReference,
      bid,
      offer,
    );
    const urlSuffix = `/dealing-gateway/costsandcharges/${currentAccountId}/close`;
    const body = {
      ...closingPayload,
      openingLevel,
      stopLevel: null,
    };
    const response = await wtpPost(urlSuffix, this.session, body);
    return await response.json();
  }

  private getCommonPayload(
    currentAccountId: string,
    instrument: Instrument,
    size: number,
    priceLevel: number | undefined,
    dealReference: string,
    bid: number,
    offer: number,
  ) {
    return {
      accountId: currentAccountId,
      ask: offer,
      bid,
      dealCurrencyCode: instrument.ticketDefaultCurrency.name,
      dealReference,
      direction: "BUY",
      editType: null,
      instrumentId: instrument.igInstrumentId,
      knockoutPremium: null,
      size,
      priceLevel,
    };
  }
}
