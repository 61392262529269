import React, { useRef } from "react";

import { useTranslation } from "react-i18next";

import { type Prices } from "../../../types/prices";
import { type Ticket } from "../../../types/ticket";
import { type TransactionState } from "../../../types/transaction-status";
import { useETPTheme } from "../../../hooks/use-etp-theme";
import { Direction } from "../../../types/directions";
import { useClickOutside } from "../../../hooks/use-click-outside";
import CloseIcon from "../../icons/CloseIcon";
import Consideration from "../../infoSummary/Consideration";
import OrderButton from "../OrderButton";
import { type ETPtheme } from "../../../theme/etp-theme";
import { PreviewTicketStyles } from "./PreviewTicketStyles";
import EstimatedCharges from "./EstimatedCharges";
import ProfitAndLoss from "../../infoSummary/ProfitAndLoss";
import { OrderType } from "../../../types/ig-trading-order-ticket";
import { getStopLimitLevel } from "../../../utils/profit-and-loss-calculator";

interface PreviewTicketProps {
  ticket: Ticket;
  positionOpenLevel: number | undefined;
  handlePlaceDeal: (t: Ticket, prices: Prices) => void;
  transactionState: TransactionState;
  closePreviewTicket: () => void;
  setUserPlatformPreference?: (
    preferenceToChange: string,
    value: boolean,
  ) => void;
}

const getTicketSizeForDisplay = (ticket: Ticket) => {
  const directionSymbol = ticket.direction === Direction.BUY ? "+" : "-";
  const ticketLevel =
    ticket.orderType === OrderType.LIMIT ? ` @ ${ticket.triggerLevel}` : "";
  return directionSymbol + ticket.size + ticketLevel;
};

const PreviewTicket = ({
  ticket,
  positionOpenLevel,
  handlePlaceDeal,
  transactionState,
  closePreviewTicket,
  setUserPlatformPreference,
}: PreviewTicketProps) => {
  const { t } = useTranslation();
  const ETPtheme: ETPtheme = useETPTheme();

  const previewTicketRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(previewTicketRef, closePreviewTicket);
  return (
    <PreviewTicketStyles
      ref={previewTicketRef}
      data-testid="preview-ticket"
      $ETPtheme={ETPtheme}
      $direction={ticket.direction}
    >
      <CloseIcon onClose={closePreviewTicket} />
      <section className="ticket__header">
        <h3 id="etp-quick-deal-preview-ticket-title">
          {t("preview-ticket.title")}
        </h3>
        <p className="header__direction">
          {t(`preview-ticket.direction.${ticket.direction}`)}
        </p>
        <p className="header__instrument-name">
          {ticket.instrument.instrumentName.toUpperCase()}
        </p>
      </section>
      <section className="ticket__section">
        <dl>
          <dt id="etp-quick-deal-ticket-order-type">
            {t("preview-ticket.order-type.title")}
          </dt>
          <dd aria-labelledby="etp-quick-deal-ticket-order-type">
            {t(
              `preview-ticket.order-type.value.${ticket.orderType.toString()}`,
            )}
          </dd>
          {ticket.orderType === OrderType.STOP_MARKET && (
            <>
              <dt id="etp-quick-deal-ticket-stop-price">
                {t("preview-ticket.stop-level")}
              </dt>
              <dd aria-labelledby="etp-quick-deal-ticket-stop-price">
                {ticket.stopPrice}
              </dd>
            </>
          )}
          <dt id="etp-quick-deal-ticket-expiry">
            {t("preview-ticket.expiry.title")}
          </dt>
          <dd aria-labelledby="etp-quick-deal-ticket-expiry">
            {t(`preview-ticket.expiry.value.${ticket.timeInForce}`)}
          </dd>
          <dt id="etp-quick-deal-ticket-size">
            {t("preview-ticket.size.title")}
          </dt>
          <dd aria-labelledby="etp-quick-deal-ticket-size">
            {getTicketSizeForDisplay(ticket)}
          </dd>
        </dl>
      </section>
      <section className="ticket__section">
        <Consideration ticket={ticket} isPreviewing={true} />

        <EstimatedCharges ticket={ticket} />
      </section>
      {ticket.direction === Direction.SELL && (
        <section className="ticket__section">
          <ProfitAndLoss
            orderType={ticket.orderType}
            stopLimitLevel={getStopLimitLevel(ticket.orderType, ticket)}
            openLevel={positionOpenLevel}
            ticketSize={ticket.size}
            ticketDefaultCurrency={ticket.instrument.ticketDefaultCurrency.name}
            baseExchangeRate={
              ticket.instrument.ticketDefaultCurrency.baseExchangeRate
            }
          />
        </section>
      )}
      {setUserPlatformPreference !== undefined && (
        <section className="ticket__section--checkbox">
          <input
            type="checkbox"
            id="etp-quick-deal-do-not-show-preview-order-checkbox"
            defaultChecked={false}
            onChange={(event) => {
              setUserPlatformPreference(
                "showOrderPreview",
                !event.target.checked,
              );
            }}
          ></input>
          <label htmlFor="etp-quick-deal-do-not-show-preview-order-checkbox">
            {t("preview-ticket.do-not-show-again")}
          </label>
        </section>
      )}
      <OrderButton
        placeDeal={(prices: Prices) => {
          handlePlaceDeal(ticket, prices);
        }}
        transactionState={transactionState}
      />
      <footer>
        <p>{t("preview-ticket.bottom-info")}</p>
      </footer>
    </PreviewTicketStyles>
  );
};

export default PreviewTicket;
