import styled from "styled-components";
import { type ETPtheme } from "../../theme/etp-theme";

export const InstrumentOptionTypeStyles = styled.strong<{
  $isOptionTypeCall: boolean;
  $ETPtheme: ETPtheme;
}>`
  color: ${({ $isOptionTypeCall, $ETPtheme }) =>
    $isOptionTypeCall
      ? $ETPtheme.instrumentTitle.text.direction.call.name
      : $ETPtheme.instrumentTitle.text.direction.put.name};
  border-radius: 2px;
  padding: 3px 4px 2px;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 4px;
`;
