import { type Ticket } from "../types/ticket";
import { type Instrument } from "../types/instrument";
import { type Direction } from "../types/directions";
import {
  type OrderType,
  type TimeInForce,
} from "../types/ig-trading-order-ticket";

const TOLERANCE: number = 0.07;

export const createTicketModel = (
  direction: Direction,
  instrument: Instrument,
  size: number = 500,
  dealReference: string,
  timeInForce: TimeInForce,
  orderType: OrderType,
): Ticket => {
  return {
    direction,
    size,
    instrument,
    validationErrors: [],
    priceTolerance: TOLERANCE,
    dealReference,
    timeInForce,
    orderType,
  };
};
