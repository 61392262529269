import styled, { css } from "styled-components";
import { GlobalElementsStyle } from "../../theme/global-elements-style";
import { type ETPtheme } from "../../theme/etp-theme";

export const WarningMessageStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
  $topOffset: number;
  isDialogueBubble: boolean;
}>`
  width: ${({ isDialogueBubble }) => (isDialogueBubble ? "224px" : "100%")};
  font-size: ${({ isDialogueBubble }) => (isDialogueBubble ? 13 : 11)}px;
  position: relative;
  top: ${({ $topOffset }) => $topOffset}px;

  ${({ isDialogueBubble, $ETPtheme }) =>
    isDialogueBubble &&
    css`
      &::before {
        content: "";
        width: 12px;
        height: 12px;
        display: block;
        position: relative;
        top: 7px;
        left: calc(50% - 6px);
        transform: rotate(45deg);
        border-left: ${$ETPtheme.message.border.warning};
        border-top: ${$ETPtheme.message.border.warning};
        background: ${$ETPtheme.message.background.warning};
      }
    `};

  div[role="alert"] {
    padding: ${({ isDialogueBubble }) =>
      isDialogueBubble ? "8px" : "3px 8px 4px"};
    box-shadow: ${({ isDialogueBubble }) => !isDialogueBubble && "none"};
    border-radius: 2px;
    ${({ $ETPtheme }) => css`
      border: ${$ETPtheme.message.border.warning};
      color: ${$ETPtheme.message.text.body.warning};
      background: ${$ETPtheme.message.background.warning};
    `}

    line-height: ${({ isDialogueBubble }) => !isDialogueBubble && 1.6};

    div {
      margin: 0;
    }

    div:first-child,
    button {
      display: none;
    }
  }
`;
