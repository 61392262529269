import TetherComponent from "react-tether";
import WarningMessage from "./messages/WarningMessage";
import { type ReactNode } from "react";

interface TetheredWarningMessageProps {
  children: ReactNode;
  testId: string;
  warningMessage: string | null;
  showWarningMessage: boolean;
}

const TetheredWarningMessage = ({
  children,
  testId,
  warningMessage,
  showWarningMessage,
}: TetheredWarningMessageProps) => {
  return (
    <TetherComponent
      attachment="top center"
      targetAttachment="bottom center"
      className="etp-quick-deal_react-tether"
      offset="4px 0"
      renderElement={(ref) => (
        <div ref={ref}>
          {showWarningMessage && warningMessage !== null && (
            <WarningMessage
              testId={testId}
              message={warningMessage}
            ></WarningMessage>
          )}
        </div>
      )}
      renderTarget={(ref) => (
        <div
          ref={ref}
          style={{
            width: "100%",
          }}
        >
          {children}
        </div>
      )}
    />
  );
};

export default TetheredWarningMessage;
