import { type Data, useLsSubscription } from "@ig-wpx/lightstreamer";
import { useSession } from "./use-session";
import { useMemo } from "react";
import MemoSubscriptionIdPrice from "./memo-subscription-id-price";
import { MarketStatus, type RawPricingData } from "../types/pricing-data";
import { useInstrumentData } from "./use-instrument-data";

const TURBO_L2_SCHEMA = [
  "BID_SIZE1",
  "ASK_SIZE1",
  "ASK1",
  "ASK2",
  "ASK3",
  "ASK4",
  "ASK5",
  "ASK_SIZE2",
  "ASK_SIZE3",
  "ASK_SIZE4",
  "ASK_SIZE5",
  "BID1",
  "BID2",
  "BID3",
  "BID4",
  "BID5",
  "BID_SIZE2",
  "BID_SIZE3",
  "BID_SIZE4",
  "BID_SIZE5",
];
const FINANCE_PRICES_SCHEMA = ["BID", "OFFER", "MARKET_STATUS"];
const UNDERLYING_PRICES_SCHEMA = ["FINANCIAL_MID"];

const convertToRawPricingData = (
  data: Data<string[]>,
): RawPricingData | undefined => {
  if (Object.keys(data).length === 0) {
    return undefined;
  }

  return {
    bid: data.BID,
    offer: data.OFFER,
    underlyingPrice: data.FINANCIAL_MID,
    bidSize: data.BID_SIZE1,
    offerSize: data.ASK_SIZE1,
    marketStatus: MarketStatus[data.MARKET_STATUS as keyof typeof MarketStatus],
    bidDepth: [
      { size: data.BID_SIZE1, price: data.BID1 },
      { size: data.BID_SIZE2, price: data.BID2 },
      { size: data.BID_SIZE3, price: data.BID3 },
      { size: data.BID_SIZE4, price: data.BID4 },
      { size: data.BID_SIZE5, price: data.BID5 },
    ],
    offerDepth: [
      { size: data.ASK_SIZE1, price: data.ASK1 },
      { size: data.ASK_SIZE2, price: data.ASK2 },
      { size: data.ASK_SIZE3, price: data.ASK3 },
      { size: data.ASK_SIZE4, price: data.ASK4 },
      { size: data.ASK_SIZE5, price: data.ASK5 },
    ],
  };
};

export const useTurboPricingLightstreamer = (
  instrumentEpic: string,
): RawPricingData | undefined => {
  const currentAccountId = useSession().sessionData.currentAccountId;

  const { epic: underlyingEpic } = useInstrumentData().instrumentDataUnderlying;

  // Finance prices for prices
  const subscriptionIdPrice = MemoSubscriptionIdPrice(
    currentAccountId,
    instrumentEpic,
  );

  const { data: dataPrice } = useLsSubscription(
    subscriptionIdPrice,
    FINANCE_PRICES_SCHEMA,
    "MERGE",
  );

  // L2 for volumes
  const subscriptionIdVolume = useMemo(() => {
    return [`MARKET_V4:${currentAccountId}:${instrumentEpic}.L2`];
  }, [currentAccountId, instrumentEpic]);

  const { data: dataVolume } = useLsSubscription(
    subscriptionIdVolume,
    TURBO_L2_SCHEMA,
  );

  const subscriptionIdUnderlying = useMemo(() => {
    return [`V2-F-FINANCIAL_MID|${underlyingEpic}`];
  }, [underlyingEpic]);

  const { data: dataUnderlying } = useLsSubscription(
    subscriptionIdUnderlying,
    UNDERLYING_PRICES_SCHEMA,
    "MERGE",
  );

  return convertToRawPricingData({
    ...dataPrice,
    ...dataVolume,
    ...dataUnderlying,
  });
};
