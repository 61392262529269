import { useETPTheme } from "../../hooks/use-etp-theme";
import React from "react";
import { PriceCalculatorToolTipContentStyles } from "./PriceCalculatorToolTipContentStyles";
import { Trans } from "react-i18next";
import { t } from "i18next";

const PriceCalculatorToolTipContent = () => {
  const etpTheme = useETPTheme();
  return (
    <PriceCalculatorToolTipContentStyles $ETPtheme={etpTheme}>
      <p className="etp-quick-deal_price-calculator-tooltip__info">
        <Trans i18nKey={"price-calculator.tooltip-content.main"}>
          text
          <strong>strong</strong>
        </Trans>
      </p>
      <p className="etp-quick-deal_price-calculator-tooltip__disclaimer">
        {t("price-calculator.tooltip-content.disclaimer")}
      </p>
    </PriceCalculatorToolTipContentStyles>
  );
};

export default PriceCalculatorToolTipContent;
