import { useEffect, useRef, useState } from "react";
import { type DefaultIconProps } from "../../types/default-icon-props";
import { PriceTickStyles } from "./PriceTickStyles";

interface PriceTickProps extends DefaultIconProps {
  price: number | null;
}

const PriceTick = ({ price }: PriceTickProps) => {
  const [priceTickDirection, setPriceTickDirection] = useState<
    "up" | "down" | "unset"
  >("unset");

  const previousPrice = usePreviousPrice(price);

  useEffect(() => {
    if (price === null || previousPrice === null) {
      setPriceTickDirection("unset");
    } else {
      if (price > previousPrice) {
        setPriceTickDirection("up");
      }
      if (price < previousPrice) {
        setPriceTickDirection("down");
      }
    }
  }, [previousPrice, price]);

  return (
    <PriceTickStyles
      key={price} // allow re-rendering subsequent same price ticks
      data-testid="price-tick"
      className={`direction-button__price-tick-direction direction-button__price-tick-direction-${priceTickDirection}`}
    >
      <svg
        width="6"
        height="9.5"
        viewBox="0 0 13 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5.58746 5.44284L0.5 6.55623L6.48032 0L12.5 6.52871L7.37318 5.43432V21H5.58746V5.44284Z" />
      </svg>
    </PriceTickStyles>
  );
};

export default PriceTick;

export const usePreviousPrice = (price: number | null) => {
  const previousPriceRef = useRef<number | null>();

  useEffect(() => {
    if (price !== null) {
      previousPriceRef.current = price;
    }
  });

  return previousPriceRef.current ?? price;
};
