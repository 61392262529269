import styled from "styled-components";
import { GlobalElementsStyle } from "../../theme/global-elements-style";
import { type ETPtheme } from "../../theme/etp-theme";

export const OrderTabContentStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  .etp-quick-deal_calculator-heading {
    position: relative;
    margin: 5px 0 0;
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    span {
      flex: 2;
    }
    button {
      flex: 1;

      p {
        color: ${({ $ETPtheme }) =>
          $ETPtheme.turboCalculator.text.calculatorHeading};
        font-weight: 400;
        font-size: 11px;
        line-height: 11px;
        text-align: left;
        text-decoration: underline dotted;
      }
    }

    &:has(p:hover)
      + .stop-limit-rows
      .etp-quick-deal_stop-limit-rows__calculator {
      margin: -2px;
      padding: 2px;
      background-color: ${({ $ETPtheme }) =>
        $ETPtheme.stopLimitRow.row.background.highlight};

      input#etp-quick-deal_calculator-input {
        border: 1px solid
          ${({ $ETPtheme }) => $ETPtheme.turboCalculator.border.active.hover};
        background-color: ${({ $ETPtheme }) =>
          $ETPtheme.turboCalculator.background.inputField};
      }
    }
  }

  .stop-limit-rows {
    margin-top: 1px;
  }
`;
