import { createContext, type ReactNode, useContext } from "react";
import { type FeatureFlag } from "../types/feature-flag";
import { type OptimizelyFeatureFlags } from "../types/optimizely-feature-flags";

const FeatureFlagContext = createContext<IsFeatureFlagEnabled | null>(null);

export type IsFeatureFlagEnabled = (
  featureFlag: FeatureFlag | keyof OptimizelyFeatureFlags,
) => boolean;

export const FeatureFlagProvider = ({
  children,
  isFeatureFlagEnabled,
}: {
  children: ReactNode;
  isFeatureFlagEnabled: IsFeatureFlagEnabled;
}) => {
  return (
    <FeatureFlagContext.Provider value={isFeatureFlagEnabled}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useFeatureFlag = () => {
  const featureFlagContext = useContext(FeatureFlagContext);
  if (featureFlagContext === null) {
    throw new Error(
      "Misconfiguration: You need to use FeatureFlagProvider component",
    );
  }
  return featureFlagContext;
};
