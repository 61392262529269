import { type OrderType, type TimeInForce } from "./ig-trading-order-ticket";

export enum TransactionState {
  NOT_STARTED = "NOT_STARTED",
  STARTED = "STARTED",
  PENDING = "PENDING",
  CANCELLED = "CANCELED", // not a typo
  CONFIRMED = "CONFIRMED",
  REJECTED = "REJECTED",

  /**
   * technical status. Used when client attempted to check the order execution status,
   * but it did not get a response after max number of retries.
   * no details are provided.
   */
  UNVERIFIED = "UNVERIFIED",

  /**
   * technical status. Used when a call to submit order endpoint has failed.
   * provided details are coming from the error: err.statusText
   */
  FAILED = "FAILED",

  /**
   * technical status. Can be used programmatically on demand (we did not find the usage of it).
   * no details are provided.
   */
  ABORTED = "ABORTED",
}
export enum ReportType {
  FULL_FILL = "FULL_FILL",
  PARTIAL_FILL = "PARTIAL_FILL",
  ACKNOWLEDGE = "ACKNOWLEDGE",
  FAILED = "FAILED",
  REJECTION = "REJECTION",
  CANCELLED = "CANCELED", // not a typo
}

export const TECHNICAL_UNSUCCESSFUL_COMPLETED_STATES = [
  TransactionState.ABORTED,
  TransactionState.FAILED,
  TransactionState.UNVERIFIED,
];

export const UNSUCCESSFUL_COMPLETED_STATES =
  TECHNICAL_UNSUCCESSFUL_COMPLETED_STATES.concat(TransactionState.REJECTED);

export const COMPLETED_STATES = UNSUCCESSFUL_COMPLETED_STATES.concat(
  TransactionState.CONFIRMED,
  TransactionState.CANCELLED,
);

export const IN_PROGRESS_STATES = [
  TransactionState.STARTED,
  TransactionState.PENDING,
];

export interface RejectionDetails {
  content: {
    dealReference: string;
    reportType: ReportType;
    reportData: {
      rejectionMessage: {
        title: string;
        body: string;
        rejectionReason: string | undefined;
      };
      status?: {
        value: string;
      };
    };
  };
}

export interface ConfirmDetails {
  content: {
    dealReference: string;
    quantity: number;
    orderLevel: number;
    orderStopLevel?: number;
    orderType: OrderType;
    reportType: ReportType;
    timeInForce: TimeInForce;
    reportData: {
      direction: {
        value: string;
      };
      status: {
        value: string;
      };
      quantity: {
        value: string;
      };
    };
  };
}

export type CancelledDetails = ConfirmDetails;

export interface FailedDetails {
  content: { reportType: ReportType };
}

export type TransactionDetails =
  | RejectionDetails
  | ConfirmDetails
  | FailedDetails;

export interface TransactionStatusInitial {
  state: TransactionState;
  details: null;
}

export interface TransactionStatus {
  state: TransactionState;
  details: TransactionDetails;
}
