export const underlyingExposureCalculator = (
  ticketSize: number,
  multiplier: number,
  scalingFactor: number,
  isShareUnderlying: boolean,
) => {
  if (isShareUnderlying) {
    return ticketSize * multiplier;
  }
  if (scalingFactor === 0) {
    return NaN;
  }
  return ticketSize * multiplier * (1 / scalingFactor);
};
