import { type PositionAdapter } from "../types/position-adapter";
import { type Position } from "../types/position";
import { type ExistingPositionProvider } from "../types/existing-position-provider";

export class PositionMonitoringService implements ExistingPositionProvider {
  positionAdapter: PositionAdapter;
  instrumentEpic: string;
  existingPosition: Position | null;
  started: boolean = false;

  constructor(positionAdapter: PositionAdapter, instrumentEpic: string) {
    this.positionAdapter = positionAdapter;
    this.instrumentEpic = instrumentEpic;
    this.existingPosition = null;
  }

  startMonitoringPosition = async (
    callback: PositionChangeCallback,
  ): Promise<Position | null> => {
    this.ensureNotStarted();
    const existingPosition = await this.positionAdapter
      .getExistingPosition(this.instrumentEpic)
      .then((position) => {
        if (position !== null) {
          this.existingPosition = position;
          callback.onPositionExistingOrOpen();
        } else {
          callback.onPositionNotExistingOrClosed();
        }
        this.positionAdapter.startListeningForUpdates(
          this.existingPosition,
          (newPosition: Position) => {
            this.handleOnAddedPosition(newPosition, callback);
          },
          (closedPositionDealId: string) => {
            this.handleOnRemovedPosition(closedPositionDealId, callback);
          },
          () => {
            this.handleOnChangedPosition(callback);
          },
        );
        return position;
      });
    this.started = true;
    return existingPosition;
  };

  private readonly ensureNotStarted = () => {
    if (this.started) {
      throw new Error("PositionMonitoringService already started");
    }
  };

  stopMonitoringPosition = () => {
    this.positionAdapter.stopListeningForUpdates();
  };

  private readonly handleOnAddedPosition = (
    newPosition: Position,
    callback: PositionChangeCallback,
  ) => {
    if (newPosition.epic === this.instrumentEpic) {
      this.existingPosition = newPosition;
      this.positionAdapter.subscribeToLiveSizeUpdates(
        this.existingPosition,
        callback.onPositionUpdate,
      );
      callback.onPositionExistingOrOpen();
    }
  };

  private readonly handleOnRemovedPosition = (
    closedPositionDealId: string,
    callback: PositionChangeCallback,
  ) => {
    if (closedPositionDealId === this.existingPosition?.dealId) {
      this.positionAdapter.removeSizeUpdateSubscription(this.existingPosition);
      this.existingPosition = null;
      callback.onPositionNotExistingOrClosed();
    }
  };

  private readonly handleOnChangedPosition = (
    callback: PositionChangeCallback,
  ) => {
    callback.onPositionUpdate();
  };

  getExistingPosition(): Position | null {
    return this.existingPosition;
  }
}

export interface PositionChangeCallback {
  onPositionNotExistingOrClosed: () => void;
  onPositionExistingOrOpen: () => void;
  onPositionUpdate: () => void;
}
