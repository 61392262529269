export const SUPPORTED_LANGUAGES = [
  "de-DE",
  "en-GB",
  "es-ES",
  "fr-FR",
  "it-IT",
  "nl-NL",
  "no-NO",
  "sv-SE",
] as const;
