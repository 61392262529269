import { Direction } from "../types/directions";
import { type Ticket } from "../types/ticket";
import { type MutableRefObject, useEffect, useRef, useState } from "react";
import { useAdapter } from "./use-adapter";
import { CallIsThrottledError } from "../services/throttler";
import { type ClosingCostsAndChargesResponseFormatted } from "../types/closing-costs-and-charges-response";
import { type OpeningCostsAndChargesResponseFormatted } from "../types/opening-costs-and-charges-response";
import { resolvePriceLevelForPayload } from "../utils/resolve-costs-and-charges-price";
import { OrderType } from "../types/ig-trading-order-ticket";
import { type Session } from "../types/session";
import { useSession } from "./use-session";

export enum TicketStatus {
  OPENING = "opening",
  CLOSING = "closing",
}

function arePricesValid(bid: number | null, offer: number | null) {
  return bid !== null && offer !== null;
}

function logIfNecessary(error: any) {
  if (!(error instanceof CallIsThrottledError)) {
    console.error(error);
  }
}

interface PricesForCostsAndCharges {
  bid: number | null;
  offer: number | null;
}

const useCostsAndCharges = (
  ticket: Ticket,
  positionOpeningLevel: number | undefined = 0,
  bid: number | null,
  offer: number | null,
) => {
  const session: Session = useSession();
  const latestCostsAndChargesCallIsValid = useRef(false);
  const pricesForCostsAndCharges: MutableRefObject<PricesForCostsAndCharges> =
    useRef({ bid: null, offer: null });
  pricesForCostsAndCharges.current.bid = bid;
  pricesForCostsAndCharges.current.offer = offer;
  const [costsAndChargesData, setCostsAndChargesData] = useState<
    | OpeningCostsAndChargesResponseFormatted
    | ClosingCostsAndChargesResponseFormatted
    | undefined
  >(undefined);
  const directionInfo = getDirectionInformation(ticket);
  const pricesAreValid = arePricesValid(
    pricesForCostsAndCharges.current.bid,
    pricesForCostsAndCharges.current.offer,
  );
  const { costsAndChargesAdapter } = useAdapter();

  useEffect(() => {
    if (session.sessionData.igCompany === "igch") return;

    const invalidateLatestCostsAndChargesCall = () => {
      latestCostsAndChargesCallIsValid.current = false;
    };
    setCostsAndChargesData(undefined);

    // Checks apply for all order types and ticket states
    if (
      pricesForCostsAndCharges.current.bid === null ||
      pricesForCostsAndCharges.current.offer === null ||
      ticket.size === 0
    ) {
      return invalidateLatestCostsAndChargesCall;
    }
    // Checks for limit orders
    if (ticket.orderType === OrderType.LIMIT) {
      if (ticket.triggerLevel === undefined || ticket.triggerLevel === 0) {
        return invalidateLatestCostsAndChargesCall;
      }
    }
    // Checks for stop orders
    if (ticket.orderType === OrderType.STOP_MARKET) {
      if (ticket.stopPrice === undefined || ticket.stopPrice === 0) {
        return invalidateLatestCostsAndChargesCall;
      }
    }

    latestCostsAndChargesCallIsValid.current = true;
    if (directionInfo === TicketStatus.OPENING) {
      costsAndChargesAdapter
        .getOpeningCostsAndCharges(
          ticket.size,
          ticket.instrument,
          ticket.dealReference,
          pricesForCostsAndCharges.current.bid,
          pricesForCostsAndCharges.current.offer,
          resolvePriceLevelForPayload(
            ticket.orderType,
            ticket.triggerLevel,
            ticket.stopPrice,
            pricesForCostsAndCharges.current.offer,
          ),
        )
        .then((data) => {
          if (latestCostsAndChargesCallIsValid.current) {
            setCostsAndChargesData({ ...data, costsAndChargesType: "opening" });
          }
        })
        .catch(logIfNecessary);
    } else {
      costsAndChargesAdapter
        .getClosingCostsAndCharges(
          ticket.size,
          positionOpeningLevel,
          ticket.instrument,
          ticket.dealReference,
          pricesForCostsAndCharges.current.bid,
          pricesForCostsAndCharges.current.offer,
          resolvePriceLevelForPayload(
            ticket.orderType,
            ticket.triggerLevel,
            ticket.stopPrice,
            pricesForCostsAndCharges.current.bid,
          ),
        )
        .then((data) => {
          if (latestCostsAndChargesCallIsValid.current) {
            setCostsAndChargesData({
              ...data.close,
              currencyCodeISO: data.currencyCodeISO,
              costsAndChargesType: "closing",
            });
          }
        })
        .catch(logIfNecessary);
    }
    return invalidateLatestCostsAndChargesCall;
  }, [
    ticket.size,
    ticket.instrument,
    ticket.dealReference,
    ticket.orderType,
    ticket.stopPrice,
    ticket.triggerLevel,
    costsAndChargesAdapter,
    directionInfo,
    positionOpeningLevel,
    pricesAreValid,
    pricesForCostsAndCharges,
    session.sessionData.igCompany,
  ]);

  return costsAndChargesData;
};
function getDirectionInformation(ticket: Ticket) {
  return ticket.direction === Direction.BUY
    ? TicketStatus.OPENING
    : TicketStatus.CLOSING;
}

export default useCostsAndCharges;
