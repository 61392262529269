import { createContext, type ReactNode, useContext } from "react";
import { type Session } from "../types/session";

const SessionContext = createContext<Session | null>(null);

export const SessionProvider = ({
  children,
  session,
}: {
  children: ReactNode;
  session: Session;
}) => {
  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = (): Session => {
  const currentSessionContext = useContext(SessionContext);
  if (currentSessionContext === null) {
    throw new Error("Session has not been set");
  }
  return currentSessionContext;
};
