import { GlobalElementsStyle } from "../../../theme/global-elements-style";
import styled from "styled-components";
import { type ETPtheme } from "../../../theme/etp-theme";

export const NumericInputStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
  $hasError: boolean;
}>`
  flex: 1;
  line-height: normal;

  .etp-quick-deal_numeric-input {
    width: 100%;
    height: 24px;
    outline: none;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    letter-spacing: -0.7px;
    border-radius: 2px;
    -moz-appearance: textfield;
    border: ${({ $ETPtheme, $hasError }) =>
      $hasError
        ? $ETPtheme.genericInput.inputField.border.warning
        : $ETPtheme.genericInput.inputField.border.focusDisabled};
    background: ${({ $ETPtheme }) =>
      $ETPtheme.genericInput.inputField.background};
    color: ${({ $ETPtheme }) => $ETPtheme.genericInput.inputField.text};

    &__counter-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      position: absolute;
      top: 1px;
      right: 1px;
      height: 22px;
      width: 15px;
      border-top-right-radius: 1px;
      border-bottom-right-radius: 1px;
      background-color: ${({ $ETPtheme }) =>
        $ETPtheme.genericInput.incrementerButtons.background.default};
    }
  }

  .etp-quick-deal_numeric-input:focus:not([disabled]),
  &:focus-within .etp-quick-deal_numeric-input:not([disabled]),
  &:hover .etp-quick-deal_numeric-input:focus:not([disabled]) {
    border: ${({ $ETPtheme }) =>
      $ETPtheme.genericInput.inputField.border.focusEnabled};
  }
`;
