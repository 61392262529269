import { type Direction } from "./directions";

export type PartialTicket = Partial<IgTradingOrderTicket>;

export interface Transaction {
  start: () => void;
  on: (eventName: string, callback: () => void, context?: any) => void;
}
export enum TimeInForce {
  GOOD_TILL_CANCELLED = "GOOD_TILL_CANCELLED",
  GOOD_TILL_CANCEL = "GOOD_TILL_CANCEL",
  IMMEDIATE_OR_CANCEL = "IMMEDIATE_OR_CANCEL",
}
export enum OrderType {
  // All market orders are converted to Limit orders by ig-trading
  MARKET = "MARKET",
  LIMIT = "LIMIT",
  STOP_MARKET = "STOP_MARKET",
}
export interface IgTradingOrderTicket {
  direction: Direction.BUY | Direction.SELL;
  dealReference: string;
  igInstrumentId: string;
  size: number;
  timeInForce: string;
  orderType: OrderType;
  triggerLevel?: number;
  tolerance: number;
  stopPrice?: number;
  submit: () => Transaction;
  setProperties: (ticketParams: PartialTicket) => void;
}
