import {
  type Account,
  type Environment,
  type SessionData,
  type Session,
} from "../../types/session";

import {
  User,
  Environment as IgTradingEnvironment,
  Session as IgTradingSession,
} from "ig-trading";

export const createIgTradingSession = (WTPSession: Session) => {
  const sessionData: SessionData = WTPSession.sessionData;
  const environment: Environment = WTPSession.environment;
  return new IgTradingSession(
    new IgTradingEnvironment(
      environment.origin, // origin
      "ig", // applicationType
      "WTP", // platform
      environment.version, // version
      undefined, // deviceType
      undefined, // view
      "nwtpdeal", // path
      "@ig-etp/quick-deal", // component
      process.env.APPLICATION_VERSION, // component version
    ),
    new User(
      sessionData.currentAccountId,
      WTPSession.xst,
      WTPSession.cst,
      sessionData.clientLocale,
      sessionData.webSiteId,
      sessionData.siteType,
      findCurrentAccountType(
        sessionData.currentAccountId,
        sessionData.accounts,
      ),
      sessionData.igCompany,
    ),
  );
};

const findCurrentAccountType = (
  currentAccountId: string,
  accounts: Account[],
): string | undefined => {
  return accounts.find((account) => account.accountId === currentAccountId)
    ?.productCode;
};
