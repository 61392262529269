import type CostsAndChargesAdapter from "../types/costs-and-charges-adapter";
import { type Instrument } from "../types/instrument";
import { type ClosingCostsAndChargesResponse } from "../types/closing-costs-and-charges-response";
import { type OpeningCostsAndChargesResponse } from "../types/opening-costs-and-charges-response";
import { Throttler } from "./throttler";

export class ThrottledCostsAndChargesAdapter implements CostsAndChargesAdapter {
  private readonly costsAndChargesAdapter: CostsAndChargesAdapter;
  private readonly throttler: Throttler;

  constructor(
    costsAndChargesAdapter: CostsAndChargesAdapter,
    delayInMillis: number,
  ) {
    this.costsAndChargesAdapter = costsAndChargesAdapter;
    this.throttler = new Throttler(delayInMillis);
  }

  async getClosingCostsAndCharges(
    size: number,
    openingLevel: number,
    instrument: Instrument,
    dealReference: string,
    bid: number,
    offer: number,
    priceLevel: number | undefined,
  ): Promise<ClosingCostsAndChargesResponse> {
    return await this.throttler.throttle(
      async () =>
        await this.costsAndChargesAdapter.getClosingCostsAndCharges(
          size,
          openingLevel,
          instrument,
          dealReference,
          bid,
          offer,
          priceLevel,
        ),
    );
  }

  async getOpeningCostsAndCharges(
    size: number,
    instrument: Instrument,
    dealReference: string,
    bid: number,
    offer: number,
    priceLevel: number | undefined,
  ): Promise<OpeningCostsAndChargesResponse> {
    return await this.throttler.throttle(
      async () =>
        await this.costsAndChargesAdapter.getOpeningCostsAndCharges(
          size,
          instrument,
          dealReference,
          bid,
          offer,
          priceLevel,
        ),
    );
  }
}
