import { GlobalElementsStyle } from "../../theme/global-elements-style";
import { type ETPtheme } from "../../theme/etp-theme";
import styled from "styled-components";

export const FeedbackCallToActionFormStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  button.etp-quick-deal__feedback-form__large-button {
    margin-top: 10px;
    border-color: ${({ $ETPtheme }) =>
      $ETPtheme.feedbackForm.feedbackInputForm.closeButton.border};
    color: ${({ $ETPtheme }) =>
      $ETPtheme.feedbackForm.feedbackInputForm.closeButton.text};
    background-color: ${({ $ETPtheme }) =>
      $ETPtheme.feedbackForm.feedbackInputForm.closeButton.background};
    box-shadow: ${({ $ETPtheme }) =>
      $ETPtheme.feedbackForm.feedbackInputForm.submitButton.boxShadow};

    &:hover {
      background-color: ${({ $ETPtheme }) =>
        $ETPtheme.feedbackForm.feedbackInputForm.closeButton.background};
    }
  }
`;
