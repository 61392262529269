import styled from "styled-components";
import { GlobalElementsStyle } from "../../../theme/global-elements-style";
import { type ETPtheme } from "../../../theme/etp-theme";

export const EstimatedChargesStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  dt#etp-quick-deal-preview-total-cost {
    font-weight: 600;
    color: ${({ $ETPtheme }) => $ETPtheme.previewTicket.text.strong};
  }

  dd {
    font-weight: 600;
    color: ${({ $ETPtheme }) => $ETPtheme.previewTicket.text.strong};

    &:last-of-type {
      grid-column: 2/3;
    }
  }

  span.preview-ticket-extra-charges {
    grid-column: 1/3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
