import { OrderType } from "../types/ig-trading-order-ticket";

export const resolvePriceLevelForPayload = (
  orderType: OrderType,
  triggerLevel: number | undefined,
  stopPrice: number | undefined,
  latestPrice: number,
) => {
  if (orderType === OrderType.LIMIT) {
    return triggerLevel;
  } else if (orderType === OrderType.STOP_MARKET) {
    return stopPrice;
  }
  return latestPrice;
};
