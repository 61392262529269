import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import de from "./de-DE.json";
import en from "./en-GB.json";
import es from "./es-ES.json";
import fr from "./fr-FR.json";
import it from "./it-IT.json";
import nl from "./nl-NL.json";
import no from "./no-NO.json";
import sv from "./sv-SE.json";
import { SUPPORTED_LANGUAGES } from "./supported-languages";

export type RequiredKeys = typeof en;
export type AvailableResources = {
  [key in (typeof SUPPORTED_LANGUAGES)[number]]: RequiredKeys;
};

const resources: AvailableResources = {
  "de-DE": de,
  "en-GB": en,
  "es-ES": es,
  "fr-FR": fr,
  "it-IT": it,
  "nl-NL": nl,
  "no-NO": no,
  "sv-SE": sv,
};

void i18n.use(initReactI18next).init({
  resources,
  lng: "en-GB",
  fallbackLng: "en-GB",
  supportedLngs: SUPPORTED_LANGUAGES,

  debug: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
