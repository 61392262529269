import styled from "styled-components";
import { type ETPtheme } from "../../theme/etp-theme";
import { DirectionButtonStyle } from "../directionButtons/DirectionButtonStyles";

export const MarketDepthDirectionButtonStyle = styled(DirectionButtonStyle)<{
  $direction: string;
  $ETPtheme: ETPtheme;
}>`
  width: 100%;
  height: 24px;
  padding: 0 4px;
  align-items: center;
  display: flex;
  justify-content: space-between;

  p {
    text-transform: capitalize;
    font-size: 9px;
    font-weight: 500;
  }

  strong {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    line-height: 11px;
  }
`;
