import { type Subscribe, type Unsubscribe } from "@ig-wpx/lightstreamer";

type Items = string[];
type Fields = Readonly<string[]>;
type Mode = "COMMAND" | "DISTINCT" | "MERGE" | "RAW";
type OnItemUpdate = (itemUpdate: {
  getValue: (key: any) => string;
  forEachChangedField: (callback: any) => void;
}) => void;

export interface LightstreamerService {
  subscribe: (
    items: Items,
    fields: Fields,
    mode: Mode,
    onItemUpdate: OnItemUpdate,
  ) => { unsubscribe: Unsubscribe };
  getStatus: () => string;
}

export class LightstreamerSubscriberService implements LightstreamerService {
  private readonly lsSubscribe: Subscribe<readonly string[]> | undefined;
  private readonly lsUnsubscribe: Unsubscribe | undefined;

  constructor(lightstreamerProvider: {
    subscribe?: Subscribe<readonly string[]> | undefined;
    unsubscribe?: Unsubscribe | undefined;
  }) {
    const { subscribe, unsubscribe } = lightstreamerProvider;
    if (subscribe === undefined || unsubscribe === undefined) {
      console.warn(
        "LightstreamerSubscriberService requires a lightstreamerProvider",
      );
    } else {
      this.lsSubscribe = subscribe;
      this.lsUnsubscribe = unsubscribe;
    }
  }

  subscribe(
    items: Items,
    fields: Fields,
    mode: Mode,
    onItemUpdate: OnItemUpdate,
  ) {
    const subscription = this.lsSubscribe?.(mode, items, fields, {
      onItemUpdate,
    });

    return {
      unsubscribe: () => {
        subscription !== undefined && this.lsUnsubscribe?.(subscription);
      },
    };
  }

  getStatus() {
    return "CONNECTED:WS-STREAMING";
  }
}
