import { type OrderAdapter } from "../../types/order-adapter";
import { type IgTradingOrderService } from "./types";
import { type Order } from "../../types/order";
import { type LightstreamerService } from "../../services/lightstreamer";
import { OrderGroupSubscription, OrderService } from "ig-trading";

export default class IgTradingOrderAdapter implements OrderAdapter {
  private readonly orderService: IgTradingOrderService;
  private readonly accountId: string;
  private readonly orderStreamingClient: any;
  private readonly orderSubscription;

  constructor(
    lightstreamerService: LightstreamerService,
    accountId: string,
    orderService: IgTradingOrderService,
  ) {
    this.orderService = orderService;
    this.accountId = accountId;
    this.orderStreamingClient =
      OrderService.getStreamingClient(lightstreamerService);
    this.orderSubscription = new OrderGroupSubscription(
      lightstreamerService,
      1,
    );
  }

  startListeningForUpdates = (
    existingOrders: Order[],
    onAdded: (order: Order) => void,
    onRemoved: (dealId: string) => void,
    onChanged: () => void,
  ) => {
    this.subscribeToLiveSizeUpdates(existingOrders, onChanged);
    this.orderStreamingClient.on("ADDED", onAdded);
    this.orderStreamingClient.on("REMOVED", onRemoved);
    this.orderStreamingClient.startListening(this.accountId);
  };

  subscribeToLiveSizeUpdates(
    orders: Order[],
    orderChangeCallback: () => void,
  ): void {
    orders.forEach((order) => {
      const originalCallback = order.boundOnStreamedUpdate;
      if (originalCallback !== undefined) {
        order.boundOnStreamedUpdate = (itemUpdate: any) => {
          originalCallback(itemUpdate);
          orderChangeCallback();
        };
      }
      this.orderSubscription.add(order);
    });
  }

  stopListeningForUpdates(): void {
    this.orderSubscription.destroy();
    this.orderStreamingClient.stopListening();
  }

  getExistingOrders = async (instrumentEpic: string): Promise<Order[]> => {
    return await this.orderService
      .getAll()
      .then(({ orders }: { orders: Order[] }) => {
        return orders.filter((order: Order) => order.epic === instrumentEpic);
      });
  };

  removeSizeUpdateSubscription = (order: Order) => {
    this.orderSubscription.remove(order);
  };
}
