import styled from "styled-components";
import { type ETPtheme } from "../../../theme/etp-theme";

export const CounterButtonStyles = styled.button<{ $ETPtheme: ETPtheme }>`
  &.etp-quick-deal_counter-button {
    position: relative;
    height: 9px;
    width: 11px;
    background: transparent;
    border: none;
    outline: none;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      background-color: ${({ $ETPtheme }) =>
        $ETPtheme.genericInput.incrementerButtons.background.hover};

      .etp-quick-deal__chevron-icon path {
        stroke: ${({ $ETPtheme }) =>
          $ETPtheme.genericInput.incrementerButtons.border.default};
      }
    }

    &:hover:not(:disabled) {
      .etp-quick-deal__chevron-icon path {
        stroke: ${({ $ETPtheme }) =>
          $ETPtheme.genericInput.incrementerButtons.border.hover};
      }
    }

    &:disabled {
      cursor: default;
      background-color: unset;

      .etp-quick-deal__chevron-icon path {
        stroke: ${({ $ETPtheme }) =>
          $ETPtheme.genericInput.incrementerButtons.border.disabled};
      }
    }

    .etp-quick-deal__chevron-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      path {
        stroke: ${({ $ETPtheme }) =>
          $ETPtheme.genericInput.incrementerButtons.border.default};
      }
    }
  }
`;
