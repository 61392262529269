import { useTranslation } from "react-i18next";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { type Direction } from "../../types/directions";
import { MarketDepthDirectionButtonStyle } from "./MarketDepthDirectionButtonStyles";

interface MarketDepthDirectionButtonProps {
  direction: Direction;
  isSelected: boolean;
  onButtonClick: (direction: Direction) => void;
  isDisabled: boolean;
}

const MarketDepthDirectionButton = ({
  direction,
  isSelected,
  onButtonClick,
  isDisabled,
}: MarketDepthDirectionButtonProps) => {
  const { t } = useTranslation();
  const ETPtheme = useETPTheme();

  return (
    <MarketDepthDirectionButtonStyle
      type="button"
      data-testid={`direction-button-${direction}`}
      className={isSelected ? "selected" : "unselected"}
      onClick={() => {
        onButtonClick(direction);
      }}
      disabled={isDisabled}
      $direction={direction}
      $ETPtheme={ETPtheme}
    >
      <strong>{t(`${direction}-button`)}</strong>
      <p>{t(`direction-volume`)}</p>
    </MarketDepthDirectionButtonStyle>
  );
};

export default MarketDepthDirectionButton;
