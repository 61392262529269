import {
  type Context,
  createContext,
  type ReactNode,
  useContext,
  useState,
} from "react";
import { type Session } from "../types/session";
import { type AdaptersFactory } from "../types/adapters-factory";
import { type AdaptersHolder } from "../types/adapters-holder";
import { LightstreamerSubscriberService } from "../services/lightstreamer";
import { useLightstreamer } from "@ig-wpx/lightstreamer";

const AdapterContext: Context<AdaptersHolder | null> =
  createContext<AdaptersHolder | null>(null);

export const AdapterProvider = ({
  children,
  session,
  factory,
}: {
  children: ReactNode;
  session: Session;
  factory: AdaptersFactory;
}) => {
  const lightstreamerService = useCreateLightstreamerService();

  const [adaptersHolder] = useState(() =>
    factory.createAdaptersHolder(session, lightstreamerService),
  );

  return (
    <AdapterContext.Provider value={adaptersHolder}>
      {children}
    </AdapterContext.Provider>
  );

  function useCreateLightstreamerService() {
    const lightstreamerSubscriber = useLightstreamer();
    return new LightstreamerSubscriberService(lightstreamerSubscriber);
  }
};

export const useAdapter = (): AdaptersHolder => {
  const currentAdapterContext = useContext(AdapterContext);
  if (currentAdapterContext === null) {
    throw new Error("Adapter has not been set");
  }
  return currentAdapterContext;
};
