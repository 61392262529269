//  * If the spread is over 10% of the buy price
//  * this is considered a "wide spread".

export default function hasWideSpread(
  buyPrice: number | null,
  sellPrice: number | null,
) {
  return (
    buyPrice === null ||
    sellPrice === null ||
    buyPrice - sellPrice > 0.1 * buyPrice
  );
}
