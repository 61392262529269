import styled from "styled-components";
import { GlobalElementsStyle } from "../../theme/global-elements-style";
import { type ETPtheme } from "../../theme/etp-theme";
import { GenericInputStyle } from "../generics/input/InputStyles";

export const StopLimitRowStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
  $isInactive: boolean;
  $isDisabled: boolean;
}>`
  display: flex;
  gap: 4px;
  cursor: ${({ $isDisabled }) => ($isDisabled ? "not-allowed" : "pointer")};
  margin: 0 -8px;
  padding: 6px 8px;

  &:hover {
    background-color: ${({ $ETPtheme, $isDisabled }) =>
      $isDisabled
        ? $ETPtheme.orderTab.background.selected
        : $ETPtheme.stopLimitRow.row.background.highlight};
  }

  &:has(input:focus) {
    background-color: ${({ $ETPtheme, $isDisabled }) =>
      $isDisabled
        ? $ETPtheme.orderTab.background.selected
        : $ETPtheme.stopLimitRow.row.background.highlight};
  }

  &:not(:hover)
    > .etp-quick-deal_stop-limit-rows__calculator
    #etp-quick-deal_calculator-input:not(:focus) {
    border: 1px solid transparent;
    background-color: transparent;
  }

  & > * {
    flex: 1;
  }
  .stop-limit-row__radio__container {
    position: relative;
    left: 3px;
    display: flex;
  }
  div:nth-child(2) {
    ${GenericInputStyle} {
      justify-content: space-between;

      &::after {
        background-color: ${({ $ETPtheme, $isDisabled, $isInactive }) =>
          $isDisabled || $isInactive
            ? $ETPtheme.stopLimitRow.input.background.disabled
            : $ETPtheme.stopLimitRow.input.background.default};
      }

      label {
        visibility: hidden;
        position: fixed;
        pointer-events: none;
      }

      input {
        padding-right: 1px;
        border: ${({ $ETPtheme, $isInactive }) =>
          $isInactive
            ? $ETPtheme.stopLimitRow.input.border.disabled
            : $ETPtheme.stopLimitRow.input.border.enabled};
      }
    }
  }
`;
