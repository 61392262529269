import { type ETPtheme } from "../../theme/etp-theme";
import { GlobalElementsStyle } from "../../theme/global-elements-style";
import styled from "styled-components";

export const InstrumentTitleStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  h3 {
    color: ${({ $ETPtheme }) => $ETPtheme.instrumentTitle.text.name};
    text-transform: uppercase;
    margin: 0;
    font-weight: 400;
    line-height: 1;
  }

  .instrument-info-container {
    strong:first-of-type {
      color: ${({ $ETPtheme }) => $ETPtheme.instrumentTitle.text.info};
      margin-right: 3px;
      font-size: 13px;
    }
  }
`;
