import { type ETPtheme } from "./etp-theme";

const light1 = "#FFFFFF";
const light2 = "#EAF1F2";
const light3 = "#F8FAFA";
const light4 = "#E2ECED";
const light5 = "#E8EDF1";
const light6 = "#F0F5F6";
const light7 = "#F5F6F7";
const light8 = "#D8D8DC";
const light9 = "#EBF1F2";
const light10 = "#D7D7DA";
const light11 = "#9A9AA2";
const light12 = "#F5FFF5";
const light13 = "#FFF7F7";
const light14 = "#FFF7EA";
const light15 = "#E7EEEF";
const light16 = "#F8F9FA";
const light17 = "#CBD4D5";
const light18 = "#E3ECED";
const light19 = "#F7FFF7";

const grey1 = "#5A5E5F";
const grey2 = "#46474F";
const grey3 = "#6C6C7A";
const grey4 = "#6A7787";
const grey5 = "#38383D";
const grey6 = "#B5BDBE";
const grey7 = "#B0B0B8";
const grey8 = "#E1E5E5";

const blue1 = "#11203E";
const blue2 = "#002954";
const blue3 = "#256297";
const blue4 = "#2B70AE";
const blue5 = "#2F7ABD";
const blue6 = "#337AB9";
const blue7 = "#206EB4";
const blue8 = "#3987CC";
const blue9 = "#C0D9EF";
const blue10 = "#2D5086";
const blue11 = "#0689FE";
const blue12 = "#25629733";
const blue13 = "#619FD6";
const blue14 = "#00295426";

const dark1 = "#262629";
const dark2 = "#262629E6";
const dark3 = "#000000CC";
const dark4 = "#26262914";
const dark5 = "#00000014";

const red1 = "#6A0D09";
const red2 = "#B72122";
const red3 = "#CB181A";
const red4 = "#FFE7E7";
const red5 = "#FF746F";

const green1 = "#DEF3DE";
const green2 = "#2A7A2C";
const green3 = "#9DD19D";

const yellow1 = "#F5AD1B";

const theme: ETPtheme = {
  id: "Light",
  consideration: {
    background: light1,
    border: `1px solid ${light17}`,
    text: {
      currentAndCalculatedHeaders: grey1,
      decorationColor: { mainTicket: grey2, previewTicket: grey2 },
      price: { mainTicket: blue1, previewTicket: dark1 },
      title: { mainTicket: blue1, previewTicket: grey3 },
    },
  },
  costsAndCharges: {
    background: {
      details: {
        body: light1,
        button: light2,
        expandableSection: light3,
        expandableIcon: light4,
        header: light5,
        overlay: dark2,
      },
      summary: light6,
    },
    border: `1px solid ${light18}`,
    text: {
      details: {
        text: grey1,
        title: blue1,
      },
      summary: blue1,
    },
  },
  directionButton: {
    background: {
      disabled: light7,
      selected: { buy: blue2, sell: red1 },
      unselected: light1,
    },
    border: {
      disabled: light7,
      unselected: grey6,
    },
    boxShadow: {
      selected: `inset 1px 1px 8px ${dark3}`,
      unselected: `0 2px 2px ${dark5}`,
    },
    text: {
      disabled: light8,
      selected: light1,
      unselected: {
        default: dark1,
        label: { buy: blue3, sell: red2 },
      },
    },
  },
  feedbackForm: {
    background: light1,
    border: `1px solid ${grey6}`,
    text: {
      title: blue1,
    },
    feedbackInputForm: {
      checkbox: {
        text: grey4,
      },
      label: blue1,
      submitButton: {
        background: {
          default: blue4,
          hover: blue5,
        },
        border: `1px solid ${blue4}`,
        boxShadow: `0 2px 2px 0 ${dark4}`,
        text: light1,
      },
      closeButton: {
        background: "transparent",
        border: blue4,
        text: blue4,
      },
      textarea: {
        background: light1,
        border: {
          default: `1px solid ${light17}`,
          focus: `1px solid ${blue11}`,
        },
        outline: {
          error: `2px solid ${red5}`,
        },
        text: {
          error: red5,
          userInput: dark1,
          placeholder: grey1,
        },
      },
    },
  },
  genericInput: {
    incrementerButtons: {
      background: {
        default: light9,
        hover: light8,
      },
      border: {
        default: grey3,
        disabled: light10,
        hover: dark1,
      },
    },
    inputField: {
      background: light1,
      border: {
        focusEnabled: `1px solid ${blue11}`,
        focusDisabled: `1px solid ${grey6}`,
        warning: `1px solid ${yellow1}`,
      },

      text: dark1,
      boxShadow: `0 0 0 1px ${blue12}`,
    },
  },
  icon: {
    close: {
      path: {
        fill: {
          default: grey1,
          hover: blue1,
        },
      },
    },
    spinner: {
      path: {
        fill: { default: grey5, secondary: light19 },
      },
    },
  },
  instrumentTitle: {
    text: {
      info: blue1,
      direction: {
        put: {
          name: red2,
          arrow: red3,
        },
        call: {
          name: blue3,
          arrow: blue6,
        },
      },
      name: grey2,
    },
  },
  link: {
    text: {
      enabled: blue7,
      disabled: light11,
    },
  },
  marketDepth: {
    background: {
      default: light1,
      hover: light2,
    },
    text: {
      default: blue1,
      link: blue8,
    },
  },
  message: {
    background: {
      confirmation: light12,
      acknowledge: light5,
      rejection: light13,
      warning: light14,
    },
    border: {
      confirmation: `1px solid ${green3}`,
      acknowledge: `1px solid ${blue13}`,
      rejection: `1px solid ${red5}`,
      warning: `1px solid ${yellow1}`,
    },
    icon: {
      rect: {
        fill: {
          confirmation: green1,
          acknowledge: blue9,
        },
      },
      path: {
        fill: {
          confirmation: green2,
          acknowledge: blue10,
          rejection: red4,
        },
        stroke: {
          confirmation: green2,
          acknowledge: blue10,
          rejection: red2,
        },
      },
    },
    text: {
      body: {
        confirmation: blue1,
        acknowledge: blue1,
        rejection: blue1,
        warning: blue1,
      },
      title: {
        confirmation: blue1,
        acknowledge: blue1,
        rejection: blue1,
      },
    },
  },
  orderTab: {
    background: {
      selected: light1,
      unselected: light15,
    },
    border: `1px solid ${grey6}`,
    text: {
      tabTitle: blue1,
    },
  },
  previewButton: {
    border: {
      primary: blue4,
      unarmed: grey6,
    },
  },
  previewTicket: {
    background: {
      default: light1,
      direction: {
        buy: blue3,
        sell: red2,
      },
    },
    border: `1px solid ${light17}`,
    text: {
      default: grey3,
      direction: light1,
      strong: dark1,
    },
  },
  profitAndLoss: {
    text: {
      loss: red2,
      neutral: blue1,
      profit: blue3,
    },
  },
  quickDeal: {
    border: {
      beforeLine: `1px solid ${light17}`,
      separatorLine: `1px solid ${grey7}`,
    },
  },
  radioButton: {
    background: {
      checked: light1,
      unchecked: light1,
    },
    border: {
      checked: `3px solid ${blue2}`,
      unchecked: "unset",
    },
    boxShadow: {
      checked: `0 0 0 1px ${blue2}`,
      unchecked: `0 0 0 1px ${grey6}, 0 0 0 transparent`,
      disabled: `0 0 0 1px ${grey8}, 0 0 0 transparent`, // TBC
    },
    text: {
      label: {
        enabled: blue1,
        disabled: light10,
      },
    },
  },
  sizeInputHelper: {
    text: grey1,
  },
  stopLimitRow: {
    input: {
      background: {
        default: light9,
        disabled: light16,
      },
      border: {
        enabled: `1px solid ${grey6}`,
        disabled: `1px solid ${grey8}`,
      },
    },
    row: {
      background: {
        highlight: light2,
      },
    },
  },
  tooltip: {
    background: light3,
    border: `1px solid ${light17}`,
    boxShadow: `0 2px 4px 0 ${blue14}`,
    text: {
      default: blue1,
      priceCalculatorDisclaimer: grey1,
    },
  },
  turboCalculator: {
    background: {
      inputField: light1,
    },
    border: {
      active: {
        focusDisabled: grey6,
        focusEnabled: blue11,
        hover: grey3,
      },
    },
    text: {
      calculatorHeading: grey1,
      input: {
        active: blue1,
        inactive: grey4,
      },
      underlyingPrice: grey4,
    },
  },
  underlyingExposure: {
    text: grey1,
  },
};

export default theme;
