import styled from "styled-components";
import { GlobalElementsStyle } from "../../theme/global-elements-style";
import { type ETPtheme } from "../../theme/etp-theme";

export const SizeInputStyle = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  margin: 4px 0 5px 0;

  .size-row {
    display: flex;
    gap: 4px;

    #size-row__container {
      flex: 2;
      gap: 4px;

      & > * {
        flex: 1;
      }
    }

    #size-row__product-info {
      flex: 1;

      p {
        line-height: 1;
        color: ${({ $ETPtheme }) => $ETPtheme.sizeInputHelper.text};
      }
    }
  }
`;
