import { wtpGet, wtpPost } from "../adapters/wtp-fetch-adapter";
import { type Session } from "../types/session";
import { IgEnvironment } from "../types/ig-environment";
import {
  type FeedbackPayload,
  UserFormStatus,
} from "../components/feedbackForm/FeedbackForm";

export interface UserPreferences {
  formStatus: UserFormStatus;
  canBeContacted: boolean;
  lastUpdated: string | null;
}

const resolveDomain = (env: IgEnvironment) => {
  if (env === IgEnvironment.UAT) {
    return "web-";
  } else if (env === IgEnvironment.DEMO) {
    return "demo-";
  } else if (env === IgEnvironment.PROD) {
    return "";
  }
  return "net-";
};

export class FeedbackService {
  private readonly url: string;

  constructor(
    private readonly session: Session,
    private readonly env: IgEnvironment,
    private readonly featureName: string,
  ) {
    this.session = session;
    this.env = env;
    this.url = `https://${resolveDomain(
      this.env,
    )}api.ig.com/feedback-service/v1/api/`;
    this.featureName = featureName;
  }

  getPreference = async (): Promise<UserPreferences> => {
    return await wtpGet(
      `${this.url}get-preference?featureName=${this.featureName}`,
      this.session,
      true,
    )
      .then(async (response) => await response.json())
      .then((res) => ({
        formStatus:
          (res.body.data.formStatus as UserFormStatus) ??
          UserFormStatus.INITIAL,
        canBeContacted: (res.body.data.canBeContacted as boolean) ?? false,
        lastUpdated: res.body.data.lastUpdated ?? null,
      }))
      .catch(() => ({
        formStatus: UserFormStatus.DISMISSED,
        canBeContacted: false,
        lastUpdated: null,
      }));
  };

  setPreference = async (
    canBeContacted: boolean,
    formStatus = UserFormStatus.SUBMITTED,
  ) => {
    await wtpPost(
      this.url + "set-preference",
      this.session,
      {
        canBeContacted,
        featureName: "quick-deal-survey",
        formStatus,
      },
      true,
    )
      .then((response) => {
        // console.log("response", response);
      })
      .catch((error) => {
        // TODO: Handle error
        console.error("error", error);
      });
  };

  submit = async (feedback: FeedbackPayload) => {
    await wtpPost(this.url + "submit-feedback", this.session, feedback, true)
      .then((response) => {
        // console.log("response", response);
      })
      .catch((error) => {
        // TODO: Handle error
        console.error("error", error);
      });
  };
}
