import styled, { css } from "styled-components";
import { type ETPtheme } from "../../theme/etp-theme";

export const DirectionLabelPriceWrapper = styled.div``;
export const DirectionLabel = styled.div``;
export const DirectionVolume = styled.div``;
export const DirectionPrice = styled.div``;

export const DirectionButtonStyle = styled.button<{
  $direction: string;
  $ETPtheme: ETPtheme;
}>`
  position: relative;
  border-radius: 4px;
  display: flex;
  flex-direction: ${({ $direction }) =>
    $direction === "buy" ? "row" : "row-reverse"};
  width: 50%;
  height: 40px;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  overflow: hidden;

  &.selected {
    background: ${({ $direction, $ETPtheme }) =>
      $direction === "buy"
        ? $ETPtheme.directionButton.background.selected.buy
        : $ETPtheme.directionButton.background.selected.sell};
    box-shadow: ${({ $ETPtheme }) =>
      $ETPtheme.directionButton.boxShadow.selected};
    color: ${({ $ETPtheme }) => $ETPtheme.directionButton.text.selected};
    
    span svg path {
      fill: ${({ $ETPtheme }) => $ETPtheme.directionButton.text.selected}
    }
  }

  &.unselected {
    ${({ $ETPtheme }) => css`
      background: ${$ETPtheme.directionButton.background.unselected};
      border: 1px solid ${$ETPtheme.directionButton.border.unselected};
      box-shadow: ${$ETPtheme.directionButton.boxShadow.unselected};
      color: ${$ETPtheme.directionButton.text.unselected.default};
    `}

    span svg path {
      fill: ${({ $ETPtheme }) =>
        $ETPtheme.directionButton.text.unselected.default}
    }

    ${DirectionLabel} {
      margin: 3px 0 0 0;
      color: ${({ $direction, $ETPtheme }) =>
        $direction === "buy"
          ? $ETPtheme.directionButton.text.unselected.label.buy
          : $ETPtheme.directionButton.text.unselected.label.sell};
    }

    ${DirectionLabelPriceWrapper} {
      padding: ${({ $direction }) =>
        $direction === "buy" ? "0 19px 0 7px" : "0 8px 0 19px"};
    }

    ${DirectionVolume} {
      ${({ $direction }) => ($direction === "buy" ? `right` : `left`)}: 3px;
    }
  }

  &:disabled {
    cursor: default;
    box-shadow: none;
    ${({ $ETPtheme }) => css`
      background-color: ${$ETPtheme.directionButton.background.disabled};
      border-color: ${$ETPtheme.directionButton.border.disabled};
      color: ${$ETPtheme.directionButton.text.disabled};

      ${DirectionLabel} {
        color: ${$ETPtheme.directionButton.text.disabled};
      }
    `}

    span svg path {
      fill: ${({ $ETPtheme }) => $ETPtheme.directionButton.text.disabled}
    }
  }

  ${DirectionLabelPriceWrapper} {
    width: 100%;
    height: 100%;
    padding: ${({ $direction }) =>
      $direction === "buy" ? "0 20px 0 8px" : "0 9px 0 20px"};
  }

  ${DirectionLabel} {
    width: 100%;
    height: 13px;
    display: flex;
    margin: 4px 0 0 0;
    align-content: center;
    justify-content: center;
    padding-right: ${({ $direction }) => ($direction === "buy" ? "0" : "3px")};

    strong {
      text-transform: uppercase;
      font-weight: 600;
      line-height: 13px;
    }
  }

  ${DirectionVolume} {
    position: absolute;
    top: 50%;
    translate: 0 -48%;
    ${({ $direction }) => ($direction === "buy" ? `right` : `left`)}: 4px;
    padding-left: ${({ $direction }) => ($direction === "buy" ? "0" : "1px")};
  }

  ${DirectionPrice} {
    position: relative;
    display: flex;
    align-items: baseline;
    justify-content: center;
    width: 100%;
    white-space: nowrap;
    gap: 3px;
    padding: ${({ $direction }) =>
      $direction === "buy" ? "4.5px 0 0 2px" : "4.5px 1px 0 0"};

    strong {
      font-size: 15px;
      line-height: 16px;
    }
`;
