import styled from "styled-components";
import { Direction } from "../../types/directions";
import { type ETPtheme } from "../../theme/etp-theme";

export const MarketDepthRowStyles = styled.li<{
  $ETPtheme: ETPtheme;
  $direction: Direction;
}>`
  display: flex;
  flex-direction: ${({ $direction }) =>
    $direction === Direction.SELL ? "row-reverse" : "row"};
  align-items: center;
  height: 24px;
  line-height: 23px;
  color: ${({ $ETPtheme }) => $ETPtheme.marketDepth.text.default};

  button {
    flex: 3;
    display: flex;
    background: inherit;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    align-items: center;
    height: 24px;
    line-height: 23px;
    padding: 0;

    &:disabled {
      cursor: default;
    }
    &:enabled > strong:hover,
    &:enabled > p:hover {
      background-color: ${({ $ETPtheme }) =>
        $ETPtheme.marketDepth.background.hover};
    }

    strong {
      flex: 1;
      font-size: 13px;
      font-weight: 600;
      text-align: ${({ $direction }) =>
        $direction === Direction.SELL ? "end" : "start"};
    }

    p {
      flex: 2;
      font-size: 9px;
      text-align: ${({ $direction }) =>
        $direction === Direction.SELL ? "start" : "end"};
    }
  }
`;
