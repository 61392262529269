import { OrderType } from "../types/ig-trading-order-ticket";
import { type Ticket } from "../types/ticket";

export const calculateProfitAndLoss = (
  closeLevel: number | null,
  openLevel: number | undefined,
  ticketSize: number,
): number | undefined => {
  if (
    closeLevel === null ||
    closeLevel === 0 ||
    ticketSize === 0 ||
    openLevel === undefined
  ) {
    return undefined;
  }
  const delta = closeLevel - openLevel;
  return delta * ticketSize;
};

export const getStopLimitLevel = (orderType: OrderType, ticket: Ticket) => {
  switch (orderType) {
    case OrderType.LIMIT:
      return ticket.triggerLevel;
    case OrderType.STOP_MARKET:
      return ticket.stopPrice;
    default:
      return undefined;
  }
};
