import { useTranslation } from "react-i18next";
import { type Direction } from "../../types/directions";
import { useETPTheme } from "../../hooks/use-etp-theme";
import {
  DirectionButtonStyle,
  DirectionLabel,
  DirectionLabelPriceWrapper,
  DirectionPrice,
  DirectionVolume,
} from "./DirectionButtonStyles";

import PriceTick from "./PriceTick";
import { formatNumberPrice } from "../../utils/format-pricing-data";

interface DirectionButtonProps {
  direction: Direction;
  isSelected: boolean;
  clickHandler: () => void;
  isDisabled: boolean;
  price: number | null;
  decimalPlacesFactor: number;
  volume: string | undefined;
}

const DirectionButton = ({
  direction,
  isSelected,
  clickHandler,
  isDisabled,
  price,
  decimalPlacesFactor,
  volume,
}: DirectionButtonProps) => {
  const { t } = useTranslation();
  const ETPtheme = useETPTheme();

  return (
    <DirectionButtonStyle
      type="button"
      data-testid={`direction-button-${direction}`}
      className={isSelected ? "selected" : "unselected"}
      onClick={clickHandler}
      disabled={isDisabled}
      aria-labelledby={`etp-quick-deal-${direction}-button`}
      $direction={direction}
      $ETPtheme={ETPtheme}
    >
      <DirectionLabelPriceWrapper>
        <DirectionLabel>
          <strong id={`etp-quick-deal-${direction}-button`}>
            {t(`${direction}-button`)}
          </strong>
        </DirectionLabel>
        <DirectionPrice data-testid={`direction-button-${direction}-price`}>
          <strong>{formatNumberPrice(price, decimalPlacesFactor)}</strong>
          <PriceTick price={price} />
        </DirectionPrice>
      </DirectionLabelPriceWrapper>
      {volume !== undefined && (
        <DirectionVolume data-testid={`direction-button-${direction}-volume`}>
          <strong>{volume}</strong>
        </DirectionVolume>
      )}
    </DirectionButtonStyle>
  );
};

export default DirectionButton;
