import { SessionProvider } from "./hooks/use-session";
import { LightstreamerProvider } from "@ig-wpx/lightstreamer";
import { type AppProps } from "./types/app-props";
import { type Session, type SessionData } from "./types/session";
import i18n from "./translation/i18n";
import { SUPPORTED_LANGUAGES } from "./translation/supported-languages";
import { resolveDomain } from "./utils/resolve-domain";
import { AdapterProvider } from "./hooks/use-adapter";
import { directionFormat } from "./utils/direction-format";
import { type Direction } from "./types/directions";
import QuickDeal from "./QuickDeal";
import { SubscriptionProvider } from "./hooks/use-subscription";
import { ETPStyleProvider } from "./hooks/use-etp-theme";
import QuickDealAdapterFactory from "./adapters/quick-deal-adapter-factory";
import { InstrumentDataProvider } from "./hooks/use-instrument-data";
import { useEffect, useMemo } from "react";
import { FeatureFlagProvider } from "./hooks/use-feature-flag";
import { FeatureFlagLookUpService } from "./services/feature-flag-look-up-service";
import { DefaultGaTracer } from "./google-analytics/default-ga-tracer";

export default function Root(props: AppProps) {
  const { xst, cst, sessionData, apiHost, version, env } = props;
  const { clientLocale } = sessionData;
  const session: Session = useMemo(
    () => mapPropsToSessionObject(xst, cst, sessionData, apiHost, version),
    [apiHost, cst, sessionData, version, xst],
  );
  const direction: Direction = directionFormat(props.direction);

  useEffect(() => {
    setLanguage(clientLocale);
  }, [clientLocale]);

  const noOp = () => {};

  const featureFlagLookUpService = new FeatureFlagLookUpService(
    env,
    props.etpQuickDealFeatureFlags ?? {},
  );

  const gaTracer = new DefaultGaTracer(props.trackEvent ?? noOp);

  return (
    <SessionProvider session={session}>
      <FeatureFlagProvider
        isFeatureFlagEnabled={featureFlagLookUpService.isEnabled}
      >
        <LightstreamerProvider
          client={{
            currentAccountId: props.sessionData.currentAccountId,
            cst,
            env,
          }}
          origin={`https://${resolveDomain(env)}wtp.ig.com`}
        >
          <ETPStyleProvider WTPtheme={props.theme}>
            <InstrumentDataProvider instrumentEpic={props.instrumentEpic}>
              <SubscriptionProvider instrumentEpic={props.instrumentEpic}>
                <AdapterProvider
                  session={session}
                  factory={new QuickDealAdapterFactory()}
                >
                  <QuickDeal
                    initialDirection={direction}
                    showPreviewOrder={props.showPreviewOrder}
                    setUserPlatformPreference={props.setUserPlatformPreference}
                    gaTracer={gaTracer}
                    isQuickDealDisplayed={props.isQuickDealDisplayed ?? true}
                    env={env}
                  />
                </AdapterProvider>
              </SubscriptionProvider>
            </InstrumentDataProvider>
          </ETPStyleProvider>
        </LightstreamerProvider>
      </FeatureFlagProvider>
    </SessionProvider>
  );
}

const setLanguage = (locale: (typeof SUPPORTED_LANGUAGES)[number]) => {
  if (SUPPORTED_LANGUAGES.includes(locale)) {
    void i18n.changeLanguage(locale);
  }
};

const mapPropsToSessionObject = (
  xst: string,
  cst: string,
  sessionData: SessionData,
  apiHost: string,
  version: string,
) => {
  const session: Session = {
    xst,
    cst,
    sessionData,
    environment: {
      origin: apiHost,
      version,
    },
  };
  return session;
};
