import { RadioButtonStyles } from "./RadioButtonStyles";
import { useETPTheme } from "../../../hooks/use-etp-theme";
import { type ETPtheme } from "../../../theme/etp-theme";

interface EtpRadioButtonProps {
  name: string;
  radioButtonsName: string;
  radioLabelText: string;
  isChecked: boolean;
  handleClick: () => void;
  isDisabled: boolean;
}
const RadioButton = ({
  name,
  radioButtonsName,
  radioLabelText,
  isChecked,
  handleClick,
  isDisabled,
}: EtpRadioButtonProps) => {
  const ETPtheme: ETPtheme = useETPTheme();
  return (
    <RadioButtonStyles $ETPtheme={ETPtheme}>
      <input
        type="radio"
        id={`${name}-row__radio`}
        className="etp-quick-deal-radio-button__button"
        name={radioButtonsName}
        checked={isChecked}
        onChange={() => {}}
        onClick={handleClick}
        disabled={isDisabled}
      />
      <span className="etp-quick-deal-radio-button__custom-button"></span>
      <label
        htmlFor={`${name}-row__radio`}
        className="etp-quick-deal-radio-button__label"
      >
        {radioLabelText}
      </label>
    </RadioButtonStyles>
  );
};

export default RadioButton;
