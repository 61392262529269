import { useTranslation } from "react-i18next";
import { calculateProfitAndLoss } from "../../utils/profit-and-loss-calculator";
import { useSubscription } from "../../hooks/use-subscription";
import { convertLocalToCurrencySymbol } from "../../utils/currency-local-format";
import { useSession } from "../../hooks/use-session";
import { ProfitAndLossContainer } from "./ProfitAndLossStyles";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { type ETPtheme } from "../../theme/etp-theme";
import { exchangeToAccountCurrencyWithoutFee } from "../../services/currency-exchange-calculator";
import { OrderType } from "../../types/ig-trading-order-ticket";

interface ProfitAndLossProps {
  orderType: OrderType;
  stopLimitLevel: number | undefined;
  openLevel: number | undefined;
  ticketSize: number;
  ticketDefaultCurrency: string;
  baseExchangeRate: number;
}

const ProfitAndLoss = ({
  orderType,
  stopLimitLevel,
  openLevel,
  ticketSize,
  ticketDefaultCurrency,
  baseExchangeRate,
}: ProfitAndLossProps) => {
  const { t } = useTranslation();
  const pricingData = useSubscription();
  const { sessionData } = useSession();
  const ETPtheme: ETPtheme = useETPTheme();

  const getCloseLevel = () => {
    if ([OrderType.LIMIT, OrderType.STOP_MARKET].includes(orderType)) {
      return stopLimitLevel === undefined ? null : Number(stopLimitLevel);
    }
    return pricingData.bid;
  };

  const profitAndLoss = calculateProfitAndLoss(
    getCloseLevel(),
    openLevel,
    ticketSize,
  );

  const ticketCurrencyProfitAndLoss = convertLocalToCurrencySymbol(
    profitAndLoss,
    sessionData.clientLocale,
    ticketDefaultCurrency,
  );

  const hasDifferentLocalCurrency =
    ticketDefaultCurrency !== sessionData.currencyIsoCode;

  let profitStatus: string = "profit-and-loss__";
  if (profitAndLoss === undefined || profitAndLoss === 0) {
    profitStatus += "neutral";
  } else if (profitAndLoss < 0) {
    profitStatus += "loss";
  } else {
    profitStatus += "profit";
  }

  return (
    <ProfitAndLossContainer $ETPtheme={ETPtheme}>
      <dl>
        <dt id="etp-quick-deal-profit-and-loss-value">
          {t("profit-and-loss")}
        </dt>
        <dd
          className={`ticket-currency ${profitStatus}`}
          aria-labelledby="etp-quick-deal-profit-and-loss-value"
        >
          {ticketCurrencyProfitAndLoss}
        </dd>

        {hasDifferentLocalCurrency && (
          <dd
            className={`local-currency ${profitStatus}`}
            aria-labelledby="etp-quick-deal-profit-and-loss-value"
          >
            {convertIntoClientCurrency()}
          </dd>
        )}
      </dl>
    </ProfitAndLossContainer>
  );

  function convertIntoClientCurrency(): string {
    const convertedProfitAndLoss =
      profitAndLoss === undefined
        ? undefined
        : exchangeToAccountCurrencyWithoutFee(profitAndLoss, baseExchangeRate);

    return convertLocalToCurrencySymbol(
      convertedProfitAndLoss,
      sessionData.clientLocale,
      sessionData.currencyIsoCode,
    );
  }
};

export default ProfitAndLoss;
