import { type Direction } from "../../../types/directions";
import { useTranslation } from "react-i18next";
import { useSession } from "../../../hooks/use-session";
import { convertLocalToCurrencySymbol } from "../../../utils/currency-local-format";

interface TotalCostProps {
  totalCostInTicketCurrency: number | undefined;
  direction: Direction;
  ticketCurrency?: string;
  totalCostInAccountCurrency?: number | undefined;
  accountCurrency?: string;
}

const TotalCost = ({
  totalCostInTicketCurrency,
  direction,
  ticketCurrency,
  totalCostInAccountCurrency,
  accountCurrency,
}: TotalCostProps) => {
  const { t } = useTranslation();
  const { sessionData } = useSession();
  const isCurrencyDifferentToAccount = ticketCurrency !== accountCurrency;

  return (
    <>
      <dt id="etp-quick-deal-preview-total-cost">
        {t(`preview-ticket.total-cost-label-${direction}`)}
      </dt>
      <dd aria-labelledby="etp-quick-deal-preview-total-cost">
        {convertLocalToCurrencySymbol(
          totalCostInTicketCurrency,
          sessionData.clientLocale,
          ticketCurrency,
        )}
      </dd>
      {isCurrencyDifferentToAccount && (
        <dd aria-labelledby="etp-quick-deal-preview-total-cost">
          {convertLocalToCurrencySymbol(
            totalCostInAccountCurrency,
            sessionData.clientLocale,
            accountCurrency,
          )}
        </dd>
      )}
    </>
  );
};

export default TotalCost;
