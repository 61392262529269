import { Direction } from "../types/directions";

const DECIMAL_PLACES_FACTOR: number = 10;

export const priceWithTolerance = (
  ticketDirection: Direction,
  touchPrice: number,
  priceTolerance: number,
) => {
  const price: number = isNaN(touchPrice) ? 0 : touchPrice;
  if (ticketDirection === Direction.BUY) {
    return round(price + priceTolerance, DECIMAL_PLACES_FACTOR);
  } else {
    return round(Math.max(price - priceTolerance, 0.01), DECIMAL_PLACES_FACTOR);
  }
};

// Should be used when two price levels are subtracted to avoid floating point errors.
export const round = (value: number, decimalPlacesFactor = 1) => {
  const exponent = Math.pow(10, decimalPlacesFactor);
  return Math.round(Math.abs(value) * exponent) / exponent;
};
