import { type Data, useLsSubscription } from "@ig-wpx/lightstreamer";
import { useSession } from "./use-session";
import { type RawPricingData } from "../types/pricing-data";
import MemoSubscriptionIdPrice from "./memo-subscription-id-price";
import { InstrumentType } from "../types/instrument-type";
import { MarketStatus } from "../types/pricing-data";

// Temporary schema as we not in control of Constant releases for volumes
const FINANCE_PRICES_SCHEMA_WITHOUT_VOLUME = ["BID", "OFFER", "MARKET_STATUS"];

const FINANCE_PRICES_SCHEMA = [
  "BID",
  "OFFER",
  "BEST_BID_SIZE",
  "BEST_ASK_SIZE",
  "MARKET_STATUS",
];

const convertToPricingData = (
  data: Data<string[]> | undefined,
): RawPricingData | undefined => {
  if (data === undefined) {
    return undefined;
  }

  return {
    bid: data.BID,
    offer: data.OFFER,
    bidSize: data.BEST_BID_SIZE,
    offerSize: data.BEST_ASK_SIZE,
    marketStatus: MarketStatus[data.MARKET_STATUS as keyof typeof MarketStatus],
  };
};

export const useM3PricingLightstreamer = (
  instrumentEpic: string,
  instrumentType: InstrumentType,
): RawPricingData | undefined => {
  const currentAccountId = useSession().sessionData.currentAccountId;

  // Finance prices for prices
  const subscriptionIdPrice = MemoSubscriptionIdPrice(
    currentAccountId,
    instrumentEpic,
  );

  const isWarrant = instrumentType === InstrumentType.WARRANT;
  const { data } = useLsSubscription(
    subscriptionIdPrice,
    isWarrant ? FINANCE_PRICES_SCHEMA : FINANCE_PRICES_SCHEMA_WITHOUT_VOLUME,
    "MERGE",
  );
  return convertToPricingData(data);
};
