import { type ReactNode, useContext } from "react";
import { resolveInstrumentTypeByString } from "../utils/resolve-instrument-type";
import { InstrumentType } from "../types/instrument-type";
import { useInstrumentData } from "./use-instrument-data";
import {
  SubscriptionTurboContext,
  SubscriptionTurboProvider,
} from "./use-turbo-subscription";
import {
  SubscriptionM3Context,
  SubscriptionM3Provider,
} from "./use-m3-subscription";
interface SubscriptionProviderProps {
  children: ReactNode;
  instrumentEpic: string;
}

export const SubscriptionProvider = ({
  children,
  instrumentEpic,
}: SubscriptionProviderProps) => {
  const { instrumentData } = useInstrumentData();
  const instrumentType = resolveInstrumentTypeByString(
    instrumentData.instrumentType,
  );

  if (instrumentType === InstrumentType.TURBO) {
    return (
      <SubscriptionTurboProvider
        instrumentEpic={instrumentEpic}
        snapshotBid={instrumentData.displayBid}
        snapshotOffer={instrumentData.displayOffer}
        snapshotMarketStatus={instrumentData.ncrMarketStatus}
      >
        {children}
      </SubscriptionTurboProvider>
    );
  }
  return (
    <SubscriptionM3Provider
      instrumentEpic={instrumentEpic}
      instrumentType={instrumentType}
      snapshotBid={instrumentData.displayBid}
      snapshotOffer={instrumentData.displayOffer}
      snapshotMarketStatus={instrumentData.ncrMarketStatus}
    >
      {children}
    </SubscriptionM3Provider>
  );
};

export const useSubscription = () => {
  const { instrumentData } = useInstrumentData();
  const resolvedInstrumentType = resolveInstrumentTypeByString(
    instrumentData.instrumentType,
  );
  const isTurbo = resolvedInstrumentType === InstrumentType.TURBO;

  const subscriptionContext = useContext(
    isTurbo ? SubscriptionTurboContext : SubscriptionM3Context,
  );

  if (subscriptionContext === null) {
    throw new Error("No subscriptions have been made");
  }
  return subscriptionContext;
};
