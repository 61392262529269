import styled from "styled-components";
import { GlobalElementsStyle } from "../../theme/global-elements-style";
import { type ETPtheme } from "../../theme/etp-theme";
import { GenericInputStyle } from "../generics/input/InputStyles";

export const TurboCalculatorStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  display: flex;
  flex-direction: column;
  color: ${({ $ETPtheme }) => $ETPtheme.turboCalculator.text.underlyingPrice};

  ${GenericInputStyle} {
    flex-direction: column-reverse;

    input {
      width: 60px;
      height: 15px;
      font-size: 13px;
      font-weight: inherit;
      padding: 0 0 0 1px;
      border: 1px solid
        ${({ $ETPtheme }) =>
          $ETPtheme.turboCalculator.border.active.focusDisabled};
      border-radius: 2px;
      color: ${({ $ETPtheme }) =>
        $ETPtheme.turboCalculator.text.input.inactive};

      &:hover {
        border-color: ${({ $ETPtheme }) =>
          $ETPtheme.turboCalculator.border.active.hover};
        color: ${({ $ETPtheme }) =>
          $ETPtheme.turboCalculator.text.input.active};
      }

      &:focus {
        outline: none !important;
        border-color: ${({ $ETPtheme }) =>
          $ETPtheme.turboCalculator.border.active.focusEnabled};
        color: ${({ $ETPtheme }) =>
          $ETPtheme.turboCalculator.text.input.active};
      }

      ::placeholder {
        color: ${({ $ETPtheme }) =>
          $ETPtheme.turboCalculator.text.input.inactive};
        opacity: 1; /* Firefox */
      }
    }

    label {
      margin-bottom: 0;
      font-size: 9px;
      font-weight: 400;
      line-height: 1;
      color: ${({ $ETPtheme }) =>
        $ETPtheme.turboCalculator.text.underlyingPrice};
    }
  }
`;
