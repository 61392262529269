import { type Session } from "../types/session";
import { igApiHeadersProvider } from "./ig-api/ig-api-headers-provider";

export async function wtpGet(
  urlPath: string,
  session: Session,
  isFullPath: boolean = false,
) {
  const fullUrl = isFullPath
    ? urlPath
    : `${session.environment.origin}${urlPath}`;

  // nosemgrep: nodejs_scan.javascript-ssrf-rule-node_ssrf
  return await fetch(fullUrl, {
    headers: igApiHeadersProvider(session),
  });
}

export async function wtpPost(
  urlPath: string,
  session: Session,
  body: any,
  isFullPath: boolean = false,
) {
  const fullUrl = isFullPath
    ? urlPath
    : `${session.environment.origin}${urlPath}`;

  // nosemgrep: nodejs_scan.javascript-ssrf-rule-node_ssrf
  return await fetch(fullUrl, {
    method: "POST",
    headers: igApiHeadersProvider(session),
    body: JSON.stringify(body),
  });
}
