import React from "react";
import { type Instrument } from "../../types/instrument";
import { useTranslation } from "react-i18next";
import { resolveInstrumentTypeByInstrument } from "../../utils/resolve-instrument-type";
import { InstrumentType } from "../../types/instrument-type";
import InstrumentOptionType from "./InstrumentOptionType";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { InstrumentTitleStyles } from "./InstrumentTitleStyles";

export interface InstrumentTitleProps {
  instrument: Instrument;
}

const InstrumentTitle = ({ instrument }: InstrumentTitleProps) => {
  const ETPtheme = useETPTheme();
  const { t } = useTranslation();
  const instrumentType = resolveInstrumentTypeByInstrument(instrument);

  const instrumentInfo = () => {
    if (instrumentType === InstrumentType.CONSTANT_LEVERAGE) {
      return (
        <strong data-testid="instrument-leverage-factor">
          {instrument.leverageFactor}
          {t("daily-leverage")}
        </strong>
      );
    } else if (instrumentType === InstrumentType.WARRANT) {
      return (
        <strong>
          <span data-testid="instrument-strike-level">
            {instrument.strikeLevel} expiring
          </span>
          <span data-testid="instrument-expiry-date">
            {instrument.expiryDetails.expiryDate}
          </span>
        </strong>
      );
    } else {
      return (
        <strong data-testid="instrument-strike-level">
          {instrument.strikeLevel} {t("ko-level")}
        </strong>
      );
    }
  };

  return (
    <section>
      <InstrumentTitleStyles
        data-testid="instrument-name-container"
        $ETPtheme={ETPtheme}
      >
        <h3 data-testid="instrument-name">{instrument.instrumentName}</h3>
        <div className="instrument-info-container">
          {instrumentInfo()}
          <InstrumentOptionType
            instrumentType={instrumentType}
            optionType={instrument.optionType}
          />
        </div>
      </InstrumentTitleStyles>
    </section>
  );
};

export default InstrumentTitle;
