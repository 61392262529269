import { type ETPtheme } from "./etp-theme";

const dark1 = "#11171C";
const dark2 = "#212B36";
const dark3 = "#192129";
const dark4 = "#303C4A";
const dark5 = "#000000E6";
const dark6 = "#1A1A1E";
const dark7 = "#2F2F34";
const dark8 = "#262629";

const light1 = "#F0F5F6";
const light2 = "#F5F6F7";
const light3 = "#FFFFFF";
const light4 = "#F7FFF7";
const light5 = "#D8D8DC";
const light6 = "#00000040";
const light7 = "#26262914";
const light8 = "#868686";

const grey1 = "#738192";
const grey2 = "#9696A3";
const grey3 = "#6A7787";
const grey4 = "#6C6C7A";
const grey5 = "#8292A6";
const grey6 = "#46474F";
const grey7 = "#5D6772";
const grey8 = "#5D6772";
const grey9 = "#353F4A";
const grey10 = "#000000BF";
const grey11 = "#11171CD9";

const blue1 = "#0576DB";
const blue2 = "#37A1FF";
const blue3 = "#2B70AE";
const blue4 = "#2F7ABD";
const blue5 = "#337AB9";
const blue6 = "#2794F5";
const blue7 = "#3987CC";
const blue8 = "#88B7E0";
const blue9 = "#5095D4";
const blue10 = "#0689FE";
const blue11 = "#337AB933";
const blue12 = "#B0CFEB";

const red1 = "#E01B1C";
const red2 = "#FF343A";
const red3 = "#CB181A";
const red4 = "#FF9793";
const red5 = "#FF746F";
const red6 = "#D04246";

const green1 = "#9DD19D";

const yellow1 = "#F5AD1B";

const theme: ETPtheme = {
  id: "Dark",
  consideration: {
    background: dark1,
    border: `1px solid ${dark4}`,
    text: {
      price: { mainTicket: light1, previewTicket: light1 },
      currentAndCalculatedHeaders: grey1,
      title: { mainTicket: light1, previewTicket: grey2 },
      decorationColor: { mainTicket: grey2, previewTicket: grey2 },
    },
  },
  costsAndCharges: {
    background: {
      summary: dark2,
      details: {
        body: dark1,
        button: dark3,
        expandableSection: dark2,
        expandableIcon: dark4,
        header: dark3,
        overlay: dark5,
      },
    },
    border: `1px solid ${dark2}`,
    text: {
      details: {
        text: grey1,
        title: light1,
      },
      summary: light1,
    },
  },
  directionButton: {
    background: {
      disabled: dark4,
      selected: { buy: blue1, sell: red1 },
      unselected: dark4,
    },
    border: {
      disabled: dark4,
      unselected: grey3,
    },
    boxShadow: {
      selected: `inset 0 4px 4px ${light6}`,
      unselected: `0 2px 8px ${grey10}`,
    },
    text: {
      disabled: grey4,
      selected: light2,
      unselected: {
        default: light1,
        label: { buy: blue2, sell: red2 },
      },
    },
  },
  feedbackForm: {
    background: dark1,
    border: `1px solid ${grey3}`,
    text: {
      title: light1,
    },
    feedbackInputForm: {
      checkbox: {
        text: grey5,
      },
      label: light1,
      submitButton: {
        background: {
          default: blue3,
          hover: blue4,
        },
        border: `1px solid ${blue3}`,
        boxShadow: `0 2px 2px 0 ${light7}`,
        text: light3,
      },
      closeButton: {
        background: "transparent",
        border: blue3, // TODO: Confirm this color in dark
        text: blue3,
      },
      textarea: {
        background: dark6,
        border: {
          default: `1px solid ${dark4}`,
          focus: `1px solid ${blue10}`,
        },
        outline: {
          error: `2px solid ${red1}`,
        },
        text: {
          error: red1,
          userInput: light3,
          placeholder: grey5,
        },
      },
    },
  },
  genericInput: {
    incrementerButtons: {
      background: {
        default: dark6,
        hover: grey6,
      },
      border: {
        default: light2,
        disabled: grey7,
        hover: light2,
      },
    },
    inputField: {
      background: dark6,
      border: {
        focusEnabled: `1px solid ${blue10}`,
        focusDisabled: `1px solid ${dark4}`,
        warning: `1px solid ${yellow1}`,
      },
      text: light3,
      boxShadow: `0 0 0 1px ${blue11}`,
    },
  },
  icon: {
    close: {
      path: {
        fill: {
          default: grey5,
          hover: light1,
        },
      },
    },
    spinner: {
      path: {
        fill: {
          default: light4,
          secondary: light4,
        },
      },
    },
  },
  instrumentTitle: {
    text: {
      info: light1,
      direction: {
        put: {
          name: red2,
          arrow: red3,
        },
        call: {
          name: blue2,
          arrow: blue5,
        },
      },
      name: grey2,
    },
  },
  link: {
    text: {
      enabled: blue6,
      disabled: grey8,
    },
  },
  marketDepth: {
    background: {
      default: dark1,
      hover: grey9,
    },
    text: {
      default: light1,
      link: blue7,
    },
  },
  message: {
    background: {
      confirmation: dark3,
      acknowledge: dark3,
      rejection: dark3,
      warning: dark3,
    },
    border: {
      confirmation: `1px solid ${green1}`,
      acknowledge: `1px solid ${blue12}`,
      rejection: `1px solid ${red5}`,
      warning: `1px solid ${yellow1}`,
    },
    icon: {
      rect: {
        fill: {
          confirmation: dark4,
          acknowledge: dark4,
        },
      },
      path: {
        fill: {
          confirmation: green1,
          acknowledge: blue8,
          rejection: dark4,
        },
        stroke: {
          confirmation: green1,
          acknowledge: blue8,
          rejection: red4,
        },
      },
    },
    text: {
      body: {
        confirmation: light1,
        acknowledge: light1,
        rejection: light1,
        warning: light1,
      },
      title: {
        confirmation: green1,
        acknowledge: blue8,
        rejection: red5,
      },
    },
  },
  orderTab: {
    background: {
      selected: dark1,
      unselected: dark4,
    },
    border: `1px solid ${grey3}`,
    text: {
      tabTitle: light5,
    },
  },
  previewButton: {
    border: {
      primary: blue3,
      unarmed: grey3,
    },
  },
  previewTicket: {
    background: {
      default: grey6,
      direction: {
        buy: blue9,
        sell: red6,
      },
    },
    border: `1px solid ${dark8}`,
    text: {
      default: grey2,
      direction: light2,
      strong: light2,
    },
  },
  profitAndLoss: {
    text: {
      loss: red2,
      neutral: light1,
      profit: blue2,
    },
  },
  quickDeal: {
    border: {
      beforeLine: `1px solid ${dark4}`,
      separatorLine: `1px solid ${dark4}`,
    },
  },
  radioButton: {
    background: {
      checked: light2,
      unchecked: dark1,
    },
    border: {
      checked: `3px solid ${blue10}`,
      unchecked: "unset",
    },
    boxShadow: {
      checked: `0 0 0 1px ${blue10}`,
      unchecked: `0 0 0 1px ${light8}, 0 0 0 transparent`,
      disabled: `0 0 0 1px ${dark4}, 0 0 0 transparent`, // TBC
    },
    text: {
      label: {
        enabled: light1,
        disabled: grey7,
      },
    },
  },
  sizeInputHelper: {
    text: grey1,
  },
  stopLimitRow: {
    input: {
      background: {
        default: dark6,
        disabled: dark6,
      },
      border: {
        enabled: `1px solid ${dark4}`,
        disabled: `1px solid ${dark4}`,
      },
    },
    row: {
      background: {
        highlight: grey9,
      },
    },
  },
  tooltip: {
    background: dark2,
    border: `1px solid ${dark4}`,
    boxShadow: `0 2px 4px 0 ${grey11}`,
    text: {
      default: light1,
      priceCalculatorDisclaimer: grey1,
    },
  },
  turboCalculator: {
    background: {
      inputField: dark6,
    },
    border: {
      active: {
        focusDisabled: dark4,
        focusEnabled: blue10,
        hover: dark7,
      },
    },
    text: {
      calculatorHeading: grey1,
      underlyingPrice: grey5,
      input: {
        active: light1,
        inactive: grey5,
      },
    },
  },
  underlyingExposure: {
    text: grey1,
  },
};

export default theme;
