import { type OrderAdapter } from "../types/order-adapter";
import { type Order } from "../types/order";
import { type ExistingOrdersProvider } from "../types/existing-orders-provider";
import { DirectionSymbol } from "../types/directions";

export class OrderMonitoringService implements ExistingOrdersProvider {
  private readonly orderAdapter: OrderAdapter;
  private readonly instrumentEpic: string;
  private readonly orderChangeCallback: () => void;
  private existingOrders: Order[];

  constructor(
    orderAdapter: OrderAdapter,
    instrumentEpic: string,
    orderChangeCallback: () => void,
  ) {
    this.orderAdapter = orderAdapter;
    this.instrumentEpic = instrumentEpic;
    this.existingOrders = [];
    this.orderChangeCallback = orderChangeCallback;
  }

  startMonitoringOrders = async () => {
    const orders = await this.orderAdapter.getExistingOrders(
      this.instrumentEpic,
    );
    this.existingOrders = orders;
    this.orderAdapter.startListeningForUpdates(
      orders,
      this.handleOnAddedOrder,
      this.handleOnRemovedOrder,
      this.handleOnChangedOrder,
    );
    return orders;
  };

  private readonly handleOnAddedOrder = (newOrder: Order) => {
    if (newOrder.epic === this.instrumentEpic) {
      this.existingOrders.push(newOrder);
      this.orderAdapter.subscribeToLiveSizeUpdates(
        [newOrder],
        this.orderChangeCallback,
      );
      this.orderChangeCallback();
    }
  };

  private readonly handleOnRemovedOrder = (closedOrderDealId: string) => {
    const index = this.existingOrders.findIndex(
      (order) => order.dealId === closedOrderDealId,
    );
    if (index !== -1) {
      const orderRemoved = this.existingOrders.splice(index, 1).pop() as Order;
      this.orderAdapter.removeSizeUpdateSubscription(orderRemoved);
      this.orderChangeCallback();
    }
  };

  private readonly handleOnChangedOrder = () => {
    this.orderChangeCallback();
  };

  getSizeOfExistingSellOrders() {
    let sizeOfTotalSellOrders = 0;
    this.existingOrders.forEach((order) => {
      if (order.direction === DirectionSymbol.SELL) {
        sizeOfTotalSellOrders += order.dealSize;
      }
    });
    return sizeOfTotalSellOrders;
  }

  stopMonitoringOrders() {
    this.orderAdapter.stopListeningForUpdates();
  }
}
