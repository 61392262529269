import styled from "styled-components";
import { GlobalElementsStyle } from "../../theme/global-elements-style";

import { type ETPtheme } from "../../theme/etp-theme";

export const RatingFormStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    color: ${({ $ETPtheme }) => $ETPtheme.feedbackForm.text.title};
    font-weight: 500;
  }

  .etp-quick-deal__rating-form__buttons-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
`;
