import styled from "styled-components";
import { GlobalElementsStyle } from "../../../theme/global-elements-style";
import { type ETPtheme } from "../../../theme/etp-theme";
import { Direction } from "../../../types/directions";

export const PreviewTicketStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
  $direction: string;
}>`
  width: 233px;
  padding: 12px;
  border-radius: 2px;
  background: ${({ $ETPtheme }) => $ETPtheme.previewTicket.background.default};
  border: ${({ $ETPtheme }) => $ETPtheme.previewTicket.border};
  color: ${({ $ETPtheme }) => $ETPtheme.previewTicket.text.default};

  .ticket__header {
    h3 {
      margin: 0 0 12px 0;
    }

    p.header__direction {
      display: inline-block;
      font-weight: 600;
      line-height: 1;
      border-radius: 2px;
      padding: 3px 4px 2px 4px;
      margin: 0 4px 0 0;
      color: ${({ $ETPtheme }) => $ETPtheme.previewTicket.text.direction};
      background: ${({ $ETPtheme, $direction }) =>
        $direction === Direction.BUY
          ? $ETPtheme.previewTicket.background.direction.buy
          : $ETPtheme.previewTicket.background.direction.sell};
    }

    p.header__instrument-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .ticket__section {
    margin: 12px 0;

    dd {
      font-weight: 600;
      color: ${({ $ETPtheme }) => $ETPtheme.previewTicket.text.strong};
    }
  }

  dt#etp-quick-deal-profit-and-loss-value {
    font-weight: 600;
  }

  .ticket__section--checkbox {
    margin: 16px 0 4px;
    display: flex;
    align-items: center;

    input {
      width: 14px;
      display: block;
    }

    label {
      padding-left: 7px;
      margin-bottom: 0;
      font-weight: 500;
      color: ${({ $ETPtheme }) => $ETPtheme.previewTicket.text.strong};
    }
  }

  footer {
    text-align: center;
  }
`;
