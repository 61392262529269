import styled from "styled-components";
import { GlobalElementsStyle } from "../../../theme/global-elements-style";
import { type ETPtheme } from "../../../theme/etp-theme";
export const CostsAndChargesDetailsContainer = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  background-color: ${({ $ETPtheme }) =>
    $ETPtheme.costsAndCharges.background.details.body};
  line-height: 16px;
  .header {
    padding: 5px 8px 7px;
    text-align: center;
    background-color: ${({ $ETPtheme }) =>
      $ETPtheme.costsAndCharges.background.details.header};
    color: ${({ $ETPtheme }) => $ETPtheme.costsAndCharges.text.details.title};
  }

  .body {
    position: relative;
    padding: 32px;
    color: ${({ $ETPtheme }) => $ETPtheme.costsAndCharges.text.details.text};
    font-size: 13px;
    font-weight: 400;

    h2 {
      font-size: 24px;
      font-weight: 600;
      margin: 0;
      padding: 2px 22px 30px 0;
      line-height: 24px;
      text-align: center;
      color: ${({ $ETPtheme }) => $ETPtheme.costsAndCharges.text.details.title};
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
      padding: 2px 22px 28px 0;
      line-height: 24px;
      color: ${({ $ETPtheme }) => $ETPtheme.costsAndCharges.text.details.title};
    }

    h4 {
      font-size: 11px;
      line-height: 16px;
      color: ${({ $ETPtheme }) => $ETPtheme.costsAndCharges.text.details.text};
    }

    .instrument-name {
      margin: 28px 0 14px;
      font-weight: 600;
      color: ${({ $ETPtheme }) => $ETPtheme.costsAndCharges.text.details.title};
    }

    section {
      margin-bottom: 28px;
      padding-bottom: 28px;
      border-bottom: ${({ $ETPtheme }) => $ETPtheme.costsAndCharges.border};

      dl {
        flex: 1;
        grid-template-columns: 3fr 1fr;
        color: ${({ $ETPtheme }) =>
          $ETPtheme.costsAndCharges.text.details.title};
        font-weight: 600;
      }

      ul {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        gap: 9px;
        padding: 0;
        margin: 0;

        li {
          &.expanded {
            button {
              background: ${({ $ETPtheme }) =>
                $ETPtheme.costsAndCharges.background.details.button};
            }

            .expandable {
              max-height: 250px;
              padding-bottom: 10px;
              background-color: ${({ $ETPtheme }) =>
                $ETPtheme.costsAndCharges.background.details.expandableSection};
            }
          }

          button {
            display: flex;
            background: inherit;
            color: inherit;
            border: none;
            padding: 3px 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            width: 100%;
            transition: background 0.3s;
            gap: 10px;

            :hover {
              background: ${({ $ETPtheme }) =>
                $ETPtheme.costsAndCharges.background.details.button};
            }

            span {
              position: relative;
              font-size: 12px;
              font-weight: 600;
              width: 12px;
              height: 12px;
              line-height: 9px;
              margin: auto auto auto 2px;
              padding: 0.5px;
              border-radius: 2px;
              background-color: ${({ $ETPtheme }) =>
                $ETPtheme.costsAndCharges.background.details.expandableIcon};
              color: ${({ $ETPtheme }) =>
                $ETPtheme.costsAndCharges.text.details.title};

              svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }

          .expandable {
            padding-left: 22px;
            max-height: 0;
            overflow: hidden;
            transition:
              max-height 0.3s,
              background-color 0.3s;

            p {
              margin-top: 9px;
              font-size: 11px;
            }

            h3 {
              padding: 9px 0 0 0;
              font-size: 11px;
              font-weight: 400;
              line-height: 16px;
              color: ${({ $ETPtheme }) =>
                $ETPtheme.costsAndCharges.text.details.text};
            }

            dl {
              margin-top: 9px;
              grid-row-gap: 9px;
              color: ${({ $ETPtheme }) =>
                $ETPtheme.costsAndCharges.text.details.title};
              font-weight: 500;
            }
          }
        }
      }

      &.total {
        h3 {
          padding: 0;
          margin-bottom: 10px;
          line-height: 16px;
        }

        dd:first-of-type {
          font-size: 18px;
          font-weight: 600;
          color: ${({ $ETPtheme }) =>
            $ETPtheme.costsAndCharges.text.details.title};
        }

        dt:nth-of-type(2) {
          width: calc(77% - 12px);
          font-weight: 400;
          color: ${({ $ETPtheme }) =>
            $ETPtheme.costsAndCharges.text.details.text};
        }

        dd:nth-of-type(2) {
          font-weight: 400;
          color: ${({ $ETPtheme }) =>
            $ETPtheme.costsAndCharges.text.details.text};
        }
      }

      &.inducements {
        h3 {
          font-size: 13px;
          font-weight: 600;
          margin-bottom: 6px;
          line-height: 16px;
          padding: 0;
        }
      }

      &.download-costs-and-charges {
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        text-align: center;
        font-size: 11px;
        padding-bottom: 0;
        border-bottom: 0;
        margin-bottom: 0;

        button {
          padding: 4px 16px 5px;
          margin-bottom: 7px;
        }
      }
    }
  }
`;
