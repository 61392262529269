import {
  SUCCESSFUL_RESULT,
  type TicketValidator,
  ValidationError,
  ValidationErrors,
  ValidationResult,
} from "../../types/ticket-validation";
import { type Ticket } from "../../types/ticket";

export default class SizeNotZeroValidator implements TicketValidator {
  validate(ticket: Ticket): ValidationResult {
    return ticket.size > 0
      ? SUCCESSFUL_RESULT
      : new ValidationResult(
          new ValidationError(
            ValidationErrors.SIZE_INPUT_WARNING_ZERO,
            "SIZE_NOT_ABOVE_ZERO",
          ),
        );
  }
}
