export class CallIsThrottledError extends Error {
  constructor() {
    super("Call rejected due to throttling");
  }
}

export class Throttler {
  private readonly delayInMillis: number;
  private timer: NodeJS.Timeout | null;
  private lastRequestMadeAt: number;
  private lastRequestRejectionCallback: (reason?: any) => void;

  constructor(delayInMillis: number) {
    this.timer = null;
    this.delayInMillis = delayInMillis;
    this.lastRequestMadeAt = 0;
    this.lastRequestRejectionCallback = () => {};
  }

  private _computeDelayInMillis(): number {
    const timeRemaining =
      this.delayInMillis - (Date.now() - this.lastRequestMadeAt);
    return timeRemaining >= 0 ? timeRemaining : 0;
  }

  async throttle<T>(call: () => Promise<T>): Promise<T> {
    const effectiveDelayInMillis = this._computeDelayInMillis();
    const useDelay = effectiveDelayInMillis > 0;
    if (this.timer != null) {
      clearTimeout(this.timer);
    }
    this.lastRequestRejectionCallback(new CallIsThrottledError());
    let promiseResolve: any;
    let promiseReject: any;
    const asyncResult = new Promise<T>((resolve, reject) => {
      promiseResolve = resolve;
      promiseReject = reject;
    });
    this.lastRequestRejectionCallback = promiseReject;
    if (useDelay) {
      this.timer = setTimeout(() => {
        this.doCall(call, promiseReject, promiseResolve);
      }, effectiveDelayInMillis);
    } else {
      this.lastRequestMadeAt = Date.now();
      this.doCall(call, promiseReject, promiseResolve);
    }
    return await asyncResult;
  }

  private doCall<T>(
    call: () => Promise<T>,
    promiseReject: any,
    promiseResolve: any,
  ) {
    void call()
      .then((value) => {
        if (this.lastRequestRejectionCallback === promiseReject) {
          promiseResolve(value);
        } else {
          promiseReject(new CallIsThrottledError());
        }
      })
      .catch((reason) => promiseReject(reason));
  }
}
