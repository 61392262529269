import { GlobalElementsStyle } from "../../theme/global-elements-style";
import { type ETPtheme } from "../../theme/etp-theme";
import styled from "styled-components";
import { SpinnerStyles } from "../icons/Spinner";

export const FeedbackInputFormStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
  $IsError: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    margin-bottom: 0;
    color: ${({ $ETPtheme }) => $ETPtheme.feedbackForm.feedbackInputForm.label};
  }

  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

    textarea#feedback-input-form-box {
      height: 64px;
      width: 100%;
      resize: none;
      outline: ${({ $ETPtheme, $IsError }) =>
        $IsError
          ? $ETPtheme.feedbackForm.feedbackInputForm.textarea.outline.error
          : "transparent"};
      outline-offset: -1px;
      border: ${({ $ETPtheme }) =>
        $ETPtheme.feedbackForm.feedbackInputForm.textarea.border.default};
      border-radius: 2px;
      padding: 3px 4px;
      margin-bottom: 1px;
      background-color: ${({ $ETPtheme }) =>
        $ETPtheme.feedbackForm.feedbackInputForm.textarea.background};
      color: ${({ $ETPtheme }) =>
        $ETPtheme.feedbackForm.feedbackInputForm.textarea.text.userInput};

      &:focus {
        border: ${({ $ETPtheme }) =>
          $ETPtheme.feedbackForm.feedbackInputForm.textarea.border.focus};
      }

      &::placeholder {
        color: ${({ $ETPtheme }) =>
          $ETPtheme.feedbackForm.feedbackInputForm.textarea.text.placeholder};
      }
    }

    p {
      font-size: 10px;
      line-height: 10px;
      margin-bottom: -10px;
      color: ${({ $ETPtheme }) =>
        $ETPtheme.feedbackForm.feedbackInputForm.textarea.text.error};
      text-align: right;
    }
  }

  section.etp-quick-deal__feedback-form-input__checkbox {
    display: flex;
    gap: 4px;

    input[type="checkbox"] {
      display: block;
      height: 16px;
      width: 16px;
    }

    label {
      flex: 1;
      color: ${({ $ETPtheme }) =>
        $ETPtheme.feedbackForm.feedbackInputForm.checkbox.text};
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
    }
  }

  button.etp-quick-deal__feedback-form__large-button {
    ${SpinnerStyles} {
      position: absolute;
      svg {
        path {
          fill: ${({ $ETPtheme }) =>
            $ETPtheme.icon.spinner.path.fill.secondary};
        }
      }
    }
  }
`;
