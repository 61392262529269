import React from "react";
import { TurboCalculatorStyles } from "./TurboCalculatorStyles";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { useTranslation } from "react-i18next";
import Input from "../generics/input/Input";
import { useInstrumentData } from "../../hooks/use-instrument-data";

interface TurboCalculatorProps {
  onChange: (underlyingValue: number | null) => void;
  onFocus: () => void;
  onBlur: () => void;
  underlyingInputValue: number | null;
}

const TurboCalculator = ({
  onChange,
  onFocus,
  onBlur,
  underlyingInputValue,
}: TurboCalculatorProps) => {
  const { t } = useTranslation();
  const { instrumentDataUnderlying } = useInstrumentData();
  const ETPtheme = useETPTheme();

  return (
    <TurboCalculatorStyles
      onFocus={onFocus}
      onBlur={onBlur}
      className="etp-quick-deal_stop-limit-rows__calculator"
      data-testid="etp-quick-deal_turbo-calculator"
      $ETPtheme={ETPtheme}
    >
      <Input
        container={{
          id: "etp-quick-deal_calculator__container",
        }}
        numericInput={{
          id: "etp-quick-deal_calculator-input",
          "data-testid": "calculator-input",
          label: t("order-tab.estimated-underlying"),
          hasError: false,
          handleValidationErrors: () => {},
          value: underlyingInputValue?.toString() ?? "",
          setValue: onChange,
          max: 9_999_999,
          min: 0,
          step: 1 / Math.pow(10, instrumentDataUnderlying.decimalPlacesFactor),
          showArrows: false,
          state: "active",
          placeholder: "0",
        }}
      />
    </TurboCalculatorStyles>
  );
};

export default TurboCalculator;
