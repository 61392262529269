import { useTranslation } from "react-i18next";
import { underlyingExposureCalculator } from "../../utils/underlying-exposure-calculator";
import { convertLocalToCurrencySymbol } from "../../utils/currency-local-format";
import { useSession } from "../../hooks/use-session";
import { type ETPtheme } from "../../theme/etp-theme";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { UnderlyingExposureStyle } from "./UnderlyingExposureStyles";
import { resolveInstrumentTypeByString } from "../../utils/resolve-instrument-type";
import { InstrumentType } from "../../types/instrument-type";

export interface UnderlyingExposureProps {
  ticketSize: number;
  scalingFactor: number;
  multiplier: number | undefined; // This component only renders for turbos - turbos are guaranteed to have multiplier fields. CL, CW have undefined for multipliers
  isShareUnderlying: boolean | undefined;
  productType: string;
  ticketDefaultCurrency: string;
}

const UnderlyingExposure = ({
  ticketSize,
  scalingFactor,
  multiplier,
  isShareUnderlying,
  productType,
  ticketDefaultCurrency,
}: UnderlyingExposureProps) => {
  const { t } = useTranslation();
  const session = useSession();
  const ETPtheme: ETPtheme = useETPTheme();
  const instrumentType = resolveInstrumentTypeByString(productType);

  if (
    ticketSize === 0 ||
    scalingFactor === 0 ||
    isShareUnderlying === undefined ||
    multiplier === undefined ||
    instrumentType !== InstrumentType.TURBO
  )
    return null;

  const calculatedMarketPoint = underlyingExposureCalculator(
    ticketSize,
    multiplier,
    scalingFactor,
    isShareUnderlying,
  );
  return isShareUnderlying
    ? renderContainer(
        t("equal-to-trading-shares", {
          numberOfShares: new Intl.NumberFormat(
            session.sessionData.clientLocale,
          ).format(calculatedMarketPoint),
        }),
        ETPtheme,
      )
    : renderContainer(
        `${convertLocalToCurrencySymbol(
          calculatedMarketPoint,
          session.sessionData.clientLocale,
          ticketDefaultCurrency,
        )} ${t("per-reference-market-point")}`,
        ETPtheme,
      );
};
function renderContainer(exposureInformation: string, theme: ETPtheme) {
  return (
    <UnderlyingExposureStyle
      $ETPtheme={theme}
      data-testid="underlying-exposure-container"
    >
      <p>{exposureInformation}</p>
    </UnderlyingExposureStyle>
  );
}

export default UnderlyingExposure;
