import { type GaTracer } from "./ga-tracer";
import { type Ticket } from "../types/ticket";
import { type TransactionStatus } from "../types/transaction-status";
import { type GaEventPayload } from "./ga-event-types";
import { GaEvents } from "./ga-events";
import {
  mapDealingInteraction,
  mapDealResult,
  mapInstrumentInteraction,
} from "./ga-mapping";
import { type Prices } from "../types/prices";
import { type OrderTypeTab } from "../types/order-type-tab";

export class DefaultGaTracer implements GaTracer {
  private readonly trackEvent: (
    gaEventName: string,
    gaEventData: GaEventPayload,
  ) => void;

  constructor(
    trackEvent: (gaEventName: string, gaEventData: GaEventPayload) => void,
  ) {
    this.trackEvent = trackEvent;
  }

  trackDealResult(ticket: Ticket, transactionStatus: TransactionStatus): void {
    this.trackEvent(
      GaEvents.DEAL_RESULT,
      mapDealResult(ticket, transactionStatus),
    );
  }

  trackDealingInteraction(ticket: Ticket, prices: Prices | undefined): void {
    this.trackEvent(
      GaEvents.DEALING_INTERACTION,
      mapDealingInteraction(ticket, prices),
    );
  }

  trackOrderTabTypeInteraction(
    instrumentName: string,
    instrumentEpic: string,
    interactionSubtype: OrderTypeTab,
  ): void {
    this.trackEvent(
      GaEvents.INSTRUMENT_INTERACTION,
      mapInstrumentInteraction(instrumentName, instrumentEpic, {
        eventAction: "Quick deal order type tab impression",
        interactionType: "Quick deal order type tab impression",
        interactionSubtype,
      }),
    );
  }

  trackQuickDealImpression(
    instrumentName: string,
    instrumentEpic: string,
  ): void {
    this.trackEvent(
      GaEvents.INSTRUMENT_INTERACTION,
      mapInstrumentInteraction(instrumentName, instrumentEpic, {
        eventAction: "Quick deal tab impression",
        interactionType: "Quick deal tab impression",
      }),
    );
  }
}
