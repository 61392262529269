import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FeedbackInputFormStyles } from "./FeedbackInputFormStyles";
import { useETPTheme } from "../../hooks/use-etp-theme";
import Spinner from "../icons/Spinner";

export enum FeedbackInputFormLabel {
  INITIAL_HAS_LIKED = "feedback-form.comment-stage.title-like",
  INITIAL_HAS_DISLIKED = "feedback-form.comment-stage.title-dislike",
  FOLLOW_UP = "feedback-form.comment-stage.title-follow-up",
}

interface FeedbackInputProps {
  title: FeedbackInputFormLabel;
  canBeContacted: boolean;
  submitFeedback: (
    canBeContacted: boolean,
    question: string,
    comment: string,
  ) => void;
  isInProgress: boolean;
}

const FeedbackInputForm = ({
  title,
  canBeContacted,
  submitFeedback,
  isInProgress,
}: FeedbackInputProps) => {
  const { t } = useTranslation();
  const etpTheme = useETPTheme();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [isError, setError] = useState<boolean>(false);

  const handleInputChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const text = (e.target as HTMLTextAreaElement).value;
    setError(false);
    if (text.length === 1500 || text.length > 1500) {
      setError(true);
    }
  };
  return (
    <FeedbackInputFormStyles
      data-testid="etp-quick-deal__feedback-input-form"
      $ETPtheme={etpTheme}
      $IsError={isError}
    >
      <label htmlFor="feedback-input-form-box">{t(title)}</label>
      <div>
        <textarea
          ref={inputRef}
          id="feedback-input-form-box"
          name="feedback-input-form"
          onChange={handleInputChange}
          maxLength={1500}
          placeholder={t("feedback-form.comment-stage.input-box-placeholder")}
        />
        {isError && (
          <p>{t("feedback-form.comment-stage.input-box-limit-error")}</p>
        )}
      </div>
      {!canBeContacted && (
        <section className="etp-quick-deal__feedback-form-input__checkbox">
          <input
            type="checkbox"
            ref={checkboxRef}
            id="etp-quick-deal__contact-permission-checkbox"
          ></input>
          <label htmlFor="etp-quick-deal__contact-permission-checkbox">
            {t("feedback-form.comment-stage.contact-permission-checkbox")}
          </label>
        </section>
      )}
      <button
        type="button"
        className="etp-quick-deal__feedback-form__large-button"
        disabled={isInProgress}
        onClick={() => {
          submitFeedback(
            (checkboxRef.current?.checked as boolean) ?? false,
            t(title),
            inputRef.current?.value as string,
          );
        }}
      >
        {isInProgress ? (
          <>
            <Spinner />
            {t("feedback-form.comment-stage.button-loading")}
          </>
        ) : (
          t("feedback-form.comment-stage.button")
        )}
      </button>
    </FeedbackInputFormStyles>
  );
};

export default FeedbackInputForm;
