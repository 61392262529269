import { useEffect, useState } from "react";
import { useSession } from "../../hooks/use-session";
import { useTranslation } from "react-i18next";
import { resolveInstrumentTypeByString } from "../../utils/resolve-instrument-type";
import { type Session } from "../../types/session";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { type ETPtheme } from "../../theme/etp-theme";
import { ViewKidStyles } from "./ViewKidStyles";
import { wtpGet } from "../../adapters/wtp-fetch-adapter";

interface ViewKidProps {
  instrumentType: string;
  instrumentIsin: string;
}

const fetchKidDocumentLink = async (
  instrumentType: string,
  instrumentIsin: string,
  session: Session,
) => {
  const resolvedInstrumentType = resolveInstrumentTypeByString(instrumentType);
  const url = `/usermanagement/kid/documentLink/?isin=${instrumentIsin}&instrumentType=${resolvedInstrumentType}&_=${Date.now()}`;

  const response = await wtpGet(url, session);
  return response.status === 200
    ? await getDocumentLinkFromBody(response)
    : undefined;
};

const getDocumentLinkFromBody = async (response: Response) => {
  const { documentLink } = await response.json();
  return documentLink;
};

const ViewKid = ({ instrumentType, instrumentIsin }: ViewKidProps) => {
  const ETPtheme: ETPtheme = useETPTheme();
  const { t } = useTranslation();
  const [kidURL, setKidURL] = useState<string>();
  const session = useSession();

  useEffect(() => {
    fetchKidDocumentLink(instrumentType, instrumentIsin, session)
      .then((documentLink) => {
        setKidURL(documentLink);
      })
      .catch(console.error);
  }, [instrumentType, instrumentIsin, session]);

  if (kidURL === undefined) return <></>;

  return (
    <ViewKidStyles $ETPtheme={ETPtheme}>
      <a
        href={kidURL}
        target="_blank"
        rel="noopener noreferrer"
        className="etp-quick-deal_external-link etp-quick-deal_external-link--with-icon"
      >
        {t("view-key-information-document")}
      </a>
    </ViewKidStyles>
  );
};

export default ViewKid;
