import { useTranslation } from "react-i18next";

import { useState } from "react";

import Modal from "react-modal";

import CostsAndChargesDetails from "./CostsAndChargesDetails";
import { type Ticket } from "../../../types/ticket";
import { useETPTheme } from "../../../hooks/use-etp-theme";
import { useSession } from "../../../hooks/use-session";
import { useSubscription } from "../../../hooks/use-subscription";
import useCostsAndCharges, {
  TicketStatus,
} from "../../../hooks/use-costs-and-charges";
import { Direction } from "../../../types/directions";
import { convertLocalToCurrencySymbol } from "../../../utils/currency-local-format";
import { resolveInstrumentTypeByString } from "../../../utils/resolve-instrument-type";
import { type ETPtheme } from "../../../theme/etp-theme";
import {
  CostsAndChargesContainer,
  getModalStyle,
} from "./CostsAndChargesStyles";

interface CostsAndChargesProps {
  ticket: Ticket;
  positionOpeningLevel: number | undefined;
}

const CostsAndCharges = ({
  ticket,
  positionOpeningLevel = 0,
}: CostsAndChargesProps) => {
  const ETPtheme: ETPtheme = useETPTheme();
  const { t } = useTranslation();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const session = useSession();
  const pricingData = useSubscription();
  const costsAndChargesData = useCostsAndCharges(
    ticket,
    positionOpeningLevel,
    pricingData.bid,
    pricingData.offer,
  );
  const directionInfo =
    ticket.direction === Direction.BUY
      ? TicketStatus.OPENING
      : TicketStatus.CLOSING;

  let currencyCode: string | undefined;
  let totalCostsAndCharges: number | undefined;

  if (costsAndChargesData !== undefined) {
    ({ currencyCodeISO: currencyCode } = costsAndChargesData);
    if (costsAndChargesData.costsAndChargesType === "opening") {
      totalCostsAndCharges = costsAndChargesData.totalOpening;
    } else {
      totalCostsAndCharges = costsAndChargesData.totalClosing;
    }
  }

  const onCloseHandler = () => {
    setIsModalOpened(false);
  };

  if (session.sessionData.igCompany === "igch") return null;
  return (
    <CostsAndChargesContainer
      data-testid="costs-and-charges_container"
      $ETPtheme={ETPtheme}
    >
      <dl data-testid={`costs-and-charges_${directionInfo}-cost`}>
        <dt
          id={`costs-and-charges_${directionInfo}-cost--label`}
          data-testid={`costs-and-charges_${directionInfo}-cost--label`}
          className="costs-and-charges__cost--label"
        >
          {t(`costs-and-charges.${directionInfo}`)}
        </dt>
        <dd
          aria-labelledby={`costs-and-charges_${directionInfo}-cost--label`}
          data-testid={`costs-and-charges_${directionInfo}-cost--value`}
          className="costs-and-charges__cost--value"
        >
          {convertLocalToCurrencySymbol(
            totalCostsAndCharges,
            session.sessionData.clientLocale,
            currencyCode,
          )}
        </dd>
      </dl>
      <div>
        <button
          type="button"
          onClick={() => {
            setIsModalOpened(true);
          }}
          disabled={costsAndChargesData === undefined}
          className="etp-quick-deal_external-link"
        >
          {t("costs-and-charges.view-full-cost-details")}
        </button>
      </div>
      <Modal
        style={getModalStyle(ETPtheme)}
        isOpen={isModalOpened}
        onRequestClose={onCloseHandler}
        ariaHideApp={false} // TODO: Get root element of the platform (see https://reactcommunity.org/react-modal/accessibility/#the-app-element)
      >
        {costsAndChargesData != null && (
          <CostsAndChargesDetails
            instrumentName={ticket.instrument.instrumentName}
            instrumentType={resolveInstrumentTypeByString(
              ticket.instrument.instrumentType,
            )}
            costsAndChargesData={costsAndChargesData}
            onClose={onCloseHandler}
          />
        )}
      </Modal>
    </CostsAndChargesContainer>
  );
};

export default CostsAndCharges;
