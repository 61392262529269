import { GlobalElementsStyle } from "../../theme/global-elements-style";
import { type ETPtheme } from "../../theme/etp-theme";
import styled from "styled-components";
import { CloseContainer } from "../icons/CloseIcon";

export const FeedbackFormStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  position: relative;
  background-color: ${({ $ETPtheme }) => $ETPtheme.feedbackForm.background};
  padding: 24px 12px 12px 12px;
  border-radius: 8px;
  border: ${({ $ETPtheme }) => $ETPtheme.feedbackForm.border};
  margin-bottom: 10px;

  ${CloseContainer} {
    top: 0;
    height: 10px;
  }

  h2 {
    font-size: 11px;
    font-weight: 500;
    line-height: 1.4;
    color: ${({ $ETPtheme }) => $ETPtheme.feedbackForm.feedbackInputForm.label};
    margin-bottom: 4px;
  }

  p {
    font-size: 11px;
    font-weight: 400;
    line-height: 1.4;
    color: #5a5e5f;
  }

  button.etp-quick-deal__feedback-form__large-button {
    width: 100%;
    border: ${({ $ETPtheme }) =>
      $ETPtheme.feedbackForm.feedbackInputForm.submitButton.border};
    border-radius: 4px;
    background-color: ${({ $ETPtheme }) =>
      $ETPtheme.feedbackForm.feedbackInputForm.submitButton.background.default};
    font-weight: 500;
    color: ${({ $ETPtheme }) =>
      $ETPtheme.feedbackForm.feedbackInputForm.submitButton.text};
    box-shadow: ${({ $ETPtheme }) =>
      $ETPtheme.feedbackForm.feedbackInputForm.submitButton.boxShadow};
    &:hover {
      background-color: ${({ $ETPtheme }) =>
        $ETPtheme.feedbackForm.feedbackInputForm.submitButton.background.hover};
    }
  }
`;
