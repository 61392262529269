import { type Ticket } from "../../types/ticket";
import { Direction } from "../../types/directions";
import { type ExistingPositionProvider } from "../../types/existing-position-provider";
import { type ExistingOrdersProvider } from "../../types/existing-orders-provider";
import {
  SizeNotAboveMaxValidationError,
  SUCCESSFUL_RESULT,
  type TicketValidator,
  type SizeDetails,
  ValidationErrors,
  ValidationResult,
} from "../../types/ticket-validation";

interface ValidationResultInformation {
  isValid: boolean;
  key: ValidationErrors | null;
  sizeDetails: SizeDetails;
}

export default class SizeNotAboveMaxValidator implements TicketValidator {
  private readonly _existingOrdersProvider: ExistingOrdersProvider;
  private readonly _existingPositionProvider: ExistingPositionProvider;
  constructor(
    existingOrdersProvider: ExistingOrdersProvider,
    existingPositionProvider: ExistingPositionProvider,
  ) {
    this._existingOrdersProvider = existingOrdersProvider;
    this._existingPositionProvider = existingPositionProvider;
  }

  private _isValid(ticketState: Ticket): ValidationResultInformation {
    const sellOrderSize =
      this._existingOrdersProvider.getSizeOfExistingSellOrders();
    const existingPosition =
      this._existingPositionProvider.getExistingPosition();
    const existingPositionDealSize = existingPosition?.dealSize ?? 0;

    const validationResult: ValidationResultInformation = {
      isValid: true,
      key: null,
      sizeDetails: { existingPositionDealSize, sellOrderSize },
    };

    if (existingPosition === null || ticketState.direction === Direction.BUY) {
      return validationResult;
    }

    if (ticketState.size > existingPositionDealSize) {
      validationResult.isValid = false;
      validationResult.key = ValidationErrors.SIZE_INPUT_WARNING_ABOVE_MAX;
      return validationResult;
    }

    if (ticketState.size > existingPositionDealSize - sellOrderSize) {
      validationResult.isValid = false;
      validationResult.key =
        ValidationErrors.SIZE_INPUT_WARNING_EXISTING_WORKING_ORDERS;
      return validationResult;
    }

    return validationResult;
  }

  validate(ticket: Ticket): ValidationResult {
    const validationResult = this._isValid(ticket);
    return validationResult.isValid
      ? SUCCESSFUL_RESULT
      : new ValidationResult(
          new SizeNotAboveMaxValidationError(
            validationResult.key as ValidationErrors,
            "SIZE_ABOVE_MAX",
            validationResult.sizeDetails,
          ),
        );
  }
}
