import {
  SUCCESSFUL_RESULT,
  type TicketValidator,
  ValidationError,
  ValidationErrors,
  ValidationResult,
} from "../../types/ticket-validation";
import { type Ticket } from "../../types/ticket";
import { OrderType } from "../../types/ig-trading-order-ticket";
import { type Prices } from "../../types/prices";

export default class StopNotAboveCurrentBidValidator
  implements TicketValidator
{
  validate(ticket: Ticket, prices: Prices): ValidationResult {
    if (
      ticket.orderType === OrderType.STOP_MARKET &&
      ticket.stopPrice !== undefined &&
      prices.bid !== null &&
      ticket.stopPrice >= prices.bid
    ) {
      return new ValidationResult(
        new ValidationError(
          ValidationErrors.STOP_INPUT_LEVEL_ABOVE_BID,
          "STOP_LEVEL_SELL_ABOVE_BID",
        ),
      );
    }

    return SUCCESSFUL_RESULT;
  }
}
