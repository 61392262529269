import { type Adapter } from "./adapter";
import { type PositionAdapter } from "./position-adapter";
import { type OrderAdapter } from "./order-adapter";
import type CostsAndChargesAdapter from "./costs-and-charges-adapter";

export class AdaptersHolder {
  adapter: Adapter;
  positionAdapter: PositionAdapter;
  orderAdapter: OrderAdapter;
  costsAndChargesAdapter: CostsAndChargesAdapter;

  constructor(
    adapter: Adapter,
    positionAdapter: PositionAdapter,
    orderAdapter: OrderAdapter,
    costsAndChargesAdapter: CostsAndChargesAdapter,
  ) {
    this.adapter = adapter;
    this.positionAdapter = positionAdapter;
    this.orderAdapter = orderAdapter;
    this.costsAndChargesAdapter = costsAndChargesAdapter;
  }
}
