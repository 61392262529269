import styled from "styled-components";
import { GlobalElementsStyle } from "../../theme/global-elements-style";
import { type ETPtheme } from "../../theme/etp-theme";

export const ProfitAndLossContainer = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  dl {
    color: ${({ $ETPtheme }) => $ETPtheme.profitAndLoss.text.neutral};
    grid-template-areas:
      "a b"
      "c d"; // "c" doesn't exist therefore "d" will be placed at the bottom right

    dt {
      grid-area: a;
    }

    dd {
      &.ticket-currency {
        grid-area: b;
      }

      &.local-currency {
        grid-area: d;
      }

      &.profit-and-loss {
        &__profit {
          font-weight: 500;
          color: ${({ $ETPtheme }) => $ETPtheme.profitAndLoss.text.profit};
        }
        &__loss {
          font-weight: 500;
          color: ${({ $ETPtheme }) => $ETPtheme.profitAndLoss.text.loss};
        }
      }
    }
  }
`;
