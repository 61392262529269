import { type Session } from "../../types/session";

export const igApiHeadersProvider = (session: Session) => {
  return {
    "Content-Type": "application/json",
    cst: session.cst,
    "X-Security-Token": session.xst,
    "ig-account-id": session.sessionData.currentAccountId,
    "x-device-user-agent": `vendor=IG Group | applicationType=ig | platform=WTP | version=${session.environment.version} | component=@ig-etp/quick-deal | componentVersion=${process.env.APPLICATION_VERSION}`,
  };
};
