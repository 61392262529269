import { type MutableRefObject, useEffect } from "react";

export const useClickOutside = (
  elementRef: MutableRefObject<HTMLDivElement | null>,
  clickHandler: () => void,
) => {
  useEffect(() => {
    if (elementRef !== null) {
      const clickOutsideHandler = (event: Event) => {
        const hasClickedOutside =
          elementRef.current?.contains(event.target as Node) === false;

        if (hasClickedOutside) {
          clickHandler();
        }
      };

      document.addEventListener("mouseup", clickOutsideHandler);

      return () => {
        document.removeEventListener("mouseup", clickOutsideHandler);
      };
    }
  }, [clickHandler, elementRef]);
};
