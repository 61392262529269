import React from "react";
import { type Ticket } from "../../types/ticket";
import { Direction } from "../../types/directions";
import { useTranslation } from "react-i18next";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { type ETPtheme } from "../../theme/etp-theme";
import { priceWithTolerance } from "../../utils/price-with-tolerance";
import { convertLocalToCurrencySymbol } from "../../utils/currency-local-format";
import { useSession } from "../../hooks/use-session";
import { exchangeToAccountCurrency } from "../../services/currency-exchange-calculator";
import { useSubscription } from "../../hooks/use-subscription";
import { ConsiderationStyles } from "./ConsiderationStyles";
import { type Prices } from "../../types/prices";
import { OrderType } from "../../types/ig-trading-order-ticket";
import Tooltip, {
  PointerLocation,
  TooltipContent,
  xAxis,
  yAxis,
} from "../generics/tooltip/Tooltip";

interface ConsiderationProps {
  ticket: Ticket;
  isPreviewing?: boolean;
}

const getPriceToUse = (pricingData: Prices, ticket: Ticket) => {
  let priceToUse: number | null = null;
  switch (ticket.orderType) {
    case OrderType.MARKET:
      priceToUse =
        ticket.direction === Direction.BUY
          ? pricingData.offer
          : pricingData.bid;
      break;
    case OrderType.STOP_MARKET:
      priceToUse = ticket.stopPrice ?? null;
      break;
    case OrderType.LIMIT:
      priceToUse = ticket.triggerLevel ?? null;
      break;
  }
  if (priceToUse === null) {
    return NaN;
  }
  return priceToUse;
};

const Consideration = ({
  ticket,
  isPreviewing = false,
}: ConsiderationProps) => {
  const { t } = useTranslation();
  const { sessionData } = useSession();
  const ETPtheme: ETPtheme = useETPTheme();
  const pricingData = useSubscription();
  const isBuy = ticket.direction === Direction.BUY;
  const priceToUse = getPriceToUse(pricingData, ticket);

  const currentConsideration: number = priceToUse * ticket.size;

  const displayExchangeAmount =
    ticket.instrument.ticketDefaultCurrency.name !==
    sessionData.currencyIsoCode;

  const convertToLocalCurrency = (amount: number) => {
    return amount > 0
      ? convertLocalToCurrencySymbol(
          exchangeToAccountCurrency(
            amount,
            ticket.instrument.ticketDefaultCurrency.baseExchangeRate,
            ticket.instrument.dealingRules.currencyOffset,
            ticket.direction,
          ),
          sessionData.clientLocale,
          sessionData.currencyIsoCode,
        )
      : "-";
  };

  return (
    <ConsiderationStyles $isPreviewing={isPreviewing} $ETPtheme={ETPtheme}>
      <strong className="consideration__title">
        {/* TODO: platform has different tooltip for M1 vs M3. do we need it? */}
        <Tooltip
          attachment={{ y: yAxis.BOTTOM, x: xAxis.LEFT }}
          targetAttachment={{ y: yAxis.TOP, x: xAxis.LEFT }}
          offset={{ y: "7px", x: "0" }}
          content={
            <TooltipContent>
              <p>{t(`consideration.tooltip.${ticket.direction}`)}</p>
            </TooltipContent>
          }
          pointerLocation={PointerLocation.BOTTOM}
          pointerPosition={10}
        >
          <p>{t("consideration.title")}</p>
        </Tooltip>
      </strong>
      <div className="consideration__prices">
        {renderConsiderationAmount(
          currentConsideration,
          ticket.orderType === OrderType.MARKET
            ? "consideration.current-price"
            : undefined,
          "current",
        )}
        {ticket.orderType === OrderType.MARKET &&
          renderConsiderationAmount(
            priceWithTolerance(
              ticket.direction,
              priceToUse,
              ticket.priceTolerance,
            ) * ticket.size,
            `consideration.calculated-price.${isBuy ? "maximum" : "minimum"}`,
            "calculated",
          )}
      </div>
    </ConsiderationStyles>
  );

  function renderConsiderationAmount(
    consideration: number,
    labelName: string | undefined,
    status: string,
  ) {
    const idSuffix = isPreviewing ? "-preview" : "";
    return (
      <>
        <div className="consideration__prices-section">
          {labelName !== undefined && (
            <span className="consideration__prices-header">{t(labelName)}</span>
          )}
          <span
            className="consideration__prices-price"
            data-testid={`etp-quick-deal-consideration-${status}-header${idSuffix}`}
          >
            {consideration > 0
              ? convertLocalToCurrencySymbol(
                  consideration,
                  sessionData.clientLocale,
                  ticket.instrument.ticketDefaultCurrency.name,
                )
              : "-"}
          </span>
        </div>
        {displayExchangeAmount && (
          <div className="consideration__prices-section">
            <span
              className="consideration__prices-price"
              data-testid={`etp-quick-deal-consideration-${status}-header${idSuffix}`}
            >
              {convertToLocalCurrency(consideration)}
            </span>
          </div>
        )}
      </>
    );
  }
};

export default Consideration;
