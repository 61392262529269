import { Button } from "ig-phoenix";
import { useTranslation } from "react-i18next";
import { useETPTheme } from "../../../hooks/use-etp-theme";
import { type ETPtheme } from "../../../theme/etp-theme";
import { PreviewButtonStyles } from "./PreviewButtonStyles";
import useTradeableInstrumentChecker from "../../../hooks/use-tradeable-instrument-checker";
import WarningMessage from "../../messages/WarningMessage";
import { type Prices } from "../../../types/prices";
import { useSubscription } from "../../../hooks/use-subscription";

interface PreviewButtonProps {
  previewDeal: (prices: Prices) => void;
}
const PreviewButton = ({ previewDeal }: PreviewButtonProps) => {
  const { t } = useTranslation();
  const ETPtheme: ETPtheme = useETPTheme();
  const { isTradeable, reasonMessage } = useTradeableInstrumentChecker();
  const prices = useSubscription();

  return (
    <PreviewButtonStyles
      $ETPtheme={ETPtheme}
      className={`etp-quick-deal_order-button ${isTradeable ? "" : "unarmed"}`}
    >
      <Button
        data-testid="preview-button"
        variant={isTradeable ? "primary" : "unarmed"}
        onClick={(event) => {
          event.preventDefault();
          if (isTradeable) {
            previewDeal(prices);
          }
        }}
      >
        {t("preview-button")}
      </Button>
      {reasonMessage !== null && (
        <WarningMessage
          testId="order-button-instrument-not-tradeable"
          message={reasonMessage}
          topOffset={-3}
        ></WarningMessage>
      )}
    </PreviewButtonStyles>
  );
};

export default PreviewButton;
