import { Notification } from "ig-phoenix";
import {
  type CancelledDetails,
  type ConfirmDetails,
  type RejectionDetails,
  ReportType,
  type TransactionDetails,
  TransactionState,
} from "../../types/transaction-status";
import { useEffect, useState } from "react";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { type ETPtheme } from "../../theme/etp-theme";
import { useTranslation } from "react-i18next";
import { ConfirmationMessageStyles } from "./ConfirmationMessageStyles";
import { OrderType, TimeInForce } from "../../types/ig-trading-order-ticket";

interface ConfirmationMessageProps {
  state: TransactionState;
  details: TransactionDetails | CancelledDetails | null;
  closeMessage: () => void;
}

const displayableStates = [
  TransactionState.REJECTED,
  TransactionState.CONFIRMED,
  TransactionState.CANCELLED,
  TransactionState.FAILED,
  TransactionState.UNVERIFIED,
];

const ConfirmationMessage = ({
  state,
  details,
  closeMessage,
}: ConfirmationMessageProps) => {
  const displayableTransaction = displayableStates.includes(state);
  const [hovered, setHovered] = useState(false);
  const ETPtheme: ETPtheme = useETPTheme();
  const { t } = useTranslation();

  const confirmTimeInForce = (timeInForce: TimeInForce): string => {
    return timeInForce === TimeInForce.IMMEDIATE_OR_CANCEL ? "E&E" : "GTC";
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (displayableTransaction && !hovered) {
      timer = setTimeout(() => {
        !hovered && closeMessage();
      }, 4000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [hovered, closeMessage, displayableTransaction]);

  useEffect(() => {
    if (displayableTransaction) {
      document.addEventListener("click", closeMessage);
    }
    return () => {
      document.removeEventListener("click", closeMessage);
    };
  }, [closeMessage, displayableTransaction]);

  if (displayableTransaction) {
    return (
      <ConfirmationMessageStyles
        $ETPtheme={ETPtheme}
        $isConfirmed={state === TransactionState.CONFIRMED}
        $isAnOrder={details?.content.reportType === ReportType.ACKNOWLEDGE}
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
      >
        <Notification
          variant={
            state === TransactionState.CONFIRMED ? "affirmative" : "critical"
          }
          onCloseClick={() => {}} // the mouse click on document.EventListener("click") will close the notification
          data-testid="confirmation-message"
        >
          {state === TransactionState.CONFIRMED && (
            <ConfirmedContent {...(details as ConfirmDetails)} />
          )}
          {state === TransactionState.REJECTED && (
            <RejectedContent
              {...(details as RejectionDetails).content.reportData
                .rejectionMessage}
            />
          )}
          {state === TransactionState.CANCELLED && (
            <RejectedContent
              title={t("order-cancelled.title")}
              body={t("order-cancelled.message")}
            />
          )}
          {state === TransactionState.FAILED && (
            <RejectedContent
              title={t("order-failed.title")}
              body={t("order-failed.message")}
            />
          )}
          {state === TransactionState.UNVERIFIED && (
            <RejectedContent
              title={t("order-unverified.title")}
              body={t("order-unverified.message")}
            />
          )}
        </Notification>
      </ConfirmationMessageStyles>
    );
  } else {
    return <div data-testid="no-confirmation"></div>;
  }

  function ConfirmedContent(confirmDetails: ConfirmDetails) {
    const orderDetails = confirmDetails.content;
    const orderLevel =
      orderDetails.orderType === OrderType.STOP_MARKET
        ? t("market-price")
        : orderDetails.orderLevel;
    return (
      <>
        <h5 data-testid="confirmation-title">
          {t(`order-confirmation.${orderDetails.reportType}`).toUpperCase()}
        </h5>
        {orderDetails.orderType === OrderType.STOP_MARKET && (
          <div aria-label="stop-trigger">
            {t("stop-trigger")} {orderDetails.orderStopLevel}
          </div>
        )}
        <div data-testid="order-details">
          {orderDetails.reportType === "PARTIAL_FILL" && (
            <p data-testid="partial-filled-quantity">{orderDetails.quantity}</p>
          )}
          <p>{`${orderDetails.reportData.direction.value} 
            ${orderDetails.quantity} @ ${orderLevel}`}</p>
        </div>
        <div data-testid="order-type">
          <p>
            {`${t(
              `order-type.${orderDetails.orderType}`,
            )}, ${confirmTimeInForce(orderDetails.timeInForce)}`}
          </p>
        </div>
      </>
    );
  }

  function RejectedContent(content: { title: string; body: string }) {
    return (
      <>
        <h5 data-testid="confirmation-title">{content.title.toUpperCase()}</h5>
        <div data-testid="confirmation-body">
          <p>{content.body}</p>
        </div>
      </>
    );
  }
};

export default ConfirmationMessage;
