import React from "react";
import { type ExtraCharge } from "../../../types/extra-charge";
import { convertLocalToCurrencySymbol } from "../../../utils/currency-local-format";
import { useSession } from "../../../hooks/use-session";

interface ExtraChargesProps {
  charges: ExtraCharge[];
}

const ExtraCharges = ({ charges }: ExtraChargesProps) => {
  const { sessionData } = useSession();

  return (
    <>
      {charges.map((charge) => {
        return (
          <span key={charge.key} className="preview-ticket-extra-charges">
            <dt id={`preview-ticket-extra-charges_${charge.displayName}`}>
              {charge.displayName}
            </dt>
            <dd
              aria-labelledby={`preview-ticket-extra-charges_${charge.displayName}`}
            >
              {convertLocalToCurrencySymbol(
                charge.amount,
                sessionData.clientLocale,
                charge.currencyCode,
              )}
            </dd>
          </span>
        );
      })}
    </>
  );
};

export default ExtraCharges;
