import { type InstrumentAdapter } from "../../types/instrument-adapter";
import {
  type Instrument,
  type InstrumentUnderlying,
} from "../../types/instrument";
import { MarketService } from "ig-trading";
import { type Session } from "../../types/session";
import { createIgTradingSession } from "./ig-trading-session-factory";

export class IgTradingInstrumentAdapter implements InstrumentAdapter {
  readonly marketService: any;

  constructor(session: Session) {
    this.marketService = new MarketService(createIgTradingSession(session));
  }

  getInstrument = async (epic: string): Promise<Instrument> => {
    return this.marketService.findByEpic(epic);
  };

  getInstrumentUnderlying = async (
    epic: string,
  ): Promise<InstrumentUnderlying> => {
    return this.marketService.findByEpic(epic);
  };
}
