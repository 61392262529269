import { type Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { type ETPtheme } from "../../theme/etp-theme";
import {
  type SizeNotAboveMaxValidationError,
  type ValidationError,
  ValidationErrors,
} from "../../types/ticket-validation";
import { type InstrumentType } from "../../types/instrument-type";
import { Direction } from "../../types/directions";
import { SizeInputStyle } from "./SizeInputStyles";
import TetheredWarningMessage from "../TetheredWarningMessage";
import Input from "../generics/input/Input";
import {
  type TicketAction,
  TicketActions,
} from "../../hooks/reducers/ticket-reducer";

interface SizeInputProps {
  instrumentType: InstrumentType;
  ticketDirection: Direction;
  ticketSize: number;
  setTicketSize: (ticketSize: number | null) => void;
  availablePosition: number;
  ticketValidationErrors: ValidationError[];
  dispatch: Dispatch<TicketAction>;
  isQuickDealDisplayed: boolean;
}

const sizeInputValidationErrorKeys = [
  ValidationErrors.SIZE_INPUT_WARNING_ZERO,
  ValidationErrors.SIZE_INPUT_WARNING_ABOVE_MAX,
  ValidationErrors.SIZE_INPUT_WARNING_EXISTING_WORKING_ORDERS,
];

const SizeInput = ({
  instrumentType,
  ticketDirection,
  ticketSize,
  setTicketSize,
  availablePosition,
  ticketValidationErrors,
  dispatch,
  isQuickDealDisplayed,
}: SizeInputProps) => {
  const { t } = useTranslation();
  const ETPtheme: ETPtheme = useETPTheme();
  const sizeInputMinMax =
    ticketDirection === Direction.SELL ? availablePosition : 1;

  const sizeInputValidationError = ticketValidationErrors.find(
    (ticketValidationError) =>
      sizeInputValidationErrorKeys.includes(ticketValidationError.key),
  );

  const extractValidationErrorMessage = (validationError: ValidationError) => {
    if (
      validationError.key !==
      ValidationErrors.SIZE_INPUT_WARNING_EXISTING_WORKING_ORDERS
    ) {
      return t(validationError.key);
    }
    const existingWorkingOrderError =
      validationError as SizeNotAboveMaxValidationError;
    return t(existingWorkingOrderError.key, {
      existingPositionDealSize:
        existingWorkingOrderError.sizeDetails.existingPositionDealSize,
      sellOrderSize: existingWorkingOrderError.sizeDetails.sellOrderSize,
    });
  };

  const handleValidationErrors = () => {
    if (sizeInputValidationError !== undefined) {
      dispatch({
        type: TicketActions.REMOVE_TICKET_VALIDATION_ERROR,
        payload: {
          validationErrorKeyToRemove: sizeInputValidationError.key,
        },
      });
    }
  };

  return (
    <SizeInputStyle
      data-testid="size-input-container"
      onClick={handleValidationErrors}
      $ETPtheme={ETPtheme}
    >
      <TetheredWarningMessage
        testId="deal-size-input"
        warningMessage={
          sizeInputValidationError !== undefined
            ? extractValidationErrorMessage(sizeInputValidationError)
            : null
        }
        showWarningMessage={isQuickDealDisplayed}
      >
        <div className="size-row">
          <Input
            container={{
              id: "size-row__container",
            }}
            numericInput={{
              id: "size-row__input",
              "data-testid": "size-input",
              label: t("size-input-label"),
              hasError: sizeInputValidationError !== undefined,
              handleValidationErrors,
              value: ticketSize?.toString() ?? "",
              initialValue: 1,
              setValue: setTicketSize,
              max: 9_999_999,
              min: 1,
              step: calcStepSize(ticketSize),
              state: "active",
            }}
          />
          <div id="size-row__product-info">
            <p data-testid="size-input-product">
              {t(`product-type.${instrumentType}`)}
            </p>
            {ticketDirection === Direction.SELL ? (
              <p data-testid="size-input-max">{t("max") + sizeInputMinMax}</p>
            ) : (
              <p data-testid="size-input-min">{t("min") + sizeInputMinMax}</p>
            )}
          </div>
        </div>
      </TetheredWarningMessage>
    </SizeInputStyle>
  );
};

const calcStepSize = (ticketSize: number) => {
  if (ticketSize > 100) return 100;
  if (ticketSize <= 100 && ticketSize > 10) return 10;
  return 1;
};

export default SizeInput;
