import { Button } from "ig-phoenix";
import { useTranslation } from "react-i18next";
import Spinner from "../icons/Spinner";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { type ETPtheme } from "../../theme/etp-theme";
import {
  IN_PROGRESS_STATES,
  type TransactionState,
} from "../../types/transaction-status";
import { type Prices } from "../../types/prices";
import { useSubscription } from "../../hooks/use-subscription";
import { OrderButtonStyles, OrderInProgress } from "./OrderButtonStyles";
import useTradeableInstrumentChecker from "../../hooks/use-tradeable-instrument-checker";
import WarningMessage from "../messages/WarningMessage";

interface OrderButtonProps {
  placeDeal: (prices: Prices) => void;
  transactionState: TransactionState;
}
const OrderButton = ({ placeDeal, transactionState }: OrderButtonProps) => {
  const { t } = useTranslation();
  const isInProgress = IN_PROGRESS_STATES.includes(transactionState);
  const ETPtheme: ETPtheme = useETPTheme();
  const prices = useSubscription();
  const { isTradeable, reasonMessage } = useTradeableInstrumentChecker();
  return (
    <OrderButtonStyles
      $ETPtheme={ETPtheme}
      className="etp-quick-deal_order-button"
    >
      <Button
        variant={isInProgress ? "disabled" : isTradeable ? "commit" : "unarmed"}
        data-testid="order-button"
        disabled={isInProgress}
        onClick={(event) => {
          event.preventDefault();
          if (isTradeable) {
            placeDeal(prices);
          }
        }}
      >
        {isInProgress ? (
          <OrderInProgress>
            <Spinner />
            {t("order-button.in-progress")}
          </OrderInProgress>
        ) : (
          t("order-button.place-order")
        )}
      </Button>
      {reasonMessage !== null && (
        <WarningMessage
          testId="order-button-instrument-not-tradeable"
          message={reasonMessage}
          topOffset={-3}
        ></WarningMessage>
      )}
    </OrderButtonStyles>
  );
};

export default OrderButton;
