import styled, { css } from "styled-components";
import { GlobalElementsStyle } from "../../theme/global-elements-style";
import { type ETPtheme } from "../../theme/etp-theme";

export const ConfirmationMessageStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
  $isConfirmed: boolean;
  $isAnOrder: boolean;
}>`
  width: 224px;
  font-size: 13px;

  &::after {
    content: "";
    width: 12px;
    height: 12px;
    display: block;
    position: relative;
    top: -7px;
    left: calc(50% - 6px);
    transform: rotate(-45deg);
    ${({ $ETPtheme, $isConfirmed, $isAnOrder }) => {
      if (!$isConfirmed)
        return css`
          background: ${$ETPtheme.message.background.rejection};
          border-left: ${$ETPtheme.message.border.rejection};
          border-bottom: ${$ETPtheme.message.border.rejection};
        `;
      return $isAnOrder
        ? css`
            background: ${$ETPtheme.message.background.acknowledge};
            border-left: ${$ETPtheme.message.border.acknowledge};
            border-bottom: ${$ETPtheme.message.border.acknowledge};
          `
        : css`
            background: ${$ETPtheme.message.background.confirmation};
            border-left: ${$ETPtheme.message.border.confirmation};
            border-bottom: ${$ETPtheme.message.border.confirmation};
          `;
    }}
  }

  div[role="alert"] {
    padding: 8px;

    ${({ $ETPtheme, $isConfirmed, $isAnOrder }) => {
      if (!$isConfirmed)
        return css`
          background: ${$ETPtheme.message.background.rejection};
          border: ${$ETPtheme.message.border.rejection};
          color: ${$ETPtheme.message.text.body.rejection};
        `;
      return $isAnOrder
        ? css`
            background: ${$ETPtheme.message.background.acknowledge};
            border: ${$ETPtheme.message.border.acknowledge};
            color: ${$ETPtheme.message.text.body.acknowledge};
          `
        : css`
            background: ${$ETPtheme.message.background.confirmation};
            border: ${$ETPtheme.message.border.confirmation};
            color: ${$ETPtheme.message.text.body.confirmation};
          `;
    }}

    h5 {
      letter-spacing: 2.6px;
      margin-bottom: 14px;
      color: ${({ $ETPtheme, $isConfirmed, $isAnOrder }) => {
        if (!$isConfirmed) return $ETPtheme.message.text.title.rejection;
        return $isAnOrder
          ? $ETPtheme.message.text.title.acknowledge
          : $ETPtheme.message.text.title.confirmation;
      }};
    }

    div > svg {
      rect {
        fill: ${({ $ETPtheme, $isAnOrder }) =>
          $isAnOrder
            ? $ETPtheme.message.icon.rect.fill.acknowledge
            : $ETPtheme.message.icon.rect.fill.confirmation};
      }

      #critical_icon {
        path {
          fill: ${({ $ETPtheme, $isConfirmed, $isAnOrder }) =>
            $ETPtheme.message.icon.path.fill.rejection};
          stroke: none;

          &:nth-child(2) {
            fill: ${({ $ETPtheme }) =>
              $ETPtheme.message.icon.path.stroke.rejection};
            stroke: ${({ $ETPtheme }) =>
              $ETPtheme.message.icon.path.stroke.rejection};
          }
        }
      }
      path {
        fill: ${({ $ETPtheme, $isAnOrder }) =>
          $isAnOrder
            ? $ETPtheme.message.icon.path.fill.acknowledge
            : $ETPtheme.message.icon.path.fill.confirmation};
        stroke: ${({ $ETPtheme, $isAnOrder }) =>
          $isAnOrder
            ? $ETPtheme.message.icon.path.stroke.acknowledge
            : $ETPtheme.message.icon.path.stroke.confirmation};
      }
    }
  }
`;
