import { useTranslation } from "react-i18next";
import { FeatureFlag } from "../../types/feature-flag";
import { useFeatureFlag } from "../../hooks/use-feature-flag";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { FeedbackFormStyles } from "./FeedbackFormStyles";
import RatingForm from "./RatingForm";
import CloseIcon from "../icons/CloseIcon";
import {
  FeedbackService,
  type UserPreferences,
} from "../../services/feedback-service";
import { useState, useEffect } from "react";
import FeedbackInputForm, { FeedbackInputFormLabel } from "./FeedbackInputForm";
import { FeedbackCallToActionForm } from "./FeedbackCallToActionForm";
import { useSession } from "../../hooks/use-session";
import { type IgEnvironment } from "../../types/ig-environment";

interface UserResponse {
  questionId: string;
  translatedQuestion: string; // TODO: Change it to "question"
  answer: boolean | string; // TODO: Change it to "response"
}

export interface FeedbackPayload {
  featureName: string;
  responses: UserResponse[];
}

export enum UserFormStatus {
  INITIAL = "initial",
  SUBMITTED = "submitted",
  DISMISSED = "dismissed",
}

export enum FormStage {
  FEEDBACK_RATING = "feedback_rating",
  FEEDBACK_INPUT = "feedback_input",
  FEEDBACK_COMPLETE = "feedback_complete",
  FOLLOW_UP_REQUEST = "follow_up_request",
  FEEDBACK_CLOSED = "feedback_closed",
}

interface FeedbackFormProps {
  env: IgEnvironment;
  showFeedbackForm: boolean;
}

const isRecent = (lastUpdated: string) => {
  const timeframeInDays = 3;
  const today = new Date();
  const cutOffDate = today.setDate(today.getDate() - timeframeInDays);
  return Date.parse(lastUpdated) > cutOffDate;
};

const FeedbackForm = ({ env, showFeedbackForm }: FeedbackFormProps) => {
  const session = useSession();
  const [feedbackService] = useState<FeedbackService>(
    new FeedbackService(session, env, "quick-deal-survey"),
  );
  const [userFeedbackPreference, setUserFeedbackPreference] =
    useState<UserPreferences>({
      formStatus: UserFormStatus.DISMISSED,
      canBeContacted: false,
      lastUpdated: null,
    });

  const [formStage, setFormStage] = useState<FormStage>(
    FormStage.FEEDBACK_RATING,
  );
  const [feedback, setFeedback] = useState<FeedbackPayload>({
    featureName: "quick-deal-survey",
    responses: [],
  });
  const [isInProgress, setIsInProgress] = useState(false);

  const hasSubmittedRecently: boolean =
    userFeedbackPreference.lastUpdated === null
      ? false
      : isRecent(userFeedbackPreference.lastUpdated);

  useEffect(() => {
    if (featureFlag(FeatureFlag.ETP_QUICK_DEAL_FEEDBACK_SURVEY)) {
      void feedbackService.getPreference().then((userPreference) => {
        setUserFeedbackPreference(userPreference);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackService]);

  useEffect(() => {
    setFormStage(
      userFeedbackPreference.formStatus === UserFormStatus.SUBMITTED
        ? FormStage.FOLLOW_UP_REQUEST
        : FormStage.FEEDBACK_RATING,
    );
  }, [userFeedbackPreference.formStatus]);
  const featureFlag = useFeatureFlag();
  const ETPtheme = useETPTheme();
  const { t } = useTranslation();

  if (
    !featureFlag(FeatureFlag.ETP_QUICK_DEAL_FEEDBACK_SURVEY) ||
    userFeedbackPreference.formStatus === UserFormStatus.DISMISSED ||
    hasSubmittedRecently ||
    formStage === FormStage.FEEDBACK_CLOSED ||
    !showFeedbackForm
  ) {
    return null;
  }

  const addRating = (hasLiked: boolean) => {
    setFeedback((prevState) => {
      const newState = { ...prevState };
      newState.responses.push({
        questionId: "quick-deal-rating",
        translatedQuestion: t("feedback-form.rating-stage.title"),
        answer: hasLiked,
      });
      return newState;
    });
    setFormStage(FormStage.FEEDBACK_INPUT);
  };

  const handleShareMore = () => {
    setFormStage(FormStage.FEEDBACK_INPUT);
  };

  const getInputTitle = (): FeedbackInputFormLabel => {
    if (userFeedbackPreference.formStatus === UserFormStatus.SUBMITTED) {
      return FeedbackInputFormLabel.FOLLOW_UP;
    }
    if (feedback.responses[0].answer === true) {
      return FeedbackInputFormLabel.INITIAL_HAS_LIKED;
    } else {
      return FeedbackInputFormLabel.INITIAL_HAS_DISLIKED;
    }
  };

  const submitFeedback = (
    isContactable: boolean,
    question: string,
    comment: string,
  ) => {
    setIsInProgress(true);
    const trimmedComment = comment.trim();
    if (trimmedComment !== "") {
      feedback.responses.push({
        questionId: "quick-deal-further-feedback",
        translatedQuestion: question,
        answer: trimmedComment,
      });
    }

    Promise.all([
      feedback.responses.length > 0 && feedbackService.submit(feedback),
      feedbackService.setPreference(isContactable),
    ])
      .then(() => {
        setIsInProgress(false);
        // TODO handle rejection cases
        setFormStage(FormStage.FEEDBACK_COMPLETE);
      })
      .catch((error) => {
        setIsInProgress(false);
        console.error("error submitting feedback: ", error);
      });
  };

  const closeForm = () => {
    void feedbackService.setPreference(
      userFeedbackPreference.canBeContacted,
      UserFormStatus.DISMISSED,
    );
    if (
      formStage === FormStage.FEEDBACK_INPUT &&
      userFeedbackPreference.formStatus === UserFormStatus.INITIAL
    ) {
      void feedbackService.submit(feedback);
    }
    setFormStage(FormStage.FEEDBACK_CLOSED);
  };

  return (
    <FeedbackFormStyles $ETPtheme={ETPtheme} data-testid="feedback-form">
      {formStage !== FormStage.FEEDBACK_COMPLETE && (
        <CloseIcon onClose={closeForm} width={8} height={8} />
      )}
      {formStage === FormStage.FEEDBACK_RATING && (
        <RatingForm handleAddRating={addRating} />
      )}
      {formStage === FormStage.FEEDBACK_INPUT && (
        <FeedbackInputForm
          title={getInputTitle()}
          canBeContacted={userFeedbackPreference.canBeContacted}
          submitFeedback={submitFeedback}
          isInProgress={isInProgress}
        />
      )}
      {formStage === FormStage.FOLLOW_UP_REQUEST && (
        <FeedbackCallToActionForm
          title={t("feedback-form.additional-feedback-stage.title")}
          description={t("feedback-form.additional-feedback-stage.description")}
          buttonText={t("feedback-form.additional-feedback-stage.button")}
          callToAction={handleShareMore}
        />
      )}
      {formStage === FormStage.FEEDBACK_COMPLETE && (
        <FeedbackCallToActionForm
          title={t("feedback-form.finish-stage.title")}
          description={t("feedback-form.finish-stage.description")}
          buttonText={t("feedback-form.finish-stage.button")}
          callToAction={() => {
            setFormStage(FormStage.FEEDBACK_CLOSED);
          }}
        />
      )}
    </FeedbackFormStyles>
  );
};

export default FeedbackForm;
