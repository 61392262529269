import styled from "styled-components";
import { type ETPtheme } from "./etp-theme";

export const GlobalElementsStyle = styled.div<{
  $ETPtheme: ETPtheme;
}>`
  line-height: 1.6;
  font-size: 11px;

  a {
    font-size: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: auto;
      text-decoration-style: solid;
    }
  }

  dl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0;
    font-size: inherit;

    dt {
      text-align: left;
      font-size: inherit;
    }

    dd {
      margin-left: 0;
      text-align: right;
      font-size: inherit;
    }
  }

  h3 {
    font-size: inherit;
    line-height: inherit;
  }

  h5 {
    font-size: inherit;
    line-height: inherit;
    font-weight: 500;
  }

  input[type="radio"] {
    display: unset;
  }

  label {
    font-size: inherit;
  }

  p {
    margin: 0;
    font-size: inherit;
    line-height: inherit;
  }

  strong {
    font-size: inherit;
    line-height: inherit;
    font-weight: 500;
  }

  .etp-quick-deal {
    &_external-link {
      color: ${({ $ETPtheme }) => $ETPtheme.link.text.enabled};
      background: none;
      border: none;
      padding: 0;

      &:hover {
        color: ${({ $ETPtheme }) => $ETPtheme.link.text.enabled};
        text-decoration: underline;
      }

      &:disabled {
        cursor: default;
        color: ${({ $ETPtheme }) => $ETPtheme.link.text.disabled};
        text-decoration: none;
        appearance: none;
      }

      &--light {
        // TODO: make a global element theme object
        color: ${({ $ETPtheme }) => $ETPtheme.marketDepth.text.link};
      }

      &--centered {
        text-align: center;
        width: 100%;
      }

      &--with-icon {
        &::after {
          content: "";
          display: inline-block;
          background-image: url("https://a.c-dn.net/b/0IIgnx.svg#icon-popout-blue.svg");
          background-repeat: no-repeat;
          background-size: 10px;
          height: 13px;
          margin-left: 4px;
          position: relative;
          top: 3px;
          width: 10px;
        }
      }
    }
  }
`;
