import { createGlobalStyle } from "styled-components";
import { type ETPtheme } from "./etp-theme";

// Style outside our QuickDeal component:
// ⚠️By editing this style you will risk to change the rest of the platform

const SharedStyles = createGlobalStyle<{
  $ETPtheme: ETPtheme;
}>`
  .etp-quick-deal_react-tether {
      z-index: 10001; // Same as ember-tether
  }
  .etp-quick-deal_tooltip-container {
      z-index: 12000;
  }
`;

export default SharedStyles;
