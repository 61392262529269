import { type Ticket } from "../types/ticket";
import { resolveInstrumentTypeByString } from "../utils/resolve-instrument-type";
import { InstrumentType } from "../types/instrument-type";
import { type Prices } from "../types/prices";
import {
  type GaDealInteractionEvent,
  type GaDealResultConfirmEvent,
  type GaDealResultEvent,
  type GaDealResultRejectEvent,
  type GaInstrumentInteractionArguments,
  type GaInstrumentInteractionEvent,
} from "./ga-event-types";
import {
  type CancelledDetails,
  type ConfirmDetails,
  type FailedDetails,
  type RejectionDetails,
  TransactionState,
  type TransactionStatus,
  UNSUCCESSFUL_COMPLETED_STATES,
} from "../types/transaction-status";

function extractDealResult(details: RejectionDetails | ConfirmDetails) {
  const status = details.content.reportData.status;
  return status === undefined
    ? details.content.reportType
    : `${status.value.toUpperCase()}_${details.content.reportType}`;
}

function extractRejectionReason(
  rejectionDetails: RejectionDetails,
): string | undefined {
  const {
    rejectionMessage,
    rejectionMessage: { body },
  } = rejectionDetails.content.reportData;

  if (rejectionMessage.rejectionReason !== undefined) {
    return rejectionMessage.rejectionReason;
  }

  let extractedRejectionReason: string | undefined;

  if (body !== undefined) {
    extractedRejectionReason = body;
    /*
    Platform expects a body object with a { string: string } key value pair when there is no rejection reason
    in transaction details object. This is an edge case that we did not encounter, but ember-tracking handles in its codebase.
     */
    // @ts-expect-error See explanation above
    if (body.string !== undefined) {
      // @ts-expect-error Map the rejection reason to the string received from the body object
      extractedRejectionReason = body.string;
    }
  }

  return extractedRejectionReason?.replace(/(<([^>]+)>)/gi, "");
}

export const mapDealResult = (
  ticket: Ticket,
  transactionStatus: TransactionStatus,
): GaDealResultConfirmEvent | GaDealResultRejectEvent | GaDealResultEvent => {
  const gaEvent: GaDealResultEvent = {
    deal_result: "UNKNOWN_CONFIRMATION",
    deal_reference: "UNKNOWN",
    event_action: "UNKNOWN",
    interaction_type: "UNKNOWN",
    event_category: "Deal result",
    event_label: "ETP_QUICK_DEAL",
    event_value: 0,
    interaction_value: "ETP_QUICK_DEAL",
    instrument_name: ticket.instrument.instrumentName,
    instrument_epic_id: ticket.instrument.epic,
  };

  if (transactionStatus.state === TransactionState.CONFIRMED) {
    const confirmDetails = transactionStatus.details as ConfirmDetails;

    return {
      ...gaEvent,
      deal_reference: confirmDetails.content.dealReference,
      deal_result: extractDealResult(confirmDetails),
      event_action: confirmDetails.content.reportType,
      interaction_type: confirmDetails.content.reportType,
      resulting_deal_size: confirmDetails.content.quantity,
      resulting_price_level: confirmDetails.content.orderLevel,
    } satisfies GaDealResultConfirmEvent;
  }

  if (transactionStatus.state === TransactionState.FAILED) {
    const failedDetails = transactionStatus.details as FailedDetails;

    return {
      ...gaEvent,
      deal_result: failedDetails.content.reportType,
      event_action: failedDetails.content.reportType,
      interaction_type: failedDetails.content.reportType,
    } satisfies GaDealResultEvent;
  }

  if (transactionStatus.state === TransactionState.CANCELLED) {
    const cancelledDetails = transactionStatus.details as CancelledDetails;

    return {
      ...gaEvent,
      deal_reference: cancelledDetails.content.dealReference,
      deal_result: extractDealResult(cancelledDetails),
      event_action: cancelledDetails.content.reportType,
      interaction_type: cancelledDetails.content.reportType,
    } satisfies GaDealResultEvent;
  }

  if (UNSUCCESSFUL_COMPLETED_STATES.includes(transactionStatus.state)) {
    const rejectionDetails = transactionStatus.details as RejectionDetails;
    let rejectionReason;
    if (rejectionDetails !== null) {
      rejectionReason = extractRejectionReason(rejectionDetails);
      gaEvent.deal_reference = rejectionDetails.content.dealReference;
      gaEvent.event_action = rejectionDetails.content.reportType;
      gaEvent.interaction_type = rejectionDetails.content.reportType;
      gaEvent.deal_result = extractDealResult(rejectionDetails);
    }

    return {
      ...gaEvent,
      rejection_reason: rejectionReason,
    } satisfies GaDealResultRejectEvent;
  }

  return gaEvent;
};

export const mapDealingInteraction = (
  ticket: Ticket,
  prices: Prices | undefined,
): GaDealInteractionEvent => {
  let knockoutLevel: number | undefined;
  let strikeLevel: number | undefined;

  let submittedBuyPrice: number | undefined;
  let submittedSellPrice: number | undefined;

  if (prices === undefined) {
    submittedBuyPrice = undefined;
    submittedSellPrice = undefined;
  } else {
    submittedBuyPrice = prices.offer ?? undefined;
    submittedSellPrice = prices.bid ?? undefined;
  }

  const instrumentType = resolveInstrumentTypeByString(
    ticket.instrument.instrumentType,
  );

  if (instrumentType === InstrumentType.TURBO) {
    knockoutLevel = ticket.instrument.strikeLevel;
    strikeLevel = ticket.instrument.strikeLevel;
  } else if (instrumentType === InstrumentType.WARRANT) {
    strikeLevel = ticket.instrument.strikeLevel;
  }

  const errors =
    ticket.validationErrors.length > 0
      ? ticket.validationErrors
          .map((validationError) => validationError.googleAnalyticsCode)
          .join(" ")
      : undefined;

  return {
    event_category: "Dealing interaction",
    event_action: "submit",
    event_value: 0,
    interaction_type: "submit",
    interaction_value: "ETP_QUICK_DEAL",
    instrument_name: ticket.instrument.instrumentName,
    instrument_epic_id: ticket.instrument.epic,
    ui_ticket_location: "market-view",
    ui_ticket_type: "create_position_ticket",
    submitted_direction: ticket.direction,
    submitted_net_off_force_open: "Net off",
    submitted_execution_type: "market",
    validation_submission_error: errors,
    deal_reference: ticket.dealReference,
    submitted_deal_size: ticket.size,
    submitted_stop_unit: "absolute",
    submitted_stop_type: "NON_GUARANTEED",
    submitted_limit_unit: "absolute",
    submitted_trade_currency: ticket.instrument.ticketDefaultCurrency.name,
    submitted_knockout_level: knockoutLevel,
    submitted_strike_level: strikeLevel,
    submitted_buy_price: submittedBuyPrice,
    submitted_sell_price: submittedSellPrice,
    order_type: ticket.orderType,
  };
};

export const mapInstrumentInteraction = (
  instrumentName: string,
  instrumentEpic: string,
  eventArguments: GaInstrumentInteractionArguments,
): GaInstrumentInteractionEvent => {
  const { eventAction, interactionType, interactionSubtype } = eventArguments;
  return {
    event_category: "Instrument Interaction",
    event_action: eventAction,
    event_label: "ETP_QUICK_DEAL",
    interaction_type: interactionType,
    interaction_subtype: interactionSubtype,
    interaction_value: "ETP_QUICK_DEAL",
    instrument_name: instrumentName,
    instrument_epic_id: instrumentEpic,
  };
};
