import { Direction } from "../types/directions";

const _getCurrencyFXRate = (baseExchangeRate: number) => 1 / baseExchangeRate;

export const exchangeToAccountCurrency = (
  amount: number,
  baseExchangeRate: number,
  currencyOffset: number,
  direction: Direction,
) => {
  const exchangedAmount = exchangeToAccountCurrencyWithoutFee(
    amount,
    baseExchangeRate,
  );
  const exchangeFee = computeExchangeFeeToAccountCurrency(
    amount,
    baseExchangeRate,
    currencyOffset,
  );

  return direction === Direction.SELL
    ? exchangedAmount - exchangeFee
    : exchangedAmount + exchangeFee;
};

export const computeExchangeFeeToAccountCurrency = (
  amount: number,
  baseExchangeRate: number,
  currencyOffset: number,
) => {
  const ONE_PERCENT = 0.01;
  const currencyFXRate = _getCurrencyFXRate(baseExchangeRate);

  const feePerUnit = currencyFXRate * currencyOffset * ONE_PERCENT;

  return amount * feePerUnit;
};

export const exchangeToAccountCurrencyWithoutFee = (
  amount: number,
  baseExchangeRate: number,
) => {
  const currencyFXRate = _getCurrencyFXRate(baseExchangeRate);
  return amount * currencyFXRate;
};
