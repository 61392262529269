import { useMemo } from "react";

const MemoSubscriptionIdPrice = (
  currentAccountId: string,
  instrumentEpic: string,
) => {
  return useMemo(() => {
    return [`MARKET_V4:${currentAccountId}:${instrumentEpic}`];
  }, [currentAccountId, instrumentEpic]);
};

export default MemoSubscriptionIdPrice;
