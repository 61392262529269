import { useETPTheme } from "../../hooks/use-etp-theme";
import { FeedbackCallToActionFormStyles } from "./FeedbackCallToActionFormStyles";

interface FeedbackCallToActionFormProps {
  title: string;
  description: string;
  buttonText: string;
  callToAction: () => void;
}
export const FeedbackCallToActionForm = ({
  title,
  description,
  buttonText,
  callToAction,
}: FeedbackCallToActionFormProps) => {
  const ETPtheme = useETPTheme();

  return (
    <FeedbackCallToActionFormStyles $ETPtheme={ETPtheme}>
      <h2>{title}</h2>
      <p>{description}</p>
      <button
        className="etp-quick-deal__feedback-form__large-button"
        type={"button"}
        onClick={callToAction}
      >
        {buttonText}
      </button>
    </FeedbackCallToActionFormStyles>
  );
};
