import { createContext, type ReactNode } from "react";
import { useTurboPricingLightstreamer } from "./use-turbo-pricing-lightstreamer";
import { type MarketStatus, type PricingData } from "../types/pricing-data";
import { determineEffectivePricingData } from "../utils/effective-pricing-data";

interface SubscriptionProviderProps {
  children: ReactNode;
  instrumentEpic: string;
  snapshotBid: string;
  snapshotOffer: string;
  snapshotMarketStatus: MarketStatus;
}
export const SubscriptionTurboContext = createContext<PricingData | null>(null);

export const SubscriptionTurboProvider = ({
  children,
  instrumentEpic,
  snapshotBid,
  snapshotOffer,
  snapshotMarketStatus,
}: SubscriptionProviderProps) => {
  const lightstreamerPricingData = useTurboPricingLightstreamer(instrumentEpic);
  const effectivePricingData = determineEffectivePricingData(
    snapshotBid,
    snapshotOffer,
    lightstreamerPricingData,
    snapshotMarketStatus,
  );

  return (
    <SubscriptionTurboContext.Provider value={effectivePricingData}>
      {children}
    </SubscriptionTurboContext.Provider>
  );
};
