import PreviewTicket from "./components/order/preview/PreviewTicket";
import PreviewButton from "./components/order/preview/PreviewButton";
import { type Ticket } from "./types/ticket";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useAdapter } from "./hooks/use-adapter";
import DirectionButtons from "./components/./directionButtons/DirectionButtons";
import { Direction } from "./types/directions";
import TetherComponent from "react-tether";
import {
  type PositionChangeCallback,
  PositionMonitoringService,
} from "./services/position-monitoring-service";
import {
  COMPLETED_STATES,
  TransactionState,
  type TransactionStatus,
  type TransactionStatusInitial,
} from "./types/transaction-status";
import ConfirmationMessage from "./components/messages/ConfirmationMessage";
import { OrderMonitoringService } from "./services/order-monitoring-service";
import SizeNotAboveMaxValidator from "./services/validators/size-not-above-max-validator";
import { type TicketValidator } from "./types/ticket-validation";
import SizeNotZeroValidator from "./services/validators/size-not-zero-validator";
import InstrumentTitle from "./components/./instrumentTitle/InstrumentTitle";
import Consideration from "./components/infoSummary/Consideration";
import ViewKid from "./components/infoSummary/ViewKid";
import { resolveInstrumentTypeByInstrument } from "./utils/resolve-instrument-type";
import CostsAndCharges from "./components/infoSummary/costsAndCharges/CostsAndCharges";
import { useETPTheme } from "./hooks/use-etp-theme";
import { type ETPtheme } from "./theme/etp-theme";
import { useInstrumentData } from "./hooks/use-instrument-data";
import ticketReducer, { TicketActions } from "./hooks/reducers/ticket-reducer";
import OrderButton from "./components/order/OrderButton";
import { OrderType, TimeInForce } from "./types/ig-trading-order-ticket";
import { type Prices } from "./types/prices";
import {
  InfoSummaryContainer,
  PreviewOverlay,
  QuickDealStyles,
} from "./QuickDealStyles";
import ProfitAndLoss from "./components/infoSummary/ProfitAndLoss";
import WideSpreadWarning from "./components/messages/WideSpreadWarning";
import MarketDepthContainer from "./components/marketDepth/MarketDepthContainer";
import OrderTabs from "./components/orderTabs/OrderTabs";
import { type GaTracer } from "./google-analytics/ga-tracer";
import LimitNotZeroValidator from "./services/validators/limit-not-zero-validator";
import StopNotZeroValidator from "./services/validators/stop-not-zero-validator";
import StopNotAboveCurrentBidValidator from "./services/validators/stop-not-above-current-bid-validator";
import { getStopLimitLevel } from "./utils/profit-and-loss-calculator";
import FeedbackForm from "./components/feedbackForm/FeedbackForm";
import { type IgEnvironment } from "./types/ig-environment";

export interface QuickDealProps {
  initialDirection: Direction;
  showPreviewOrder?: boolean;
  setUserPlatformPreference?: (preferenceToChange: string, value: any) => void;
  gaTracer: GaTracer;
  isQuickDealDisplayed: boolean;
  env: IgEnvironment;
}

const INITIAL_TRANSACTION_STATUS: TransactionStatusInitial = {
  state: TransactionState.NOT_STARTED,
  details: null,
};

const QuickDeal = ({
  initialDirection,
  showPreviewOrder = true,
  setUserPlatformPreference,
  gaTracer,
  isQuickDealDisplayed,
  env,
}: QuickDealProps) => {
  const ETPtheme: ETPtheme = useETPTheme();
  const { instrumentData } = useInstrumentData();
  const instrumentEpic = instrumentData.epic;
  const { adapter, positionAdapter, orderAdapter } = useAdapter();
  const [ticket, dispatch] = useReducer(ticketReducer, null);
  const [availablePosition, setAvailablePosition] = useState<number>(0);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  /* NB: (availablePosition === 0) is _not_ equivalent to (isPositionNotExistingOrClosed === true) - availablePosition
   * could be zero even if you have an existing position (i.e., if you happen to have working orders of the same size)
   * and, in that case, you still want to enable the Sell button. */
  const [isPositionNotExistingOrClosed, setIsPositionNotExistingOrClosed] =
    useState<boolean>(true);
  const [isPreviewing, setIsPreviewing] = useState<boolean>(false);
  const [isMarketDepthExpanded, setIsMarketDepthExpanded] =
    useState<boolean>(false);
  const [transactionStatus, setTransactionStatus] = useState<
    TransactionStatus | TransactionStatusInitial
  >(INITIAL_TRANSACTION_STATUS);

  const closeMessage = useCallback(() => {
    if (transactionStatus.state === TransactionState.CONFIRMED) {
      setShowFeedbackForm(true);
    }
    setTransactionStatus(INITIAL_TRANSACTION_STATUS);
  }, [transactionStatus.state]);

  const [positionMonitoringService] = useState<PositionMonitoringService>(
    new PositionMonitoringService(positionAdapter, instrumentEpic),
  );

  const [positionOpenLevel, setPositionOpenLevel] = useState<
    number | undefined
  >(undefined);
  const [orderMonitoringService] = useState<OrderMonitoringService>(
    new OrderMonitoringService(
      orderAdapter,
      instrumentEpic,
      recalculateAvailablePosition,
    ),
  );
  const ticketValidators: TicketValidator[] = useMemo(() => {
    return [
      new SizeNotZeroValidator(),
      new SizeNotAboveMaxValidator(
        orderMonitoringService,
        positionMonitoringService,
      ),
      new LimitNotZeroValidator(),
      new StopNotZeroValidator(),
      new StopNotAboveCurrentBidValidator(),
    ];
  }, [positionMonitoringService, orderMonitoringService]);
  const [hasUserChangedSizeInput, setHasUserChangedSizeInput] =
    useState<boolean>(false);

  const setTicketDirection = (selectedDirection: Direction) => {
    if (ticket === null) return;
    const existingPosition = positionMonitoringService.getExistingPosition();
    const size =
      existingPosition !== null && !hasUserChangedSizeInput
        ? existingPosition.dealSize
        : ticket.size;
    dispatch({
      type: TicketActions.UPDATE_TICKET_DIRECTION,
      payload: {
        direction: selectedDirection,
      },
    });
    dispatch({
      type: TicketActions.UPDATE_TICKET_SIZE,
      payload: {
        size,
      },
    });
  };

  function recalculateAvailablePosition() {
    const existingPosition = positionMonitoringService.getExistingPosition();
    if (existingPosition === null) return;
    const positionSize = existingPosition.dealSize;
    const availablePositionSize =
      positionSize - orderMonitoringService.getSizeOfExistingSellOrders();
    setAvailablePosition(availablePositionSize);
    setPositionOpenLevel(existingPosition.openLevel);
  }

  const setTicketSize = (ticketSize: number | null) => {
    dispatch({
      type: TicketActions.UPDATE_TICKET_SIZE,
      payload: { size: ticketSize ?? undefined },
    });
    setHasUserChangedSizeInput(true);
  };

  const handlePlaceDeal = (ticketState: Ticket, prices: Prices) => {
    gaTracer.trackDealingInteraction(ticketState, prices);
    adapter.placeDeal(ticketState, transactionStatusHandler, prices);
    dispatch({
      type: TicketActions.UPDATE_DEAL_REFERENCE,
      payload: { dealReference: adapter.generateDealReference() },
    });

    function transactionStatusHandler(ts: TransactionStatus) {
      setTransactionStatus(ts);
      if (COMPLETED_STATES.includes(ts.state)) {
        gaTracer.trackDealResult(ticket, ts);
      }
    }
  };

  const closePreviewTicket = useCallback(() => {
    setIsPreviewing(false);
  }, []);

  const isTicketValid = (prices: Prices) => {
    const validationErrors = ticketValidators
      .map((ticketValidator) => ticketValidator.validate(ticket, prices))
      .filter((validationResult) => validationResult.isError())
      .map((validationResult) => validationResult.getError());

    if (validationErrors.length > 0) {
      dispatch({
        type: TicketActions.UPDATE_TICKET_VALIDATION_ERROR,
        payload: { validationErrors },
      });
      gaTracer.trackDealingInteraction(
        { ...ticket, validationErrors },
        undefined,
      );
      return false;
    }
    return true;
  };

  useEffect(() => {
    const positionChangeCallbacks: PositionChangeCallback = {
      onPositionExistingOrOpen: () => {
        setIsPositionNotExistingOrClosed(false);
      },
      onPositionNotExistingOrClosed: () => {
        setIsPositionNotExistingOrClosed(true);
        dispatch({
          type: TicketActions.UPDATE_TICKET_DIRECTION,
          payload: {
            direction: Direction.BUY,
          },
        });
      },
      onPositionUpdate: () => {
        recalculateAvailablePosition();
      },
    };
    // TODO handle rejection case
    const positionPromise = positionMonitoringService.startMonitoringPosition(
      positionChangeCallbacks,
    );
    const ordersPromise = orderMonitoringService.startMonitoringOrders();

    void Promise.all([positionPromise, ordersPromise]).then(
      ([existingPosition]) => {
        let size;
        if (existingPosition !== null) {
          recalculateAvailablePosition();
          if (initialDirection === Direction.SELL) {
            size = existingPosition.dealSize;
          }
        }
        dispatch({
          type: TicketActions.INITIAL_SETUP_OF_TICKET,
          payload: {
            direction: initialDirection,
            instrument: instrumentData,
            size,
            dealReference: adapter.generateDealReference(),
            timeInForce: TimeInForce.IMMEDIATE_OR_CANCEL,
            orderType: OrderType.MARKET,
          },
        });
      },
    );

    gaTracer.trackQuickDealImpression(
      instrumentData.instrumentName,
      instrumentData.epic,
    );

    return () => {
      positionMonitoringService.stopMonitoringPosition();
      orderMonitoringService.stopMonitoringOrders();
      adapter.destroy();
    };
    // adding missing dependencies requires refactoring for non-existing scenario to change the epic at runtime
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrumentEpic]);

  useEffect(() => {
    if (COMPLETED_STATES.includes(transactionStatus.state)) {
      setIsPreviewing(false);
    }
  }, [transactionStatus]);

  if (ticket === null) {
    return <></>;
  }

  const resolvedInstrumentType = resolveInstrumentTypeByInstrument(
    ticket.instrument,
  );

  function isTransactionPending() {
    return transactionStatus.state === TransactionState.PENDING;
  }

  function isSellButtonDisabled() {
    return isPositionNotExistingOrClosed || isTransactionPending();
  }

  return (
    <QuickDealStyles $ETPtheme={ETPtheme} data-testid="quick-deal-component">
      <InstrumentTitle instrument={ticket.instrument} />
      <WideSpreadWarning instrumentType={ticket.instrument.instrumentType} />
      <DirectionButtons
        selectedDirection={ticket.direction}
        isBuyDisabled={isTransactionPending()}
        onDirectionButtonClick={setTicketDirection}
        isMarketDepthExpanded={isMarketDepthExpanded}
        isSellDisabled={isSellButtonDisabled()}
        decimalPlacesFactor={ticket.instrument.decimalPlacesFactor}
      />
      <MarketDepthContainer
        selectedDirection={ticket.direction}
        isBuyDisabled={isTransactionPending()}
        instrumentType={resolvedInstrumentType}
        onDirectionButtonClick={setTicketDirection}
        isMarketDepthExpanded={isMarketDepthExpanded}
        setIsMarketDepthExpanded={setIsMarketDepthExpanded}
        isSellDisabled={isSellButtonDisabled()}
        decimalPlacesFactor={ticket.instrument.decimalPlacesFactor}
        orderType={ticket.orderType}
        dispatch={dispatch}
      />
      <OrderTabs
        resolvedInstrumentType={resolvedInstrumentType}
        ticket={ticket}
        setTicketSize={setTicketSize}
        dispatch={dispatch}
        availablePosition={availablePosition}
        isQuickDealDisplayed={isQuickDealDisplayed}
        gaTracer={gaTracer}
      />
      <InfoSummaryContainer $ETPtheme={ETPtheme}>
        <Consideration ticket={ticket} />
        {ticket.direction === Direction.SELL && (
          <ProfitAndLoss
            openLevel={positionOpenLevel}
            ticketSize={ticket.size}
            stopLimitLevel={getStopLimitLevel(ticket.orderType, ticket)}
            ticketDefaultCurrency={ticket.instrument.ticketDefaultCurrency.name}
            orderType={ticket.orderType}
            baseExchangeRate={
              ticket.instrument.ticketDefaultCurrency.baseExchangeRate
            }
          />
        )}
        <ViewKid
          instrumentType={ticket.instrument.instrumentType}
          instrumentIsin={ticket.instrument.isin}
        />
        <CostsAndCharges
          ticket={ticket}
          positionOpeningLevel={positionOpenLevel}
        />
      </InfoSummaryContainer>
      <TetherComponent
        attachment="bottom center"
        targetAttachment="bottom center"
        offset="-30px 0"
        className="etp-quick-deal_react-tether"
        renderElement={(refPreview) =>
          isPreviewing && (
            <span
              role="dialog"
              aria-labelledby="etp-quick-deal-preview-ticket-title"
              ref={refPreview}
            >
              <PreviewTicket
                ticket={ticket}
                positionOpenLevel={positionOpenLevel}
                transactionState={transactionStatus.state}
                handlePlaceDeal={handlePlaceDeal}
                closePreviewTicket={closePreviewTicket}
                setUserPlatformPreference={setUserPlatformPreference}
              />
            </span>
          )
        }
        renderTarget={(refPreviewButtonModal) => (
          <TetherComponent
            attachment="bottom center"
            targetAttachment="top center"
            offset="-12px 0"
            className="etp-quick-deal_react-tether"
            renderElement={(refConfirmation) => (
              <div
                ref={refConfirmation}
                className="confirmation-message__container"
              >
                <ConfirmationMessage
                  state={transactionStatus.state}
                  details={transactionStatus.details}
                  closeMessage={closeMessage}
                />
              </div>
            )}
            renderTarget={(refPreviewButtonConfirmation) => (
              <div ref={refPreviewButtonConfirmation}>
                <div ref={refPreviewButtonModal}>
                  {showPreviewOrder ? (
                    <PreviewButton
                      previewDeal={(prices: Prices) => {
                        if (isTicketValid(prices)) {
                          setIsPreviewing(true);
                        }
                      }}
                    />
                  ) : (
                    <OrderButton
                      transactionState={transactionStatus.state}
                      placeDeal={(prices: Prices) => {
                        if (isTicketValid(prices)) {
                          handlePlaceDeal(ticket, prices);
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          />
        )}
      />
      <FeedbackForm env={env} showFeedbackForm={showFeedbackForm} />
      {isPreviewing && <PreviewOverlay />}
    </QuickDealStyles>
  );
};

export default QuickDeal;
