import TetherComponent from "react-tether";
import React, { type ReactNode, useRef, useState } from "react";
import {
  TargetContainerStyles,
  TooltipContentStyles,
  TooltipStyles,
} from "./TooltipStyles";
import { useETPTheme } from "../../../hooks/use-etp-theme";

export enum yAxis {
  TOP = "top",
  BOTTOM = "bottom",
}

export enum xAxis {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
}

export enum PointerLocation {
  TOP = "top",
  BOTTOM = "bottom",
  LEFT = "left",
  RIGHT = "right",
  NONE = "none",
}

export interface Offset {
  y: string;
  x: string;
}

export interface TooltipBoxProperty {
  maxWidth?: string;
  minWidth?: string;
  minHeight?: string;
  maxHeight?: string;
  width?: string;
  height?: string;
}

interface TooltipProps {
  children: ReactNode;
  testId?: string;
  className?: string;
  attachment: { y: yAxis; x: xAxis };
  targetAttachment: { y: yAxis; x: xAxis };
  offset?: Offset;
  content: ReactNode;
  tooltipBoxSize?: TooltipBoxProperty;
  pointerLocation?: PointerLocation;
  pointerPosition?: number;
}

const Tooltip = ({
  children,
  testId,
  className,
  attachment,
  targetAttachment,
  offset = { y: "0", x: "0" },
  content,
  tooltipBoxSize = { maxWidth: "200px", minWidth: "140px" },
  pointerLocation = PointerLocation.NONE,
  pointerPosition = 50,
}: TooltipProps) => {
  throwIfOutOfRange(pointerLocation, pointerPosition);

  const etpTheme = useETPTheme();
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const hoverTimeout = useRef<NodeJS.Timeout>();

  const showTooltip = () => {
    clearTimeout(hoverTimeout.current);

    hoverTimeout.current = setTimeout(() => {
      setIsVisible(true);
    }, 250);
  };

  const hideTooltip = () => {
    if (!isHovered) {
      clearTimeout(hoverTimeout.current);

      hoverTimeout.current = setTimeout(() => {
        setIsVisible(false);
      }, 250);
    }
  };

  return (
    <TetherComponent
      attachment={`${attachment.y} ${attachment.x}`}
      targetAttachment={`${targetAttachment.y} ${targetAttachment.x}`}
      offset={`${offset.y} ${offset.x}`}
      className="etp-quick-deal_react-tether"
      renderElement={(ref) => {
        return isVisible || isHovered ? (
          <TooltipStyles
            ref={ref}
            role="tooltip"
            data-testid={testId}
            className={className}
            style={tooltipBoxSize}
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
            $ETPtheme={etpTheme}
            $isVisible={isVisible || isHovered}
            $pointerLocation={pointerLocation}
            $pointerPosition={pointerPosition}
          >
            {content}
          </TooltipStyles>
        ) : null;
      }}
      renderTarget={(ref) => (
        <TargetContainerStyles
          id="etp-quick-deal_react-tether-target-element"
          type="button"
          ref={ref}
          onMouseEnter={showTooltip}
          onMouseLeave={hideTooltip}
        >
          {children}
        </TargetContainerStyles>
      )}
    />
  );
};

export default Tooltip;

export interface TooltipContentProps {
  children: ReactNode;
}
export const TooltipContent = ({ children }: TooltipContentProps) => {
  const etpTheme = useETPTheme();
  return (
    <TooltipContentStyles $ETPtheme={etpTheme}>{children}</TooltipContentStyles>
  );
};

function throwIfOutOfRange(
  pointerLocation: PointerLocation,
  pointerPosition: number,
) {
  const isPointerTopOrBottom = [
    PointerLocation.TOP,
    PointerLocation.BOTTOM,
  ].includes(pointerLocation);

  const isPointerLeftOrRight = [
    PointerLocation.LEFT,
    PointerLocation.RIGHT,
  ].includes(pointerLocation);

  const isHorizontallyOutOfRange = pointerPosition < 4 || pointerPosition > 96;
  const isVerticallyOutOfRange = pointerPosition < 7 || pointerPosition > 93;

  if (isPointerTopOrBottom && isHorizontallyOutOfRange) {
    throw new Error(
      "pointerPosition should be between 4 and 96 when pointerLocation is TOP or BOTTOM",
    );
  }

  if (isPointerLeftOrRight && isVerticallyOutOfRange) {
    throw new Error(
      "pointerPosition should be between 7 and 93 when pointerLocation is LEFT or RIGHT",
    );
  }
  return false;
}
