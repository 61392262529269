import { type ETPtheme } from "../../theme/etp-theme";
import styled from "styled-components";
import { GlobalElementsStyle } from "../../theme/global-elements-style";

export const UnderlyingExposureStyle = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  line-height: 12px;
  font-style: italic;
  color: ${({ $ETPtheme }) => $ETPtheme.underlyingExposure.text};
  margin-top: 2px;
  min-height: 20px;
`;
