import { type Ticket } from "../../../types/ticket";
import { useEffect, useState } from "react";
import { type EstimateCharges } from "../../../types/estimate-charges";
import { useSession } from "../../../hooks/use-session";
import { useSubscription } from "../../../hooks/use-subscription";
import { Direction } from "../../../types/directions";
import { wtpPost } from "../../../adapters/wtp-fetch-adapter";
import Commission from "./Commission";
import { EstimatedChargesStyles } from "./EstimatedChargesStyles";
import { useETPTheme } from "../../../hooks/use-etp-theme";
import TotalCost from "./TotalCost";
import ExtraCharges from "./ExtraCharges";

interface EstimatedChargesProps {
  ticket: Ticket;
}

const EstimatedCharges = ({ ticket }: EstimatedChargesProps) => {
  const [estimatedCharges, setEstimatedCharges] = useState<EstimateCharges>();
  const session = useSession();
  const prices = useSubscription();
  const ETPtheme = useETPTheme();
  const [hasDataBeenRequested, setHasDataBeenRequested] = useState(false);

  const relevantPrice =
    ticket.direction === Direction.SELL ? prices.bid : prices.offer;

  useEffect(() => {
    if (hasDataBeenRequested || relevantPrice === null) {
      return;
    }

    setHasDataBeenRequested(true);

    const fetchRequestBody = {
      currencyCode: ticket.instrument.ticketDefaultCurrency.name,
      direction: ticket.direction.toUpperCase(),
      epic: ticket.instrument.epic,
      size: ticket.size,
      level: relevantPrice,
    };

    wtpPost(
      "/nwtpdeal/orders/considerations/estimatecharges",
      session,
      fetchRequestBody,
    )
      .then(async (response) => {
        if (response.ok) {
          return await response.json();
        }
        throw new Error();
      })
      .then((data: EstimateCharges) => {
        setEstimatedCharges(data);
      })
      .catch((reason) => {
        console.error("error", reason);
      });
  }, [
    hasDataBeenRequested,
    relevantPrice,
    session,
    ticket.direction,
    ticket.instrument.epic,
    ticket.instrument.ticketDefaultCurrency.name,
    ticket.size,
  ]);

  return (
    <EstimatedChargesStyles $ETPtheme={ETPtheme}>
      <dl>
        <Commission
          commission={estimatedCharges?.commission.amount}
          commissionCurrency={estimatedCharges?.commission.currencyCode}
        />
        {estimatedCharges?.charges !== undefined && (
          <ExtraCharges charges={estimatedCharges.charges} />
        )}
        <TotalCost
          direction={ticket.direction}
          totalCostInTicketCurrency={
            estimatedCharges?.totalTradeCurrency.amount
          }
          ticketCurrency={estimatedCharges?.totalTradeCurrency.currencyCode}
          totalCostInAccountCurrency={
            estimatedCharges?.totalUsersBaseCurrency.amount
          }
          accountCurrency={
            estimatedCharges?.totalUsersBaseCurrency.currencyCode
          }
        />
      </dl>
    </EstimatedChargesStyles>
  );
};

export default EstimatedCharges;
