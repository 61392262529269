import { type Prices, type RawPrices } from "./prices";

export enum MarketStatus {
  TRADEABLE = "TRADEABLE",
  CLOSED = "CLOSED",
  AUCTION = "AUCTION",
  AUCTION_NO_EDIT = "AUCTION_NO_EDIT",
  OFFLINE = "OFFLINE",
  CLOSE_ONLY = "CLOSE_ONLY",
  EDIT = "EDIT",
  SUSPENDED = "SUSPENDED",
  UNAVAILABLE = "UNAVAILABLE",
  NONTRADEABLE = "NONTRADEABLE",
}

export type MarketDepths = [
  MarketDepth,
  MarketDepth,
  MarketDepth,
  MarketDepth,
  MarketDepth,
];

export type Modify<T, R> = Omit<T, keyof R> & R;

// Overriding the string bid and offer types of raw pricing data to numbers
export type PricingDataWithBidAndOfferConverted = Modify<
  RawPricingData,
  Prices
>;

export type PricingData = Modify<
  PricingDataWithBidAndOfferConverted,
  UnderlyingPrice
>;

interface UnderlyingPrice {
  underlyingPrice: number | null;
}

export interface RawPricingData extends RawPrices {
  bidSize?: string | null;
  offerSize?: string | null;
  marketStatus: MarketStatus;
  bidDepth?: MarketDepths;
  offerDepth?: MarketDepths;
  underlyingPrice?: string;
}

export interface MarketDepth {
  price: string;
  size: string;
}
