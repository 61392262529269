import { type AdaptersFactory } from "../types/adapters-factory";
import { type Session } from "../types/session";
import { type LightstreamerService } from "../services/lightstreamer";
import IgTradingAdapter from "./ig-trading/ig-trading-adapter";
import { AdaptersHolder } from "../types/adapters-holder";
import IgTradingPositionAdapter from "./ig-trading/ig-trading-position-adapter";
import {
  DealReferenceGenerator,
  PositionService,
  OrderService,
  PrioritisedConfirmationStreamingClient,
} from "ig-trading";
import IgTradingOrderAdapter from "./ig-trading/ig-trading-order-adapter";
import IgApiCostsAndChargesAdapter from "./ig-api/ig-api-costs-and-charges-adapter";
import { ThrottledCostsAndChargesAdapter } from "../services/throttled-costs-and-charges-adapter";
import { createIgTradingSession } from "./ig-trading/ig-trading-session-factory";

export default class QuickDealAdapterFactory implements AdaptersFactory {
  createAdaptersHolder(
    session: Session,
    lightstreamerService: LightstreamerService,
  ): AdaptersHolder {
    const confirmationStreamingClient =
      new PrioritisedConfirmationStreamingClient(
        lightstreamerService,
        session.sessionData.currentAccountId,
      );
    const dealReferenceGenerator = new DealReferenceGenerator(
      session.sessionData.currentAccountId,
    );
    const igTradingSession = createIgTradingSession(session);
    const igTradingPositionService = new PositionService(
      igTradingSession,
      confirmationStreamingClient,
      dealReferenceGenerator,
    );
    const igTradingAdapter = new IgTradingAdapter(
      igTradingSession,
      confirmationStreamingClient,
      dealReferenceGenerator,
    );
    const igTradingPositionAdapter = new IgTradingPositionAdapter(
      lightstreamerService,
      session.sessionData.currentAccountId,
      igTradingPositionService,
    );
    const igTradingOrderService = new OrderService(
      igTradingSession,
      confirmationStreamingClient,
      dealReferenceGenerator,
    );
    const igTradingOrderAdapter = new IgTradingOrderAdapter(
      lightstreamerService,
      session.sessionData.currentAccountId,
      igTradingOrderService,
    );
    const igApiCostsAndChargesAdapter = new ThrottledCostsAndChargesAdapter(
      new IgApiCostsAndChargesAdapter(session),
      2_000,
    );
    return new AdaptersHolder(
      igTradingAdapter,
      igTradingPositionAdapter,
      igTradingOrderAdapter,
      igApiCostsAndChargesAdapter,
    );
  }
}
