import { CounterButton } from "./CounterButton";
import {
  type ChangeEvent,
  type InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import { NumericInputStyles } from "./NumericInputStyles";
import ChevronIcon, { ChevronPointingDirection } from "../../icons/ChevronIcon";
import { useETPTheme } from "../../../hooks/use-etp-theme";

export interface NumericInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  initialValue?: number;
  value?: string;
  placeholder?: string;
  step?: number;
  min?: number;
  max?: number;
  showArrows?: boolean;
  onUpdate: (newValue: number | null) => void;
  hasError?: boolean;
  state?: "active" | "inactive" | "disabled";
}

export const NumericInput = ({
  initialValue,
  value,
  placeholder,
  step = 1,
  min = 0,
  max = Number.POSITIVE_INFINITY,
  showArrows = true,
  onUpdate,
  hasError = false,
  state = "active",
  ...inputProps
}: NumericInputProps) => {
  const [inputValue, setInputValue] = useState(
    value !== undefined ? value.toString() : "",
  );
  const isComponentDisabled = state === "disabled";
  const isComponentNotActive = state !== "active";
  const inputValueAsNumber = Number(inputValue);
  const decimalPlaces = countDecimalPlaces(step);
  const etpTheme = useETPTheme();
  const setTimeoutRef = useRef<NodeJS.Timeout>();
  const setIntervalRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (inputValueAsNumber <= min || inputValueAsNumber >= max) {
      clearTimers();
    }
  }, [inputValue, inputValueAsNumber, max, min, onUpdate]);

  useEffect(() => {
    setInputValue(value ?? "");
  }, [value]);

  const addStepToValue = (theStep: number) => {
    const updatedInput = (Number(inputValue) + theStep).toFixed(decimalPlaces);
    setInputValue(updatedInput);
    onUpdate(Number(updatedInput));
    return updatedInput;
  };

  function isDisabled(
    hasReachedBoundaries: boolean,
    hasReachedInitialBoundaries: boolean,
  ) {
    if (inputValue === "" && initialValue === undefined) {
      return true;
    }
    if (inputValue !== "") {
      return hasReachedBoundaries;
    }
    if (initialValue !== undefined) {
      return hasReachedInitialBoundaries;
    }
    return false;
  }
  const isIncrementDisabled = () =>
    isDisabled(
      inputValueAsNumber >= max || inputValueAsNumber < min,
      initialValue !== undefined && (initialValue < min || initialValue >= max),
    );
  const isDecrementDisabled = () =>
    isDisabled(
      inputValueAsNumber > max || inputValueAsNumber <= min,
      initialValue !== undefined && (initialValue <= min || initialValue > max),
    );

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const newValue = target.value;

    if (newValue === "") {
      setInputValue("");
      onUpdate(null);
      return;
    }

    const validityRegex = /^-?(\d*|\d+(\.\d*)?)$/;
    if (
      !validityRegex.test(newValue) ||
      !hasValidDecimalPlaces(newValue, decimalPlaces) ||
      !isWithinValidRange(newValue, min, max)
    ) {
      return;
    }

    setInputValue(newValue);

    if (!newValue.endsWith(".") && newValue !== "-") {
      onUpdate(Number(newValue));
    }
  };

  const handleMouseDown = (
    e: React.MouseEvent<HTMLButtonElement>,
    theStep: number,
  ) => {
    if (e.button === 0) {
      setTimeoutRef.current = setTimeout(() => {
        setIntervalRef.current = setInterval(() => {
          addStepToValue(theStep);
        }, 100);
      }, 500);
    }
  };

  const clearTimers = () => {
    clearTimeout(setTimeoutRef.current);
    clearInterval(setIntervalRef.current);
  };

  const updateInput = (theStep: number) => {
    const newValue = inputValueAsNumber + theStep;

    if (inputValue !== "") {
      newValue <= max && newValue >= min && addStepToValue(theStep);
    } else if (initialValue !== undefined) {
      onUpdate(initialValue);
      setInputValue(initialValue.toString());
    }
  };

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    theStep: number,
  ) => {
    if (e.button === 0) {
      updateInput(theStep);
    }
  };

  return (
    <NumericInputStyles $ETPtheme={etpTheme} $hasError={hasError}>
      <input
        autoComplete={"off"}
        type="text"
        step={step}
        value={inputValue}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={isComponentDisabled}
        {...inputProps}
        className={`etp-quick-deal_numeric-input ${
          hasError ? "etp-quick-deal_numeric-input__has-error" : ""
        }`}
        onKeyDown={(event) => {
          switch (event.key) {
            case "ArrowUp":
              updateInput(step);
              break;
            case "ArrowDown":
              updateInput(-step);
              break;
            case "Enter":
              event.preventDefault();
              break;
          }
        }}
      />
      {showArrows && (
        <div className="etp-quick-deal_numeric-input__counter-buttons">
          <CounterButton
            buttonName={inputProps.id + "-increase"}
            isDisabled={isComponentNotActive || isIncrementDisabled()}
            onClick={(e) => {
              handleClick(e, step);
            }}
            onMouseDown={(e) => {
              handleMouseDown(e, step);
            }}
            onMouseUp={clearTimers}
          >
            <ChevronIcon pointingDirection={ChevronPointingDirection.UP} />
          </CounterButton>
          <CounterButton
            buttonName={inputProps.id + "-decrease"}
            isDisabled={isComponentNotActive || isDecrementDisabled()}
            onClick={(e) => {
              handleClick(e, -step);
            }}
            onMouseDown={(e) => {
              handleMouseDown(e, -step);
            }}
            onMouseUp={clearTimers}
          >
            <ChevronIcon pointingDirection={ChevronPointingDirection.DOWN} />
          </CounterButton>
        </div>
      )}
    </NumericInputStyles>
  );
};

const hasValidDecimalPlaces = (
  newValue: string,
  decimalPlacesLimit: number,
) => {
  const decimalPlaces = countDecimalPlaces(newValue);

  if (decimalPlacesLimit === 0 && newValue.includes(".")) {
    return false;
  }

  return decimalPlaces <= decimalPlacesLimit;
};

const isWithinValidRange = (newValue: string, min: number, max: number) => {
  const isNegativeAllowed = min < 0;
  const newValueAsNumber = Number(newValue);

  if (!isNegativeAllowed && newValue.includes("-")) {
    return false;
  }
  if (newValueAsNumber > max) {
    return false;
  }
  return !(isNegativeAllowed && newValueAsNumber < min);
};

const countDecimalPlaces = (aNumber: number | string) => {
  return aNumber.toString().split(".")[1]?.length ?? 0;
};
