import React, { type ReactNode } from "react";
import { CounterButtonStyles } from "./CounterButtonStyles";
import { useETPTheme } from "../../../hooks/use-etp-theme";

interface CounterButtonProps {
  children: ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled: boolean;
  onMouseDown: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseUp: () => void;
  buttonName: string;
}

export const CounterButton = ({
  children,
  onClick,
  isDisabled,
  onMouseDown,
  onMouseUp,
  buttonName,
}: CounterButtonProps) => {
  const etpTheme = useETPTheme();
  return (
    <CounterButtonStyles
      name={buttonName}
      aria-label={buttonName}
      type="button"
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      disabled={isDisabled}
      className="etp-quick-deal_counter-button"
      $ETPtheme={etpTheme}
    >
      {children}
    </CounterButtonStyles>
  );
};
