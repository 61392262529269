export const convertLocalToCurrencySymbol = (
  numberToFormat: number | undefined | null,
  localeUnformatted: string,
  currencyCode: string | undefined = "EUR",
): string => {
  if (
    numberToFormat === null ||
    numberToFormat === undefined ||
    localeUnformatted === ""
  ) {
    return "-";
  }
  const locale: string = localeUnformatted.replace(/_/g, "-");
  const formattedCurrency = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
  });
  const valuesToReplace = / /g;
  return formattedCurrency.format(numberToFormat).replace(valuesToReplace, " ");
};
