import styled from "styled-components";
import { type ETPtheme } from "../../../theme/etp-theme";
import { GlobalElementsStyle } from "../../../theme/global-elements-style";

export const PreviewButtonStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  &.etp-quick-deal_order-button {
    padding: 8px 0 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;

    button {
      font-size: 13px;
      width: 100%;
      height: 24px;
      border-color: ${({ $ETPtheme }) =>
        $ETPtheme.previewButton.border.primary};
    }

    &.unarmed button {
      border-color: ${({ $ETPtheme }) =>
        $ETPtheme.previewButton.border.unarmed};

      &:focus {
        box-shadow: unset;
      }
    }
  }
`;
