import styled from "styled-components";
import { type ETPtheme } from "../../theme/etp-theme";
import { useETPTheme } from "../../hooks/use-etp-theme";
import { memo } from "react";
import { type DefaultIconProps } from "../../types/default-icon-props";
import { useTranslation } from "react-i18next";

interface CloseIconProps extends DefaultIconProps {
  onClose: () => void;
}
const CloseIcon = ({
  onClose,
  height = 10,
  width = 10,
  fill = "none",
  offset = 8,
}: CloseIconProps) => {
  const ETPtheme: ETPtheme = useETPTheme();
  const { t } = useTranslation();

  return (
    <CloseContainer
      aria-label={t("close-button")}
      $ETPtheme={ETPtheme}
      $offset={offset}
      type="button"
      onClick={onClose}
    >
      <svg
        width={width}
        height={height}
        viewBox="0 0 10 10"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.3821 1.1726C9.57935 0.975353 9.57936 0.655542 9.3821 0.458295V0.458295C9.18486 0.261066 8.86508 0.261061 8.66784 0.458283L4.83982 4.28588L0.911056 0.357239C0.713773 0.159963 0.393901 0.160026 0.196696 0.35738V0.35738C-0.000408432 0.554635 -0.000343586 0.874309 0.196841 1.07148L4.12582 5.00026L0.297925 8.82767C0.100627 9.02495 0.100621 9.34482 0.297913 9.5421V9.5421C0.495185 9.73936 0.815016 9.73936 1.01229 9.5421L4.84008 5.71449L8.76842 9.64287C8.96566 9.84011 9.28545 9.84011 9.48269 9.64288V9.64288C9.67994 9.44564 9.67994 9.12584 9.4827 8.9286L5.55429 5.00022L9.3821 1.1726Z"
          fill={ETPtheme.icon.close.path.fill.default}
        />
      </svg>
    </CloseContainer>
  );
};

export const CloseContainer = styled.button<{
  $ETPtheme: ETPtheme;
  $offset: number;
}>`
  background: inherit;
  color: inherit;
  border: none;
  padding: 3px 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  position: absolute;
  top: ${({ $offset }) => $offset}px;
  right: ${({ $offset }) => $offset}px;

  &:hover {
    path {
      fill: ${({ $ETPtheme }) => $ETPtheme.icon.close.path.fill.hover};
    }
  }
`;

export default memo(CloseIcon);
