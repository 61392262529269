import {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSession } from "./use-session";
import { IgTradingInstrumentAdapter } from "../adapters/ig-trading/ig-trading-instrument-adapter";
import {
  type Instrument,
  type InstrumentUnderlying,
} from "../types/instrument";

interface InstrumentDataProviderProps {
  children: ReactNode;
  instrumentEpic: string;
}
interface InstrumentDataContextProps {
  instrumentData: Instrument;
  instrumentDataUnderlying: InstrumentUnderlying;
}
const InstrumentDataContext = createContext<InstrumentDataContextProps | null>(
  null,
);

export const InstrumentDataProvider = ({
  children,
  instrumentEpic,
}: InstrumentDataProviderProps) => {
  const [instrumentData, setInstrumentData] = useState<Instrument | null>(null);
  const [instrumentDataUnderlying, setInstrumentDataUnderlying] =
    useState<InstrumentUnderlying | null>(null);

  const session = useSession();
  useEffect(() => {
    const igTradingInstrumentAdapter = new IgTradingInstrumentAdapter(session);

    const instrumentPromise =
      igTradingInstrumentAdapter.getInstrument(instrumentEpic);
    instrumentPromise
      .then((instrument) => {
        setInstrumentData(instrument);
        return instrument.referenceEpic;
      })
      .then((referenceEpic) => {
        if (referenceEpic !== "" && referenceEpic !== null) {
          igTradingInstrumentAdapter
            .getInstrumentUnderlying(referenceEpic)
            .then((instrumentUnderlying) => {
              setInstrumentDataUnderlying(instrumentUnderlying);
            })
            .catch(console.error);
        } else {
          console.warn(
            "No underlying epic found, skipping call for underlying instrument data.",
          );
        }
      })
      .catch(console.error);
  }, [instrumentEpic, session]);

  if (instrumentData === null || instrumentDataUnderlying === null) {
    return false;
  }

  return (
    <InstrumentDataContext.Provider
      value={{
        instrumentData,
        instrumentDataUnderlying,
      }}
    >
      {children}
    </InstrumentDataContext.Provider>
  );
};

export const useInstrumentData = () => {
  const instrumentDataContext = useContext(InstrumentDataContext);
  if (instrumentDataContext === null) {
    throw new Error("No instrument data has been retrieved");
  }
  return instrumentDataContext;
};
