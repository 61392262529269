import {
  SUCCESSFUL_RESULT,
  type TicketValidator,
  ValidationError,
  ValidationErrors,
  ValidationResult,
} from "../../types/ticket-validation";
import { type Ticket } from "../../types/ticket";
import { OrderType } from "../../types/ig-trading-order-ticket";

export default class LimitNotZeroValidator implements TicketValidator {
  validate(ticket: Ticket): ValidationResult {
    if (
      ticket.triggerLevel === undefined &&
      ticket.orderType !== OrderType.LIMIT
    ) {
      return SUCCESSFUL_RESULT;
    }

    if (ticket.triggerLevel !== undefined && ticket.triggerLevel > 0) {
      return SUCCESSFUL_RESULT;
    }

    return new ValidationResult(
      new ValidationError(
        ValidationErrors.LIMIT_INPUT_WARNING_ZERO,
        "TRIGGER_LEVEL_REQUIRED",
      ),
    );
  }
}
