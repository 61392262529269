import { GlobalElementsStyle } from "../../../theme/global-elements-style";
import styled, { css } from "styled-components";

export const RadioButtonStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: any;
}>`
  display: flex;
  align-items: center;

  .etp-quick-deal-radio-button__button {
    position: relative;
    margin: 0;
    opacity: 0;
    z-index: 1;
    cursor: inherit;

    &:checked {
      & + .etp-quick-deal-radio-button__custom-button {
        ${({ $ETPtheme }) => css`
          background-color: ${$ETPtheme.radioButton.background.checked};
          box-shadow: ${$ETPtheme.radioButton.boxShadow.checked};
          border: ${$ETPtheme.radioButton.border.checked};
        `}
      }
    }

    &:disabled {
      & + .etp-quick-deal-radio-button__custom-button {
        box-shadow: ${({ $ETPtheme }) =>
          $ETPtheme.radioButton.boxShadow.disabled};
      }

      & ~ .etp-quick-deal-radio-button__label {
        color: ${({ $ETPtheme }) => $ETPtheme.radioButton.text.label.disabled};
      }
    }
  }

  .etp-quick-deal-radio-button__custom-button {
    position: absolute;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    z-index: 0;
    cursor: inherit;
    transition: all 0.1s;
    outline: 0;
    ${({ $ETPtheme }) => css`
      background-color: ${$ETPtheme.radioButton.background.unchecked};
      box-shadow: ${$ETPtheme.radioButton.boxShadow.unchecked};
      border: ${$ETPtheme.radioButton.border.unchecked};
    `}
  }

  .etp-quick-deal-radio-button__label {
    margin: 0 0 0 10px;
    cursor: inherit;
    color: ${({ $ETPtheme }) => $ETPtheme.radioButton.text.label.enabled};
  }
`;
