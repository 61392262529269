import styled from "styled-components";
import { GlobalElementsStyle } from "../../theme/global-elements-style";
import { type ETPtheme } from "../../theme/etp-theme";

export const MarketDepthContainerStyles = styled(GlobalElementsStyle)<{
  $ETPtheme: ETPtheme;
}>`
  position: relative;
  background-color: ${({ $ETPtheme }) =>
    $ETPtheme.marketDepth.background.default};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5px;
  width: calc(100% + 12px);
  left: -6px;
  padding: 6px;
  margin-top: 6px;

  .etp-quick-deal_external-link {
    grid-column: 1/3;
    grid-row: 3/4;
  }

  ul {
    display: flex;
    flex-direction: column;
    padding: 2px 0 0 0;
    margin: 0;
    list-style: none;

    li:first-child {
      strong {
        font-size: 15px;
      }
    }
  }
`;
